// @flow

import style from "./style.module.scss";

import React, { type Node, useEffect, useState } from "react";

import { Pagination } from "@2po-c21/components";
import AgencyCard, { type Agency } from "../AgencyCard";

import { RESULTS_PER_PAGE } from "@utils/search/shared";

type Props = {
    agencies: Array<Agency>,
    currentPage: number,
    onPageChanged: (number) => void,
    Header?: Node,
};

const getAgenciesForPage = (agencies, page) => {
    const start = (page - 1) * RESULTS_PER_PAGE;
    return agencies.slice(start, start + RESULTS_PER_PAGE);
};

const AgencySearchResults = ({
    agencies,
    currentPage,
    onPageChanged,
    Header,
}: Props) => {
    const pageCount = Math.ceil(agencies.length / RESULTS_PER_PAGE);

    const [currentPageAgencies, setCurrentPageAgencies] = useState([]);

    useEffect(
        () => setCurrentPageAgencies(getAgenciesForPage(agencies, currentPage)),
        [agencies, currentPage],
    );

    return (
        <div className={style.agencyResultsHolder}>
            {Header && <div className={style.sorting}>{Header}</div>}
            <div className={style.cards}>
                {currentPageAgencies.map((agency, index) => (
                    <div className={style.card} key={index}>
                        <AgencyCard agency={agency}></AgencyCard>
                    </div>
                ))}
            </div>
            <div>
                <Pagination
                    onPageChange={onPageChanged}
                    nrOfPages={pageCount}
                    currentPage={currentPage}
                />
            </div>
        </div>
    );
};

AgencySearchResults.RESULTS_PER_PAGE = RESULTS_PER_PAGE;

export default AgencySearchResults;
