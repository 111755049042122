// @flow

import React from "react";
import { type PropertyFilterProps } from "../types";
import { Filter } from "@2po-c21/components";
import { gettext, t } from "ttag";
import { ensureArray } from "@utils/array";

const values = ["be", "fr", "es", "it", "lu"];

type ExclusiveCountryProps = {
    whitelist: string[],
};

const Country = ({
    onChange,
    filter,
    className,
    whitelist,
}: PropertyFilterProps & ExclusiveCountryProps) => {
    return (
        <Filter.MultiSelect
            label={t`common.filters.country-code.label`}
            values={(whitelist?.length
                ? values.filter((value) => whitelist.includes(value))
                : values
            ).map((value) => ({
                value,
                displayValue: gettext(
                    `api.property.country-code.${value.toLowerCase()}`,
                ),
            }))}
            value={filter?.countryCode && ensureArray(filter.countryCode)}
            onChange={(value) => onChange && onChange({ countryCode: value })}
            className={className}
            applyBtnText={t`common.filters.apply`}
        />
    );
};

export default Country;
