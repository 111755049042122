// @flow

import React from "react";

import { gettext } from "ttag";

import { PropertyCard as Card } from "@2po-c21/components";

import { energyUtils, toYesNoNull } from "@utils";
import Image from "gatsby-image";
import { Link } from "gatsby";
import { PropertyFallbackImage } from "@containers";
import { numberToCurrency } from "../../utils";

import { type SliderImageClickEvent, EVENT_MAP } from "../../models/events";

type Props = {
    property: *,
    callbacks?: {
        handleRemoveFavourite?: (property: any) => void,
        handleAddFavourite?: (property: any) => void,
        handleNext: (property: any) => void,
        handlePrevious: (property: any) => void,
        handleOnClick?: (event: SliderImageClickEvent) => void,
    },
};

const converter = energyUtils.unitsConverter;

const SUB_TYPE_EMPTY = "UNKNOWN";
const map = ({
    id,
    type,
    subType,
    address,
    surface,
    images,
    rooms,
    price,
    energySpecifications,
    slug,
    numberOfFacades,
    condition,
    hasParking,
    status,
}: *): * => ({
    id,
    type: gettext(`api.property.type.${type}`),
    typeKey: type,
    subType:
        subType !== SUB_TYPE_EMPTY
            ? gettext(`api.property.sub-type.${subType}`)
            : null,
    address,
    surface:
        (surface?.totalSurfaceArea?.value &&
            surface?.totalSurfaceArea?.value !== 0 &&
            converter[surface?.totalSurfaceArea?.unit](
                surface?.totalSurfaceArea?.value,
            )) ||
        0,
    images: images.map((image) => ({
        // eslint-disable-next-line react/display-name
        imageSrc: () => (
            <Image
                fixed={image.fixed}
                alt={image?.description}
                backgroundColor
            />
        ),
        description: image.description,
    })),
    rooms,
    price: typeof price !== "object" ? price : numberToCurrency(price.amount),
    // eslint-disable-next-line react/display-name
    LinkComponent: ({ children }: *) => (
        <Link to={slug} className="gatsbyLink">
            {children}
        </Link>
    ),
    epcLabel: energySpecifications?.energyLabel,
    parking: toYesNoNull(hasParking),
    badge:
        condition === "NEW" || status === "OPTION"
            ? {
                  text:
                      status === "OPTION"
                          ? gettext("api.property.status.OPTION")
                          : gettext("api.property.condition.NEW"),
                  variant: status === "OPTION" ? "info" : "",
              }
            : {},
    numberOfWalls: numberOfFacades,
});

const mapAll = (properties: Array<*>): Array<*> => properties.map(map);

export const PropertyCardMapper = { map, mapAll };

const PropertyCard = ({ property, callbacks }: Props) => {
    const handleOnClick = (imageIndex: number) => {
        const image = property.images[imageIndex].fixed.src;
        const amountImages = property.images.length;
        const gtmEvent: SliderImageClickEvent = {
            name: image,
            propertyid: property.id,
            propertyview: "",
            listname: "",
            position: imageIndex + 1,
            listtotal: amountImages,
            type: EVENT_MAP.SliderImageClickEvent,
        };
        callbacks &&
            callbacks.handleOnClick &&
            callbacks.handleOnClick(gtmEvent);
    };
    return (
        <PropertyFallbackImage>
            {(fallbackImage) => (
                <Card
                    fallbackImage={fallbackImage}
                    property={PropertyCardMapper.map(property)}
                    handleRemoveFavourite={() =>
                        callbacks &&
                        callbacks.handleRemoveFavourite &&
                        callbacks.handleRemoveFavourite(property)
                    }
                    handleAddFavourite={() =>
                        callbacks &&
                        callbacks.handleAddFavourite &&
                        callbacks.handleAddFavourite(property)
                    }
                    handleNext={() =>
                        callbacks &&
                        callbacks.handleNext &&
                        callbacks.handleNext(property)
                    }
                    handlePrevious={() =>
                        callbacks &&
                        callbacks.handlePrevious &&
                        callbacks.handlePrevious(property)
                    }
                    handleOnClick={(imageIndex: number) =>
                        handleOnClick(imageIndex)
                    }
                />
            )}
        </PropertyFallbackImage>
    );
};
export default PropertyCard;
