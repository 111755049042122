// @flow

import React from "react";

import style from "./style.module.scss";
import { Typography } from "@2po-c21/components";
import { Button, Col, Container, Row } from "reactstrap";
import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";

type Props = {
    ctaTitle?: string,
    ctaDescription?: {
        ctaDescription?: string,
    },
    ctaBackgroundColors: "Gold" | "Grey" | "Orange",
    ctaContent: {
        icon: string,
        linkTitle: string,
        externalLink?: string,
        contentfulInternalLink?: {
            slug: string,
        },
    }[],
};

const ctaBackGroundColorType = {
    Gold: "primary",
    Grey: "secondary",
    Orange: "orange",
};

const render = (
    { ctaTitle, ctaDescription, ctaContent, ctaBackgroundColors }: Props,
    key,
) => {
    return (
        <Container fluid key={key}>
            <div className={style.headingWrapper}>
                <Typography type="heading-4-bold">{ctaTitle}</Typography>
                <Typography type="body" className={style.headingDescription}>
                    {ctaDescription?.ctaDescription}
                </Typography>
            </div>
            <Row className={style.quickLinksWrapper}>
                {ctaContent.map(
                    (
                        { linkTitle, contentfulInternalLink, externalLink },
                        index,
                    ) => {
                        return (
                            <Col
                                className={style.quickLinkCard}
                                key={index}
                                xs="6"
                                lg="3"
                                xl="2"
                            >
                                <Button
                                    onClick={() => {
                                        window.location.href = `${
                                            (contentfulInternalLink
                                                ? contentfulInternalLink.slug
                                                : externalLink &&
                                                  externalLink) || ""
                                        }`;
                                    }}
                                    block
                                    color={
                                        ctaBackGroundColorType[
                                            ctaBackgroundColors
                                        ]
                                    }
                                >
                                    {linkTitle}
                                </Button>
                            </Col>
                        );
                    },
                )}
            </Row>
        </Container>
    );
};

const condition = (content) => {
    return content.__typename === CONTENTFUL_TYPES.CtaGroup;
};
export default createContentfulRenderer(condition, render);
