// @flow

import React, { useState } from "react";
import {
    Icon,
    Facebook,
    Email,
    Twitter,
    Share,
    Typography,
    Whatsapp,
} from "@2po-c21/components";
import { t } from "ttag";
import style from "./style.module.scss";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { type ShareEvent, EVENT_MAP } from "../../models/events";
import { PushEvent } from "../Tracker/Tracker";
type Props = {
    propertyTitle: string,
    propertyId: string,
};

const SocialShare = ({ propertyTitle, propertyId }: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const gtmDispatcher = useGTMDispatch();
    const winWidth = 780;
    const winHeight = 550;

    const shareArticle = function (url: *) {
        const winTop =
            window.top.outerHeight / 2 + window.top.screenY - winHeight / 2;
        const winLeft =
            window.top.outerWidth / 2 + window.top.screenX - winWidth / 2;
        toggle();
        return window.open(
            url,
            "sharer",
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${winWidth}, height=${winHeight}, top=${winTop}, left=${winLeft}`,
        );
    };

    const buildFacebookUrl = (siteUrl: string) => {
        const baseUrl = "https://www.facebook.com/sharer/sharer.php?";
        const urlParams = `u=${encodeURI(siteUrl)}`;
        return `${baseUrl}${urlParams}`;
    };

    const buildTwitterUrl = (articleTitle: string, siteUrl: string) => {
        const baseUrl = "https://twitter.com/intent/tweet?";
        let urlParams = `text=${encodeURI(articleTitle)}
                        &url=${encodeURI(siteUrl)}`;
        return `${baseUrl}${urlParams}`;
    };

    const buildWhatsappUrl = (siteUrl: string) => {
        const baseUrl = "whatsapp://send?";
        let urlParams = `text=${t`common.social.whatsapp.text`} ${encodeURI(
            siteUrl,
        )}`;

        return `${baseUrl}${urlParams}`;
    };

    const buildMail = (siteUrl: string, subject: string) => {
        const baseUrl = "mailto:?";
        const urlParams = `subject=${encodeURI(subject)}&body=${encodeURI(
            siteUrl,
        )}`;
        return `${baseUrl}${urlParams}`;
    };

    const sendShareEvent = (platform: string) => {
        const gtmEvent: ShareEvent = {
            platform: platform,
            propertyid: propertyId,
            propertyview: "detail",
            type: EVENT_MAP.ShareEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    return (
        <div className={style.socialShare}>
            <div onClick={() => toggle()}>
                <Icon icon={Share} />
                <Typography
                    type="caption"
                    className={style.socialShareLink}
                >{t`common.social.share`}</Typography>
            </div>
            {dropdownOpen && (
                <div className={style.socialShareDropdown}>
                    <a
                        className={style.socialShareDropdownItem}
                        href={buildMail(
                            location.href,
                            t`common.social.subject`,
                        )}
                        onClick={() => {
                            sendShareEvent("email");
                        }}
                    >
                        <Icon icon={Email} />
                        {t`common.social.email`}
                    </a>
                    <div
                        className={style.socialShareDropdownItem}
                        onClick={() => {
                            sendShareEvent("twitter");
                            shareArticle(
                                buildTwitterUrl(location.href, propertyTitle),
                            );
                        }}
                    >
                        <Icon icon={Twitter} />
                        {t`common.social.twitter`}
                    </div>
                    <div
                        className={style.socialShareDropdownItem}
                        onClick={() => {
                            sendShareEvent("facebook");
                            shareArticle(buildFacebookUrl(location.href));
                        }}
                    >
                        <Icon icon={Facebook} />
                        {t`common.social.facebook`}
                    </div>
                    <div
                        className={style.socialShareDropdownItem}
                        onClick={() => {
                            sendShareEvent("whatsapp");
                            shareArticle(buildWhatsappUrl(location.href));
                        }}
                    >
                        <Icon icon={Whatsapp} />
                        {t`common.social.whatsapp`}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SocialShare;
