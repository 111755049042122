// @flow

import React, { useEffect } from "react";
import { Error, FormInput, Icon } from "@2po-c21/components";
import { Label, Input } from "reactstrap";
import * as yup from "yup";
import style from "../style.module.scss";
import { Field } from "formik";
import Select from "react-select";

export const getValidationSchema: any = (choices: *, additionalOptions: *) => {
    const fields = flattenList(choices);
    const phoneRegEx = /^[0-9\-+]{9,15}$/;
    const schema = fields.reduce((accumulator, field: any) => {
        if (field.type === "number") {
            accumulator[field.name] = yup.number().required(field.errorMessage);
        } else if (field.type === "checkbox") {
            accumulator[field.name] = yup.boolean().notRequired();
        } else if (field.type === "dropdown") {
            accumulator[field.name] = yup
                .string()
                .ensure()
                .required(field.errorMessage);
        } else if (field.type === "email") {
            accumulator[field.id] = yup
                .string()
                .email(field.emailErrorMessage)
                .required(field.errorMessage);
        } else if (field.id === "phoneNumber") {
            accumulator[field.id] = yup
                .string()
                .matches(phoneRegEx, field.phoneErrorMessage)
                .required(field.errorMessage);
        } else {
            if (field.required)
                accumulator[field.name] = yup
                    .string()
                    .required(field.errorMessage);
        }
        return accumulator;
    }, {});

    return yup.object().shape({ ...schema, ...additionalOptions });
};

const flattenList = (values) => {
    const list = Object.values(values).map(function (value) {
        return value;
    });
    return list.flat();
};

export const getInitialValues = (choices: *, additionalOptions: *) => {
    const fields = flattenList(choices);

    const initialValues = fields.reduce((accumulator, field: any) => {
        accumulator[field.name] = "";
        return accumulator;
    }, {});
    return { ...initialValues, ...additionalOptions };
};

export const renderRadioButton = (radioButton: *, handleChange: *, key: *) => (
    <Label key={key} className={style.radioButtonContainer}>
        <Input
            type={radioButton.type}
            name={radioButton.name}
            value={radioButton.value}
            onChange={handleChange}
        />
        <div className={style.radioButton}>
            {radioButton.icon && <Icon size={"lg"} icon={radioButton.icon} />}
            <span>{radioButton.displayValue}</span>
        </div>
    </Label>
);

export const renderInput = (
    field: *,
    handleChange: *,
    handleBlur: *,
    errors: *,
    touched: *,
) => (
    <FormInput
        className={style.inputField}
        type={field.type}
        name={field.name}
        label={field.displayValue}
        invalid={errors[field.name] && touched[field.name]}
        tag={Field}
        errorMessage={errors[field.name]}
        onChange={handleChange}
        onBlur={handleBlur}
    />
);

export const renderDropdown = ({
    dropdown,
    handleDropdownChange,
    setFieldValue,
    key,
    handleBlur,
    presetAgencyId,
    ...rest
}: *) => {
    const handleOnChange = (item) =>
        handleDropdownChange(item, dropdown.name, setFieldValue);

    useEffect(() => {
        const foundItem = dropdown.options.find(
            (item) => item.value === presetAgencyId,
        );
        if (presetAgencyId && foundItem) handleOnChange(foundItem);
    }, [dropdown]);

    return (
        <div key={key}>
            <label htmlFor={dropdown.name}>{dropdown.label}</label>
            <Select
                className={style.dropdown}
                tag={Field}
                key={key}
                name={dropdown.name}
                options={dropdown.options}
                onChange={(e) => handleOnChange(e)}
                onBlur={handleBlur}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary75: "#beaf87",
                        primary50: "#beaf87",
                        primary25: "#beaf87",
                        primary: "#beaf87",
                    },
                })}
                {...rest}
            />
        </div>
    );
};

export const renderErrorMessage = (errorMessage: string) => (
    <div className={style.errorMessage}>
        <Icon variant="red" icon={Error} size="sm" />
        {errorMessage}
    </div>
);
