// @flow

import React from "react";

import { InfoImageSplitView } from "@2po-c21/components";

import Image from "gatsby-image";

import { Background, Link } from "@components";

import {
    createContentfulRenderer,
    CONTENTFUL_TYPES,
    IMAGE_INFORMATION_BLOCK_TYPES,
} from "../types";

type Props = {
    link?: {
        linkTitle: string,
        externalLink?: string,
        contentfulInternalLink?: {
            slug?: string,
            query?: string,
        },
        fileLink?: {
            file?: {
                url?: string,
            },
        },
    },
    content: {
        contentTitle: string,
        contentSubtitle: string,
        squareImage: *,
    },
    background?: {
        size: string,
        position: string,
        color: string,
    },
    maxHeight?: Number,
    imagePosition?: Boolean,
    backgroundColor: string,
    textColor: string,
};

const render = ({
    content: { contentTitle, contentSubtitle, squareImage },
    link,
    maxHeight,
    imagePosition,
    background,
    backgroundColor,
    textColor,
}: Props) => {
    const { linkTitle, contentfulInternalLink, externalLink, fileLink } =
        link || {};
    const LinkComponent = ({ children }: *) =>
        link ? (
            <Link
                to={
                    (contentfulInternalLink
                        ? contentfulInternalLink.slug ||
                          contentfulInternalLink.query
                        : externalLink) || ""
                }
                fileUri={fileLink?.file?.url}
            >
                {children}
            </Link>
        ) : null;
    return (
        <Background background={background} fullBleed={true}>
            <InfoImageSplitView
                LinkComponent={LinkComponent}
                buttonTxt={linkTitle}
                title={contentTitle}
                subtitle={contentSubtitle}
                isLeft={imagePosition}
                maxHeight={maxHeight}
                backgroundColor={backgroundColor}
                textColor={textColor}
                image={{
                    // eslint-disable-next-line react/display-name
                    imageSrc: () => (
                        <Image
                            fluid={squareImage.fluid}
                            alt={squareImage?.description}
                            backgroundColor
                        />
                    ),
                    description: squareImage?.description,
                }}
            />
        </Background>
    );
};

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.ImageInformationBlock &&
    content.type === IMAGE_INFORMATION_BLOCK_TYPES.Split;

export default createContentfulRenderer(condition, render);
