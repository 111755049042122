// @flow

export const GATSBY_C21_API_BASEPATH: string =
    process.env.C21_API_BASEPATH || process.env.GATSBY_C21_API_BASEPATH || "";
export const GATSBY_C21_IMAGES_BASEPATH: string =
    process.env.C21_IMAGES_BASEPATH ||
    process.env.GATSBY_C21_IMAGES_BASEPATH ||
    "";

export const supportedLanguages =
    process.env.COUNTRY_CODE?.toLowerCase() === "lu"
        ? ["fr", "en"]
        : ["fr", "nl"];
