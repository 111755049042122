//@flow

import React from "react";
import { Container } from "reactstrap";

import style from "./style.module.scss";

type Props = {
    children: *,
};

const PropertyBanner = ({ children }: Props) => (
    <Container className={style.searchResultBanner}>{children}</Container>
);

export default PropertyBanner;
