// @flow

import React from "react";
import { StickyButton as _StickyButton } from "@2po-c21/components";

type Props = {
    estimationLink: string,
    estimationLabel: string,
};

const StickyButton = ({ estimationLink, estimationLabel }: Props) => {
    return (
        <_StickyButton
            estimationLink={estimationLink}
            estimationLabel={estimationLabel}
        />
    );
};

export default StickyButton;
