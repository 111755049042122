// @flow

import style from "./style.module.scss";

import React, { type Node } from "react";
import { Container } from "reactstrap";
import { Header, Footer } from "@components";
import { type NavigationItem } from "./../../components/Header";
import features from "../../utils/features";

export type PageContentProps = {
    children: any,
    locale: string,
    navigationItems: Array<NavigationItem>,
    translationSlugs: { [key: string]: string },
    footerItems: { navigationItems: Array<NavigationItem> },
    Breadcrumb?: Node,
    agency?: any,
};

const PageContent = ({
    children,
    locale,
    navigationItems,
    translationSlugs,
    footerItems,
    Breadcrumb,
    agency,
}: PageContentProps) => {
    return (
        <div className={style.fullHeight}>
            <Header
                locale={locale}
                estimationTool={features.SHOW_ESTIMATION_TOOL}
                navigationItems={navigationItems}
                translationSlugs={translationSlugs}
                isAgencyPage={!!agency}
                agencyId={agency ? agency.api_id : ""}
            />
            <div className={style.contentWrapper}>
                <div className={style.breadcrumb}>
                    {Breadcrumb && <Container>{Breadcrumb}</Container>}
                </div>
                {children}
            </div>
            <Footer footerItems={footerItems} />
        </div>
    );
};

export default PageContent;
