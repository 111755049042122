export const LOCALITY_POSTALCODES = [
    "1300",
    "1315",
    "1320",
    "1325",
    "1350",
    "1357",
    "1360",
    "1367",
    "1370",
    "1380",
    "1390",
    "1400",
    "1430",
    "1435",
    "1440",
    "1450",
    "1457",
    "1460",
    "1470",
    "1480",
    "1495",
    "1540",
    "1570",
    "1600",
    "1670",
    "1700",
    "1730",
    "1731",
    "1745",
    "1750",
    "1755",
    "1760",
    "1785",
    "1790",
    "1800",
    "1820",
    "1840",
    "1850",
    "1880",
    "1910",
    "1930",
    "1980",
    "1982",
    "2040",
    "2070",
    "2200",
    "2220",
    "2222",
    "2230",
    "2235",
    "2240",
    "2260",
    "2275",
    "2340",
    "2390",
    "2430",
    "2431",
    "2460",
    "2500",
    "2520",
    "2550",
    "2560",
    "2580",
    "2590",
    "2800",
    "2811",
    "2820",
    "2830",
    "2840",
    "2870",
    "2880",
    "2890",
    "2940",
    "2960",
    "2970",
    "2980",
    "2990",
    "3020",
    "3040",
    "3060",
    "3078",
    "3080",
    "3130",
    "3150",
    "3200",
    "3210",
    "3220",
    "3271",
    "3272",
    "3290",
    "3300",
    "3320",
    "3350",
    "3360",
    "3370",
    "3380",
    "3390",
    "3400",
    "3401",
    "3404",
    "3440",
    "3450",
    "3460",
    "3470",
    "3500",
    "3510",
    "3511",
    "3530",
    "3540",
    "3545",
    "3550",
    "3560",
    "3620",
    "3630",
    "3631",
    "3640",
    "3650",
    "3670",
    "3680",
    "3700",
    "3730",
    "3740",
    "3770",
    "3790",
    "3800",
    "3803",
    "3830",
    "3840",
    "3850",
    "3870",
    "3890",
    "3891",
    "3910",
    "3930",
    "3945",
    "3950",
    "3960",
    "3990",
    "4000",
    "4020",
    "4041",
    "4100",
    "4120",
    "4130",
    "4140",
    "4180",
    "4190",
    "4210",
    "4217",
    "4219",
    "4250",
    "4257",
    "4260",
    "4280",
    "4287",
    "4300",
    "4317",
    "4340",
    "4347",
    "4350",
    "4357",
    "4360",
    "4367",
    "4400",
    "4420",
    "4432",
    "4450",
    "4452",
    "4460",
    "4480",
    "4500",
    "4520",
    "4530",
    "4537",
    "4540",
    "4550",
    "4557",
    "4560",
    "4570",
    "4577",
    "4590",
    "4600",
    "4607",
    "4608",
    "4610",
    "4630",
    "4650",
    "4670",
    "4671",
    "4680",
    "4682",
    "4690",
    "4730",
    "4750",
    "4760",
    "4770",
    "4780",
    "4800",
    "4837",
    "4845",
    "4850",
    "4851",
    "4860",
    "4870",
    "4890",
    "4910",
    "4920",
    "4950",
    "4960",
    "4970",
    "4980",
    "4987",
    "4990",
    "5000",
    "5020",
    "5024",
    "5030",
    "5032",
    "5060",
    "5070",
    "5080",
    "5081",
    "5100",
    "5101",
    "5140",
    "5150",
    "5170",
    "5190",
    "5300",
    "5310",
    "5330",
    "5340",
    "5350",
    "5360",
    "5361",
    "5370",
    "5377",
    "5380",
    "5500",
    "5520",
    "5523",
    "5530",
    "5537",
    "5540",
    "5550",
    "5555",
    "5560",
    "5570",
    "5575",
    "5580",
    "5590",
    "5600",
    "5620",
    "5621",
    "5630",
    "5640",
    "5650",
    "5651",
    "5660",
    "5670",
    "5680",
    "6030",
    "6120",
    "6200",
    "6210",
    "6220",
    "6230",
    "6238",
    "6240",
    "6250",
    "6280",
    "6440",
    "6460",
    "6464",
    "6470",
    "6500",
    "6530",
    "6536",
    "6540",
    "6560",
    "6567",
    "6596",
    "6600",
    "6637",
    "6640",
    "6660",
    "6661",
    "6670",
    "6680",
    "6690",
    "6700",
    "6717",
    "6720",
    "6724",
    "6730",
    "6740",
    "6747",
    "6750",
    "6760",
    "6767",
    "6769",
    "6780",
    "6792",
    "6800",
    "6810",
    "6820",
    "6830",
    "6833",
    "6840",
    "6850",
    "6852",
    "6860",
    "6870",
    "6880",
    "6887",
    "6890",
    "6900",
    "6920",
    "6927",
    "6929",
    "6940",
    "6941",
    "6950",
    "6953",
    "6960",
    "6980",
    "6987",
    "6990",
    "6997",
    "7012",
    "7020",
    "7022",
    "7034",
    "7040",
    "7041",
    "7050",
    "7060",
    "7061",
    "7063",
    "7070",
    "7080",
    "7090",
    "7100",
    "7110",
    "7120",
    "7130",
    "7134",
    "7141",
    "7160",
    "7170",
    "7181",
    "7190",
    "7321",
    "7322",
    "7332",
    "7334",
    "7340",
    "7350",
    "7370",
    "7380",
    "7387",
    "7390",
    "7500",
    "7520",
    "7522",
    "7534",
    "7540",
    "7604",
    "7620",
    "7640",
    "7700",
    "7730",
    "7740",
    "7743",
    "7750",
    "7760",
    "7780",
    "7784",
    "7800",
    "7804",
    "7812",
    "7822",
    "7830",
    "7850",
    "7861",
    "7866",
    "7870",
    "7890",
    "7900",
    "7903",
    "7904",
    "7910",
    "7911",
    "7912",
    "7940",
    "7950",
    "7971",
    "7972",
    "7973",
    "8000",
    "8020",
    "8200",
    "8210",
    "8300",
    "8301",
    "8310",
    "8340",
    "8370",
    "8377",
    "8380",
    "8400",
    "8420",
    "8433",
    "8434",
    "8460",
    "8470",
    "8480",
    "8490",
    "8501",
    "8510",
    "8531",
    "8560",
    "8570",
    "8581",
    "8587",
    "8600",
    "8610",
    "8620",
    "8630",
    "8640",
    "8647",
    "8650",
    "8660",
    "8670",
    "8680",
    "8690",
    "8691",
    "8700",
    "8710",
    "8720",
    "8730",
    "8740",
    "8750",
    "8800",
    "8820",
    "8840",
    "8870",
    "8880",
    "8890",
    "8900",
    "8902",
    "8904",
    "8920",
    "8930",
    "8940",
    "8970",
    "8972",
    "8980",
    "9042",
    "9050",
    "9051",
    "9070",
    "9080",
    "9090",
    "9100",
    "9120",
    "9130",
    "9140",
    "9150",
    "9160",
    "9170",
    "9190",
    "9200",
    "9220",
    "9230",
    "9255",
    "9260",
    "9270",
    "9280",
    "9290",
    "9308",
    "9310",
    "9320",
    "9340",
    "9400",
    "9420",
    "9450",
    "9500",
    "9506",
    "9520",
    "9550",
    "9570",
    "9620",
    "9630",
    "9660",
    "9667",
    "9680",
    "9690",
    "9700",
    "9750",
    "9790",
    "9800",
    "9810",
    "9820",
    "9840",
    "9850",
    "9860",
    "9870",
    "9880",
    "9890",
    "9910",
    "9940",
    "9968",
    "9988",
];
