// @flow

import React from "react";
import { graphql, StaticQuery, navigate } from "gatsby";
import Image from "gatsby-image";
import { t } from "ttag";
import style from "./style.module.scss";
import { AgencyCardRedesign } from "@2po-c21/components";

export type Agency = {
    name: string,
    slug: string,
    phoneNumber: string,
    address: *,
    images: ?Array<*>,
    facebookUrl?: string,
};

type AgencyCardProps = {
    agency: Agency,
};

const AgencyCard = ({
    agency: { address, images, name, slug, phoneNumber, facebookUrl },
}: AgencyCardProps) => {
    const agencyButtonLabels = {
        telephone: t`agency.card.button.telephone`,
        facebook: t`agency.card.button.facebook`,
        email: t`agency.card.button.email`,
        site: t`agency.card.button.site`,
    };
    return (
        <StaticQuery
            query={graphql`
                query {
                    file(relativePath: { eq: "fallback.png" }) {
                        childImageSharp {
                            fixed(width: 235, height: 235, quality: 100) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            `}
            render={({
                file: {
                    childImageSharp: { fixed: fallbackImage },
                },
            }) => (
                <AgencyCardRedesign
                    navigate={navigate}
                    name={name}
                    slug={slug}
                    address={address}
                    phoneNumber={phoneNumber}
                    facebookUrl={facebookUrl}
                    buttonLabels={agencyButtonLabels}
                    image={
                        <Image
                            className={style.image}
                            fixed={
                                (images?.length && images[0].fixed) ||
                                fallbackImage
                            }
                        />
                    }
                />
            )}
        />
    );
};

export default AgencyCard;
