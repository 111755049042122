// @flow

import { GATSBY_C21_API_BASEPATH } from "../env";

export const endpoints = {
    agencies: `${GATSBY_C21_API_BASEPATH}/api/v1/agencies`,
    forms: `${GATSBY_C21_API_BASEPATH}/api/v1/forms`,
    projects: `${GATSBY_C21_API_BASEPATH}/api/v1/projects`,
    properties: `${GATSBY_C21_API_BASEPATH}/api/v2/properties`,
};

export const createPropertyAssetPath = ({ id }: *) => `property-assets/${id}`;
export const createAgencyAssetPath = ({ id }: *) => `agency-assets/${id}`;
export const createProjectAssetPath = ({ id }: *) => `project-assets/${id}`;
