//@flow

export type NavigationClickEvent = {
    type?: string | "NavigationClickEvent",
    name: string,
    linkname: any,
    pageurl: string,
    targeturl: string,
};

export type MapViewEvent = {
    type: string,
    active: boolean,
};

export type SortEvent = {
    type: string,
    order: string,
    field: string,
};

export type FilterUpdateEvent = {
    type: string,
    fieldname: string,
    fieldtype: string,
    value: any,
    action: string,
    selection: *,
};

export type FavoritesEvent = {
    favorite: boolean,
    propertyid: string,
    propertyview: string,
    listname: string,
    type: string,
};

export type ShareEvent = {
    propertyid: string,
    propertyview: string,
    platform: string,
    type: string,
};

export type PrintEvent = {
    propertyid: string,
    propertyview: string,
};

export type ImagesSliderEvent = {
    direction: string,
    propertyid: string,
    propertyview: string,
    listname: string,
};

export type SliderImageClickEvent = {
    name: string,
    propertyid: string,
    propertyview: string,
    listname: string,
    position: number,
    listtotal: number,
};

export type DetailElementClickEvent = {
    name: string,
    type: string,
    targeturl: string,
    element: string,
    propertyid: string,
};

export type ToolStepEvent = {
    name: string,
    stepnumber: number,
    stepname: string,
    element: string,
    messagetype: string,
    type: string,
};

type Agency = {
    id: string,
    name: string,
    postalcode: string,
    city: string,
    country: string,
};
export type PageImpression = {
    name: string,
    url: string,
    pagename: string,
    useragent: string,
    domain: string,
    path: string,
    title: string,
    referrer: string,
    language: string,
    timestamp: string,
    type: string,
    agencypage: string,
    agency: Agency | null,
    typetemplate: string,
};

export type PageImpressionParams = {
    pagename: string,
    agencypage: string,
    language: string,
    agency: Agency | null,
    typetemplate: string,
};

export type BannerImpression = {
    name: string,
    targeturl: string,
    targetname: string,
};

export type BannerImpressionParams = {
    name: string,
    targeturl: string,
    targetname: string,
};

export type BannerClickEvent = {
    name: string,
    targeturl: string,
    targetname: string,
};

export type ToolLastStepEvent = ToolStepEvent & {};

export const EVENT_MAP = {
    NavigationClickEvent: "navigation-click",
    MapViewEvent: "map-view",
    SortEvent: "property-sort",
    FilterUpdateEvent: "filter-update",
    FavoritesEvent: "property-addtofavorites",
    ShareEvent: "property-share",
    PrintEvent: "property-print",
    ImagesSliderEvent: "property-imageslider",
    SliderImageClickEvent: "property-imageclick",
    DetailElementClickEvent: "link-click",
    ToolStepEvent: "tool-step",
    ToolLastStepEvent: "tool-laststep",
    PageImpression: "page-impression",
    BannerImpression: "banner-impression",
    BannerClickEvent: "banner-click",
    SearchPageBannerImpression: "searchpagebanner-impression",
    SearchPageBannerClickEvent: "searchpagebanner-click",
};
