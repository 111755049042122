// @flow

import style from "./style.module.scss";

import React from "react";
import { t } from "ttag";
import { PropertyVisualInformation as _PropertyVisualInformation } from "@2po-c21/components";
import { Section } from "@containers";

type Props = {
    images: {
        description?: string,
        imageSrc: string | (() => Node),
    }[],
    onClick?: (key: number) => void,
    onShowMore: () => void,
};

const PropertyVisualInformation = ({ images, onClick, onShowMore }: Props) => (
    <div className={style.visualInformation}>
        {images.length ? (
            <Section theme={"themeGray"}>
                <_PropertyVisualInformation
                    title={t`property-details.visual-information.title`}
                    images={images}
                    onClick={onClick}
                    onShowMore={onShowMore}
                    showMoreText={t`common.text.show-more`}
                />
            </Section>
        ) : null}
    </div>
);

export default PropertyVisualInformation;
