// @flow

import React from "react";

import { ensureArray } from "@utils/array";

import { Filter } from "@2po-c21/components";
import { t, gettext } from "ttag";
import { type PropertyFilterProps } from "../types";

const values = [
    "HOUSE",
    "APARTMENT",
    "PARKING",
    "COMMERCIAL",
    "LAND",
    "OFFICE",
];

const Type = ({ onChange, filter, className }: PropertyFilterProps) => (
    <Filter.MultiSelect
        label={t`common.filters.type.label`}
        values={values.map((value) => ({
            value,
            displayValue: gettext(`api.property.type.${value}`),
        }))}
        value={filter?.type && ensureArray(filter.type)}
        onChange={(value) => onChange && onChange({ type: value })}
        applyBtnText={t`common.filters.apply`}
        className={className}
    />
);

export default Type;
