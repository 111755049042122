// @flow

import React from "react";
import style from "./style.module.scss";

import renditions from "./renditions";
import { createContentfulRenderer, EMBEDDED_CONTENT_TYPE } from "../types";
import { Row, Col } from "reactstrap";
import Image from "gatsby-image";

const render = (content, key, locale) => {
    const images = content.fields.images.nl?.map((image) => ({
        description: image.fields.title[locale],
        fluid: renditions(image.fields.file[locale], 100),
    }));

    return (
        <div key={key}>
            <Row>
                {images &&
                    images.map((image, index) => (
                        <Col
                            key={index}
                            md={(1 / images.length) * 12}
                            className={style.imageWrapper}
                        >
                            <Image
                                fluid={image.fluid}
                                alt={image.description}
                                backgroundColor="#e6e7e8"
                            />
                        </Col>
                    ))}
            </Row>
        </div>
    );
};

const condition = (content) =>
    content?.sys?.contentType?.sys?.id === EMBEDDED_CONTENT_TYPE.ImageContainer;

export default createContentfulRenderer(condition, render);
