// @flow
import style from "./style.module.scss";

import React, { type Node } from "react";
import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { t } from "ttag";

type Props = {
    children: (fallbackImage: {
        imageSrc: string | (() => Node),
        description?: string,
    }) => *,
};

const FallbackImage = ({ children }: Props) => (
    <StaticQuery
        query={graphql`
            query FallbackImageQuery {
                file(relativePath: { eq: "no-image.jpg" }) {
                    childImageSharp {
                        fixed(width: 310, height: 177, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={(data) => {
            const fallbackImage = {
                // eslint-disable-next-line react/display-name
                imageSrc: () => (
                    <Image
                        fixed={data.file.childImageSharp.fixed}
                        alt={t`common.text.fallback-image`}
                    />
                ),
                description: t`common.text.fallback-image`,
            };
            return children(fallbackImage);
        }}
    />
);

export default FallbackImage;

const CarouselFallbackImage = ({ fluid }: *) => (
    <div className={style.carouselFallbackImage}>
        <Image fluid={fluid} alt={t`common.text.fallback-image`} />
    </div>
);

export { CarouselFallbackImage };
