// @flow

import style from "./style.module.scss";

import React from "react";
import { t } from "ttag";

import { Sorting } from "@2po-c21/components";

type Props = {
    numberOfResults?: number,
    currentSort: *,
    onSortChanged: (*) => void,
};

const PropertyOverviewSort = ({
    currentSort,
    onSortChanged,
    numberOfResults,
}: Props) => (
    <span className={style.header}>
        <span className={style.numberOfResults}>
            {t`property-overview.search.results.number-of-results_${numberOfResults}`}
        </span>
        <Sorting
            currentSort={currentSort}
            sortingOptions={[
                {
                    displayValue: t`property-overview.sorting.low-high`,
                    value: {
                        field: "price.amount",
                        direction: "asc",
                    },
                },
                {
                    displayValue: t`property-overview.sorting.high-low`,
                    value: {
                        field: "price.amount",
                        direction: "desc",
                    },
                },
                {
                    displayValue: t`property-overview.sorting.newest`,
                    value: {
                        field: "creationDate",
                        direction: "desc",
                    },
                },
            ]}
            onChange={onSortChanged}
        />
    </span>
);

export default PropertyOverviewSort;
