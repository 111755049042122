/**
 * Read out and expose all features the site has
 *
 * TODO: Freeze the settings object
 */

export default {
    TITLE: process.env.GATSBY_SETTING_TITLE || "",
    COUNTRY_CODE: process.env.GATSBY_SETTING_COUNTRY_CODE?.toLowerCase() || "",
    SUPPORTED_LANGUAGES:
        process.env.GATSBY_SETTING_SUPPORTED_LANGUAGES?.toLowerCase()?.split(
            ",",
        ) || "",
    LISTED_COUNTRIES:
        process.env.GATSBY_SETTING_LISTED_COUNTRIES?.toLowerCase()?.split(
            ",",
        ) || "",
};
