// @flow

import { GATSBY_C21_IMAGES_BASEPATH } from "../../env";

type ImageNode = {
    path: string,
    filename: string,
    extension: string,
    lastModifiedDate?: ?string,
};

type Edits = {
    resize: {
        withoutEnlargement: boolean,
        height?: *,
        width?: *,
    },
};

const IMAGE_QUALITY = 75;

const fixed = (image: ImageNode, { width, height }: *) => {
    const fixedImageSizes = [1, 2, 3, 4];

    const createSourceSetEntries = (
        image: ImageNode,
        width: number,
        height: number,
    ) =>
        fixedImageSizes
            .map((size) => ({
                width: width * size,
                height: height * size,
                size,
            }))
            .map(({ width, height, size }: *) => {
                const format = image.extension.substring(1);
                return {
                    src: createPath(image, {
                        format: format,
                        quality: IMAGE_QUALITY,
                        width: width,
                        height: height,
                    }),
                    webp: createPath(image, {
                        format: "webp",
                        quality: IMAGE_QUALITY,
                        width: width,
                        height: height,
                    }),
                    size,
                };
            });

    const toSourceSet = (entries: *, getValue: *) => ({
        src: getValue(entries[0]),
        srcSet: entries
            .map((entry) => `${getValue(entry)} ${entry.size}x`)
            .join(", "),
    });

    const createSourceSets = (imageNode: *, width: *, height: *) => {
        const sourceSetsEntries = createSourceSetEntries(
            imageNode,
            width,
            height,
        );

        const src = toSourceSet(sourceSetsEntries, (entry) => entry.src);
        const webp = toSourceSet(sourceSetsEntries, (entry) => entry.webp);

        return {
            width,
            height,
            src: src.src,
            srcSet: src.srcSet,
            srcWebp: webp.src,
            srcSetWebp: webp.srcSet,
        };
    };

    return {
        width,
        height,
        ...createSourceSets(image, width, height),
    };
};

const fluid = (image: *, { maxWidth }: *) => {
    const fluidImageSizes = [0.25, 0.5, 0.75, 1, 1.5, 2, 3, 4];

    const createSourceSetEntries = (image: *, maxWidth: *) =>
        fluidImageSizes
            .map((size) => ({
                width: Math.ceil(maxWidth * size),
                size,
            }))
            .map(({ width }: *) => {
                return {
                    src: createPath(image, {
                        format: "jpeg",
                        quality: IMAGE_QUALITY,
                        width: width,
                        height: 0,
                    }),
                    webp: createPath(image, {
                        format: "webp",
                        quality: IMAGE_QUALITY,
                        width: width,
                        height: 0,
                    }),
                    max: width,
                };
            });

    const toSourceSet = (entries: *, getValue: *) => ({
        src: getValue(entries[0]),
        srcSet: entries
            .map((entry) => `${getValue(entry)} ${entry.max}w`)
            .join(", "),
    });

    const createSourceSets = (imageNode: *, maxWidth: *) => {
        const sourceSetsEntries = createSourceSetEntries(imageNode, maxWidth);

        const src = toSourceSet(sourceSetsEntries, (entry) => entry.src);
        const webp = toSourceSet(sourceSetsEntries, (entry) => entry.webp);

        return {
            src: src.src,
            srcSet: src.srcSet,
            srcWebp: webp.src,
            srcSetWebp: webp.srcSet,
            sizes: `(max-width: ${maxWidth}px), 100vw, ${maxWidth}px`,
            aspectRatio: 16 / 9, // TODO [TKN-20200629] Get from API instead of hardcoded aspect ratio
        };
    };

    return {
        maxWidth,
        ...createSourceSets(image, maxWidth),
    };
};

const ImageMapper = {
    toFixed: fixed,
    toFluid: fluid,
};

export default ImageMapper;

const createPath = (
    { path, filename, extension, lastModifiedDate }: ImageNode,
    editConfig,
) => {
    return `${GATSBY_C21_IMAGES_BASEPATH}/${Buffer.from(
        JSON.stringify({
            key: `${path}/${filename}${extension}`,
            edits: edits(editConfig),
            lastModifiedDate,
        }),
    ).toString("base64")}`;
};

const edits = ({ format, quality, width, height }: *) => {
    const requestFormat: string = /jpg/i.test(format) ? "jpeg" : format;

    const edits: Edits = {
        [requestFormat]: {
            quality: quality,
        },
        rotate: null,
        resize: {
            withoutEnlargement: true,
        },
    };

    if (width) {
        edits.resize.width = width;
    }

    if (height) {
        edits.resize.height = height;
    }

    return edits;
};
