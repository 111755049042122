// @flow

import countries from "i18n-iso-countries";

export type Mapper = {
    map: (subject: *) => *,
    localize: (subject: *, locale: string) => *,
    mapLocalized: (subject: *, translations: *) => *,
};

export const api_id = ({ id }: *) => ({ api_id: id });

export const localizeAddress = ({ address }: *, locale: string) => {
    return {
        address: {
            ...address,
            country: countries.getName(address.countryCode, locale),
        },
    };
};

export const shallowCopy = (subject: *) => ({ ...subject });

export const setLocale = (_: any, locale: string) => ({ locale });

export const apply = <T>(steps: *, apply: (T) => T): T =>
    steps.reduce(
        (result: *, step: *) => ({
            ...result,
            ...apply(step),
        }),
        {},
    );

export const getDomainByCountryCode = (countryCode: string) => {
    const domainMap = {
        LU: {
            domain: "www.century21.lu",
            languageMap: {
                fr: "fr",
                en: "fr",
                nl: "fr",
            },
            agencySlug: {
                fr: "agence",
                en: "agence",
                nl: "agence",
            },
        },
        BE: {
            domain: "www.century21.be",
            languageMap: {
                fr: "fr",
                nl: "fr",
                en: "fr",
            },
            agencySlug: {
                fr: "agence",
                nl: "agence",
                en: "agence",
            },
        },
    };

    return domainMap[countryCode];
};
