// @flow

import style from "./style.module.scss";

import React from "react";

import { BlogQuickLinkCard, SectionTitle } from "@2po-c21/components";
import { Row, Col } from "reactstrap";
import Image from "gatsby-image";
import { Link } from "gatsby";
import { t } from "ttag";

import { Background } from "@components";

import {
    createContentfulRenderer,
    CONTENTFUL_TYPES,
    QUICK_LINK_BLOCK_TYPES,
} from "../types";

const renderBlogCard = (item: *, slug: string, wideCard: boolean = false) => {
    const LinkComponent = ({ children }: *) => (
        <Link className="gatsbyLink" to={slug}>
            {children}
        </Link>
    );
    return (
        <BlogQuickLinkCard
            wideCard={wideCard}
            title={item.title}
            linkTitle={t`landing.blog.article`}
            image={{
                // eslint-disable-next-line react/display-name
                imageSrc: () => (
                    <Image
                        fluid={item.heroImage.fluid}
                        alt={item?.heroImage?.description}
                    />
                ),
                description: item?.heroImage?.description,
            }}
            LinkComponent={LinkComponent}
        />
    );
};

const render = ({ linkItems, title, background }) => {
    return (
        <Background background={background} theme="themeGray">
            <Row className={style.blogCardsWrapper}>
                <Col lg="12">
                    <SectionTitle>{title}</SectionTitle>
                </Col>
                {linkItems && linkItems.length && (
                    <>
                        {linkItems[0] && (
                            <Col lg="6">
                                {renderBlogCard(
                                    linkItems[0].pageContent[0],
                                    linkItems[0].slug,
                                    true,
                                )}
                            </Col>
                        )}
                        <Col lg="6">
                            {linkItems.slice(1).map((item, index) => (
                                <div key={index} className={style.sideHolder}>
                                    {renderBlogCard(
                                        item.pageContent[0],
                                        item.slug,
                                    )}
                                </div>
                            ))}
                        </Col>
                    </>
                )}
            </Row>
        </Background>
    );
};

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.QuickLinkBlock &&
    content.type === QUICK_LINK_BLOCK_TYPES.Blog;

export default createContentfulRenderer(condition, render);
