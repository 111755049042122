// @flow

import style from "./style.module.scss";

import React, { useEffect, useState } from "react";
import { t } from "ttag";

import { Filter, Icon, Magnify } from "@2po-c21/components";

import { ensureArray } from "@utils/array";
import { addFilterFactory } from "@components/Filters";

import {
    type AgencyOverviewFilter,
    AgencyOverviewSearchFactory,
} from "@utils/search";
import {
    findPlaceForCoordinates,
    findCitiesForAutocomplete,
    findCoordinatesForPlace,
} from "@utils/mapbox";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import customStyles from "../Filters/Location/styles";

export type FilterConfig = {
    currentFilter: ?AgencyOverviewFilter,
    onFilterChanged?: (filter: AgencyOverviewFilter) => void,
    language: string,
    filterType?: string,
};

type Props = {} & FilterConfig;

const AgencyFilter = ({
    onFilterChanged,
    currentFilter,
    language,
    filterType,
}: Props) => {
    const [filter, setFilter] = useState(undefined);
    const [initialLocation, setInitialLocation] = useState();

    const typeFilterValues = [
        {
            value: "broker",
            displayValue: t`common.filters.agency-type.broker`,
        },
        {
            value: "syndic",
            displayValue: t`common.filters.agency-type.syndic`,
        },
        {
            value: "management",
            displayValue: t`common.filters.agency-type.management`,
        },
    ];

    useEffect(() => {
        const { longitude, latitude } = AgencyOverviewSearchFactory.fromQs(
            window.location.search,
        );

        if (latitude || longitude) {
            findPlaceForCoordinates({ longitude, latitude }).then((result) => {
                result?.map((value) => {
                    setInitialLocation({
                        label: value.text,
                        value: value.center,
                    });
                });
            });
        } else {
            setInitialLocation([]);
        }
    }, []);

    const onChange = (newFilter) => {
        if (newFilter === filter) return;

        setFilter(newFilter);
    };

    useEffect(() => {
        if (!filter) return;

        onFilterChanged && onFilterChanged(filter);
    }, [filter]);

    const promiseOptions = async (inputValue) =>
        await findCitiesForAutocomplete(inputValue, language, false);

    const Control = ({ children, ...props }: { children: * }) => (
        <components.Control {...props}>
            {children}
            <Icon icon={Magnify} size={"lg"} className={style.filterIcon} />
        </components.Control>
    );

    const TypeFilter = ({ onChange, filter }: *) => {
        return (
            <Filter.MultiSelect
                label={t`common.filters.agency-type.label`}
                values={typeFilterValues}
                value={filter?.type && ensureArray(filter.type)}
                onChange={(value) => onChange({ type: value })}
                applyBtnText={t`common.filters.apply`}
            />
        );
    };

    return (
        <Filter
            onChange={onChange}
            initialValue={currentFilter}
            filterType={filterType}
        >
            {(applyFilter) => {
                const addFilter = addFilterFactory(
                    currentFilter,
                    null,
                    applyFilter,
                );

                const onLocationSelected = async (value) => {
                    if (value) {
                        const completeValue = await findCoordinatesForPlace(
                            value.text,
                        );
                        applyFilter(completeValue);
                    } else {
                        applyFilter({
                            latitude: undefined,
                            longitude: undefined,
                        });
                    }
                };

                return (
                    <>
                        <div className={style.filters}>
                            {initialLocation && (
                                <AsyncSelect
                                    cacheOptions
                                    styles={customStyles()}
                                    defaultValue={initialLocation}
                                    placeholder={t`common.filters.location.label`}
                                    loadOptions={promiseOptions}
                                    onChange={(value) =>
                                        onLocationSelected(value)
                                    }
                                    isClearable
                                    noOptionsMessage={() => null}
                                    components={{ Control }}
                                    className={style.filterSelect}
                                />
                            )}
                            {addFilter(<TypeFilter />)}
                        </div>
                    </>
                );
            }}
        </Filter>
    );
};

export default AgencyFilter;
