// @flow

import React from "react";

import { SearchPageBanner } from "@2po-c21/components";
import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";
import { EVENT_MAP, type BannerClickEvent } from "../../../models/events";
import Tracker from "../../Tracker/Tracker";

type Props = {
    imageLink: {
        externalLink?: string,
        contentfulInternalLink?: {
            slug?: string,
            query?: string,
        },
        linkTitle?: string,
    },
    image: *,
};

const render = ({ imageLink, image }: Props) => {
    const { src: imageSrc } = image.fluid;

    const { externalLink, contentfulInternalLink, linkTitle } = imageLink || {};

    const targetUrl =
        (contentfulInternalLink && contentfulInternalLink?.slug) || "";
    const trackerEvent: BannerClickEvent = {
        type: EVENT_MAP.SearchPageBannerClickEvent,
        name: "searchpagebanner",
        targeturl: targetUrl,
        targetname: linkTitle || "",
    };

    return (
        <Tracker trackerEvent={trackerEvent}>
            <SearchPageBanner
                imageLink={
                    (contentfulInternalLink
                        ? contentfulInternalLink.slug ||
                          contentfulInternalLink.query
                        : externalLink && externalLink) || ""
                }
                desktopImage={imageSrc}
                desktopImageDescription={image.description || image.title}
            />
        </Tracker>
    );
};

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.SearchPageBanner;

export default createContentfulRenderer(condition, render);
