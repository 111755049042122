// @flow

import React from "react";

import { Filter } from "@2po-c21/components";
import { t } from "ttag";
import { type PropertyFilterProps } from "../types";

const Floors = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const values = facets?.floorNumber
        .filter(({ count }) => count > 0)
        .map(({ value }) => value)
        .sort()
        .map((value) => ({
            value,
            displayValue: value,
        }));

    return (
        <Filter.Dropdown
            label={t`common.filters.floor-number.label`}
            values={values}
            value={filter?.floorNumber}
            onChange={(value) => onChange && onChange({ floorNumber: value })}
        />
    );
};

export default Floors;
