// @flow

import { type Node } from "react";

export interface ContentfulRenderer {
    render: (content: *, key: number, locale?: string) => ?Node | boolean;
}

export const createContentfulRenderer = (
    condition: (content: *) => boolean,
    render: (content: *, key: number, locale?: string) => Node,
): ContentfulRenderer => ({
    render: (content, key, locale) =>
        condition(content) && render(content, key, locale),
});

export const CONTENTFUL_TYPES = {
    CtaGroup: "ContentfulCtaGroup",
    Iframe: "ContentfulIframe",
    HeroVideo: "ContentfulHeroVideo",
    ImageInformationBlock: "ContentfulImageInformationBlock",
    QuickLinkBlock: "ContentfulQuickLinkBlock",
    Blog: "ContentfulBlog",
    Banner: "ContentfulBanner",
    SearchPageBanner: "ContentfulSearchPageBanner",
    RichText: "ContentfulRichText",
    QuickSearch: "ContentfulQuickSearchBlock",
    UspBlock: "ContentfulUspBlock",
    ContactHqBlock: "ContentfulContactHqBlock",
    Container: "ContentfulContainer",
};

export const EMBEDDED_CONTENT_TYPE = {
    Video: "video",
    ImageContainer: "imageContainer",
};

export const IMAGE_INFORMATION_BLOCK_TYPES = {
    Basic: "Basic",
    Search: "Search",
    Split: "Split view",
};

export const QUICK_LINK_BLOCK_TYPES = {
    Basic: "Basic",
    CategoryLink: "Category Link",
    Blog: "Blog",
    Steps: "Steps",
};

export const USP_BLOCK_TYPES = {
    Basic: "Basic",
    Extended: "Extended",
};
