// @flow
import React from "react";
import style from "./style.module.scss";

type Props = {
    children: *,
};

const PropertyHeader = ({ children }: Props) => (
    <div className={style.propertyHeader}>{children}</div>
);

export default PropertyHeader;
