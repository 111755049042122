// @flow

import style from "./style.module.scss";

import React, { type Node, type Element, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Section } from "@containers";
import { ShowHideMap } from "@components";
import { PushEvent } from "../../components/Tracker/Tracker";
import PageContent, { type PageContentProps } from "../PageContent";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import type { MapViewEvent } from "../../models/events";
import Render from "../../components/Contentful";
import { PropertyBanner } from "../../components";
import { t } from "ttag";
import settings from "../../utils/settings";

type Props = {
    headerProps: $Diff<PageContentProps, { children: * }>,
    Filter: Element<*>,
    Map: Element<*>,
    Results: React$Element<*>,
    searchBanner?: Array<*>,
    Breadcrumb?: Node,
    showMapByDefault?: boolean,
};

const FilterType = {
    agency: "agencyFilter",
    property: "propertyFilter",
};

const SearchPage = ({
    headerProps: { locale, navigationItems, translationSlugs, footerItems },
    Filter,
    Map,
    Results,
    Breadcrumb,
    showMapByDefault,
    searchBanner,
}: Props) => {
    const sendToGTM = useGTMDispatch();
    const countryCode = settings.COUNTRY_CODE.toUpperCase();

    const [mapVisible, setMapVisible] = useState(!!showMapByDefault);

    const containsAgencyFilter =
        Filter?.props?.filterType === FilterType.agency;
    const toggleMapView = (visible: boolean) => {
        const gtmEvent: MapViewEvent = {
            active: visible,
            type: "MapViewEvent",
        };
        PushEvent(gtmEvent, sendToGTM);
        setMapVisible(visible);
    };
    const showHideMap = (
        <div className={style.showHideMap}>
            <ShowHideMap
                mapVisible={mapVisible}
                onShowClick={() => {
                    toggleMapView(true);
                }}
                onHideClick={() => toggleMapView(false)}
                className={style.showHideMap}
            />
        </div>
    );

    const RenderSearchPageBanner = () => {
        if (
            Filter?.props?.filterType == FilterType.property &&
            searchBanner?.length
        ) {
            const filteredBanners = (type, countryCode = "") =>
                searchBanner?.filter(
                    (item) =>
                        item.typeOfSearch === type &&
                        ["ALL", countryCode].includes(item.country),
                );
            let toRender = null;

            if (Filter?.props?.currentFilter?.listingType == "FOR_RENT")
                toRender = Render(
                    filteredBanners("For rent", countryCode),
                    locale,
                );

            if (Filter?.props?.currentFilter?.listingType == "FOR_SALE")
                toRender = Render(
                    filteredBanners("For sale", countryCode),
                    locale,
                );
            return (
                <PropertyBanner>
                    {Render(filteredBanners("All", countryCode), locale)}
                    {toRender}
                </PropertyBanner>
            );
        } else {
            return null;
        }
    };

    const RenderSearchResultsHeader = () => {
        if (containsAgencyFilter) {
            return (
                <>
                    <span
                        className={style.agencyOverviewTitle}
                    >{t`agency-overview.our-agencies`}</span>
                    {showHideMap}
                </>
            );
        } else {
            return showHideMap;
        }
    };

    return (
        <PageContent
            locale={locale}
            navigationItems={navigationItems}
            translationSlugs={translationSlugs}
            footerItems={footerItems}
            Breadcrumb={Breadcrumb}
        >
            <div className={containsAgencyFilter ? style.offsetFilter : ""}>
                <Section theme={containsAgencyFilter ? "themeGold" : ""}>
                    <Row>
                        <Col>{Filter}</Col>
                    </Row>
                </Section>
                <RenderSearchPageBanner />
                {mapVisible ? (
                    <Container
                        fluid={true}
                        className={style.searchResultsWrapper}
                    >
                        <div className={style.map}>
                            <div className={style.searchResultsHeader}>
                                <RenderSearchResultsHeader />
                            </div>
                            {Map}
                        </div>
                        <div className={style.results}>{Results}</div>
                    </Container>
                ) : (
                    <Container>
                        {React.cloneElement(Results, {
                            Header: (
                                <div className={style.noMapSearchResultsHeader}>
                                    <RenderSearchResultsHeader />
                                    {Results.props.Header}
                                </div>
                            ),
                        })}
                    </Container>
                )}
            </div>
        </PageContent>
    );
};

export default SearchPage;
