// @flow

import React from "react";
import style from "../style.module.scss";
import { gettext, t } from "ttag";
import { Typography, Icon, Alert } from "@2po-c21/components";
import Render from "../../Contentful";
import { numberToCurrency, toKebabCase } from "../../../utils";

type Props = {
    results: *,
    contentfulData: *,
    locale: string,
};
const Results = ({ results, contentfulData, locale }: Props) => {
    return (
        <div className={style.resultsContainer}>
            {results &&
            (results.data.lowerBound != 0 || results.data.upperBound != 0) ? (
                <>
                    <Typography
                        type="heading-5-bold"
                        className={style.resultsTitle}
                    >{t`form.estimation-tool.result.title`}</Typography>
                    <div className={style.resultWrapper}>
                        {Object.keys(results.data).map((result, key) => (
                            <div className={style.result} key={key}>
                                <div className={style.resultLabel}>
                                    <Typography type="tiny">
                                        {gettext(
                                            `form.estimation-tool.result.${toKebabCase(
                                                result,
                                            )}`,
                                        )}
                                    </Typography>
                                </div>
                                <div className={style.resultValue}>
                                    <Typography type="heading-5-bold">
                                        {numberToCurrency(results.data[result])}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                    {(results.data.lowerBound == 0 ||
                        results.data.upperBound == 0) && (
                        <div className={style.errorContainer}>
                            <div>{t`form.estimation-tool.result.error`}</div>
                        </div>
                    )}
                </>
            ) : (
                <div className={style.errorContainer}>
                    <Icon
                        className={style.errorIcon}
                        size={"lg"}
                        icon={Alert}
                    />
                    <div>
                        {t`form.estimation-tool.result.error-message-description`}
                    </div>
                    <br />
                    <div>{t`form.estimation-tool.result.error`}</div>
                    <div
                        className={style.errorLink}
                        onClick={() => window.location.reload(false)}
                    >
                        {t`form.estimation-tool.result.error-message-link`}
                    </div>
                </div>
            )}
            <div>{Render(contentfulData, locale)}</div>
        </div>
    );
};
export default Results;
