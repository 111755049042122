// @flow

import { createAgencyAssetPath } from "../../endpoints";
import slugify from "slugify";
import { gettext, addLocale, useLocale } from "ttag";

import jobs from "./jobsLinks.json";
import ImageMapper from "../image";
import {
    api_id,
    apply,
    localizeAddress,
    type Mapper,
    shallowCopy,
    setLocale,
    getDomainByCountryCode,
} from "../shared";
import settings from "../../../../utils/settings";

/** non-gatsby mappers */
const slug = ({ locale, name, api_id, address }: *) => {
    const slug = ["", locale, slugify(gettext("slug.agency")), slugify(name)]
        .join("/")
        .toLowerCase();

    // Does the agency country code occur in the listed countries setting,
    // YES => Proceed to default return
    // NO => Can we map via getDomainByCountryCode? (domain + lang) + Format external link

    if (
        !settings.LISTED_COUNTRIES.includes(address.countryCode.toLowerCase())
    ) {
        const { domain, languageMap, agencySlug } = getDomainByCountryCode(
            address.countryCode,
        );

        const language = languageMap[locale];

        const slug = `https://${domain}/${language}/${
            agencySlug[language]
        }/${slugify(name, { lower: true })}/${api_id}`;

        return {
            slug,
        };
    }

    // Return internal slug
    return {
        slug: `${slug}/${api_id}`,
    };
};

const images = (agency: *) => {
    const path = createAgencyAssetPath(agency);

    return {
        images: agency.images
            .map(({ name, description }: *) => {
                try {
                    const [, filename, extension] = name.match(/(.+)(\..+)/);

                    return {
                        description,
                        path,
                        filename,
                        extension,
                    };
                } catch (error) {
                    return null;
                }
            })
            .filter((image) => !!image)
            .map((image) => ({
                description: image.description,
                fixed: (size) => ImageMapper.toFixed(image, size),
            })),
    };
};

const jobsLinks = ({ accountNumber }: *) => {
    const jobId =
        jobs.find((job) => job.accNR === accountNumber)?.jobId || null;

    return {
        jobId,
    };
};

/** gatsby only */

const images___NODE =
    ({ createC21ImageId }: *) =>
    (agency: *) => ({
        images___NODE: agency.images.map((image) =>
            createC21ImageId(createAgencyAssetPath(agency), image.name),
        ),
    });

/** cleanup */

const cleanup = (agency: *) => {
    delete agency.images;
};

/** mappers */

const createSourcePluginMapper = (gatsbyApi: *): Mapper => ({
    map: (agency: *) => {
        const steps: *[] = [
            api_id,
            shallowCopy,
            images___NODE(gatsbyApi),
            jobsLinks,
        ];

        const mapped = apply(steps, (step) => step(agency));

        cleanup(mapped);

        return mapped;
    },
    localize: (agency: *, locale: string) => {
        const steps: *[] = [shallowCopy, setLocale, localizeAddress];

        return apply(steps, (step) => step(agency, locale));
    },
    mapLocalized: (agency: *, translations: *) => {
        addLocale(agency.locale, translations);
        useLocale(agency.locale);

        const steps: *[] = [shallowCopy, slug];

        return apply(steps, (step) => step(agency));
    },
});

const createSearchMapper = (): Mapper => ({
    map: (agency: *) => {
        const steps: *[] = [api_id, shallowCopy, images];

        return apply(steps, (step) => step(agency));
    },
    localize: (agency: *, locale: string) => {
        const steps: *[] = [shallowCopy, setLocale, localizeAddress];

        return apply(steps, (step) => step(agency, locale));
    },
    mapLocalized: (agency: *) => {
        const steps: *[] = [shallowCopy, slug];

        return apply(steps, (step) => step(agency));
    },
});

const MapperFactory = {
    forSourcePlugin: (gatsbyApi: *): Mapper =>
        createSourcePluginMapper(gatsbyApi),
    forSearch: (): Mapper => createSearchMapper(),
};

export default MapperFactory;

export const mapAgenciesForSearch = (data: *, locale: *) => {
    const mapper = MapperFactory.forSearch();

    return data
        .map(mapper.map)
        .map((agency) => mapper.localize(agency, locale))
        .map(mapper.mapLocalized)
        .map((agency) => ({
            ...agency,
            images: agency.images.map((image) => ({
                description: image.description,
                fixed: image.fixed({
                    width: 235,
                    height: 235,
                }),
            })),
        }));
};
