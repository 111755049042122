// @flow

import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { type PropertyFilterProps } from "../types";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import PropertyOverviewSearchFactory from "@utils/search/property-overview";
import {
    findCitiesForAutocomplete,
    findPostalCodeForCoordinates,
} from "@utils/mapbox";
import { ensureArray } from "@utils/array";
import axios from "axios";
import { getMapBoxUrl } from "@utils/mapbox";
import customStyles from "./styles";
import classnames from "classnames";

const Location = ({
    onChange,
    filter,
    language,
    className,
    additionalClassName,
}: PropertyFilterProps) => {
    const filterValue = ensureArray(filter?.location);
    const [initialLocation, setInitialLocation] = useState();
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        const QS = PropertyOverviewSearchFactory.fromQs(window.location.search);

        const initialLocationValue = QS?.current?.filter?.location;

        if (initialLocationValue?.length) {
            getLocationName(initialLocationValue);
        } else {
            setInitialLocation([]);
        }
    }, []);

    const flat = (arr: any) => {
        return Array.prototype.flat ? arr.flat() : [].concat(...arr);
    };

    const getLocationName = async (postalCode) => {
        const results = await axios.get(
            getMapBoxUrl(
                postalCode.join("&"),
                "postcode",
                postalCode.length.toString(),
            ),
        );

        const displayValue = results.data.features;

        const newArray = flat(
            displayValue.map((value) => {
                const text = value.context.find((location) =>
                    location.id.startsWith("place."),
                );

                return {
                    label: value.place_name,
                    value: value.text,
                    postalCode: value.text,
                    text: text.text,
                };
            }),
        );

        setInitialLocation(newArray);

        setSelected(
            flat(
                displayValue.map((value, index) => {
                    return {
                        label: value.text,
                        value: postalCode[index],
                        postalCode: postalCode[index],
                    };
                }),
            ),
        );
    };

    const onSelectValue = (value, { action }) => {
        let coordinates;

        if (action === "select-option") {
            coordinates = value[value.length - 1].value;

            if (coordinates) {
                findPostalCodeForCoordinates(coordinates).then((result) => {
                    if (filterValue.indexOf(result) === -1) {
                        filterValue.push(result);

                        const diff = function (arr1, arr2) {
                            return arr1.filter((x) => !arr2.includes(x));
                        };

                        const filteredValues = diff(value, selected);
                        const newItem =
                            filteredValues[filteredValues.length - 1];

                        newItem.postalCode = result;

                        setSelected([...selected, newItem]);
                    }
                    onChange && onChange({ location: filterValue });
                });
            }
        }

        if (action === "remove-value") {
            if (value === null) {
                onChange && onChange({ location: [] });
            } else {
                onChange &&
                    onChange({
                        location: value.map((a) => a.postalCode),
                    });
            }
        }

        if (action === "clear") {
            onChange && onChange({ location: [] });
        }
    };

    const promiseOptions = async (inputValue) =>
        await findCitiesForAutocomplete(inputValue, language);

    // eslint-disable-next-line react/display-name
    const MultiValue = (props) => {
        // eslint-disable-next-line react/prop-types
        if (props.data) {
            // eslint-disable-next-line react/prop-types
            const text = props.data.text;
            return (
                <components.MultiValue {...props}>{text}</components.MultiValue>
            );
        }
    };
    return (
        <div className={classnames(className, additionalClassName)}>
            {initialLocation && (
                <AsyncSelect
                    isMulti
                    styles={customStyles(className)}
                    defaultValue={initialLocation}
                    placeholder={t`common.filters.location.label`}
                    loadOptions={promiseOptions}
                    onChange={(value, action) => onSelectValue(value, action)}
                    components={{ MultiValue }}
                    noOptionsMessage={() => null}
                />
            )}
        </div>
    );
};

export default Location;
