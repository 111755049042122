// @flow

import queryString from "query-string";

import axios from "axios";
import { endpoints } from "../../c21-api";
import { type SimpleSearch, type AgencyOverviewFilter } from "../types";
import { ensureArray } from "../../array";

const PAGE_SIZE = 10000;

class AgencySearch implements SimpleSearch {
    coordinates: *;
    type: *;

    constructor(props: AgencyOverviewFilter) {
        const { longitude, latitude, type } = props;

        this.coordinates = { longitude, latitude };
        this.type = ensureArray(type);
    }

    async search(): Promise<*> {
        if (
            (this.coordinates &&
                this.coordinates.latitude &&
                this.coordinates.longitude) ||
            this?.type
        ) {
            const matchTypes =
                this?.type &&
                this.type.reduce((arr, el) => {
                    arr.push({
                        bool: {
                            should: {
                                match: {
                                    [el]: true,
                                },
                            },
                        },
                    });
                    return arr;
                }, []);
            const { latitude, longitude } = this.coordinates;
            const request = queryString.stringifyUrl({
                url: endpoints.agencies,
                query: {
                    pageSize: `${PAGE_SIZE}`,
                    ...(latitude &&
                        longitude && {
                            sortByPoint: `${longitude},${latitude}`,
                        }),
                    // Append current country code to the query
                    filter: btoa(
                        JSON.stringify({
                            bool: {
                                filter: {
                                    bool: {
                                        must: [
                                            ...(matchTypes?.length
                                                ? matchTypes
                                                : []),
                                            {
                                                bool: {
                                                    should: [
                                                        {
                                                            match: {
                                                                "address.countryCode":
                                                                    process.env
                                                                        .GATSBY_COUNTRY_CODE,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                        }),
                    ),
                },
            });

            return axios.get(request);
        }
    }

    toBrowserQueryString() {
        const qs = queryString.stringify({
            ...(this?.coordinates?.longitude && {
                longitude: this.coordinates.longitude,
            }),
            ...(this?.coordinates?.latitude && {
                latitude: this.coordinates.latitude,
            }),
            ...(this?.type?.length && {
                type: this.type.join("-"),
            }),
        });
        return qs && `?${qs}`;
    }
}

const fromCoordinates = (coordinates: *): AgencySearch =>
    new AgencySearch(coordinates);

const fromQs = (qs: string) => queryString.parse(qs) || {};

export default {
    fromCoordinates,
    fromQs,
};
