// @flow

import React from "react";

import { gettext, t } from "ttag";
import style from "./style.module.scss";
import Image from "gatsby-image";

import { Row, Col } from "reactstrap";
import {
    Map,
    Pin,
    Pins,
    Address,
    Icon,
    EmailReceive,
    AgencyCard,
    Phone,
} from "@2po-c21/components";

type Props = {
    agency: {
        name: string,
        phoneNumber: string,
        images: Array<{
            description: string,
            fixed: *,
        }>,
        email: string,
        location: { longitude: string, latitude: string },
        address: {
            city: string,
            postalCode: string,
            number: string,
            street: string,
            countryCode: string,
        },
        companyNumber: string,
        companyName: string,
        bivNumber: string,
        baNumber: string,
        bankAccount: string,
        facebookUrl: string,
        broker: boolean,
        syndic: boolean,
        management: boolean,
    },
    fallbackImage: string,
    country: string,
};

const AgencyInformation = ({
    agency: {
        location,
        name,
        address,
        email,
        companyNumber,
        bivNumber,
        baNumber,
        bankAccount,
        phoneNumber,
        companyName,
        broker,
        syndic,
        management,
    },
    agency,
    fallbackImage,
    country,
}: Props) => {
    if (!location) return null; // TODO [TKN-20200818] agencies should always have a location, and map with contact details should always be shown (Bert Vandepoele to ask business)

    const { longitude, latitude } = location;

    const image = agency.images[0] || fallbackImage;

    const cardImage = {
        // eslint-disable-next-line react/display-name
        imageSrc: () => (
            <Image
                fixed={image?.fixed}
                alt={image?.description}
                backgroundColor
            />
        ),
        description: image?.description,
    };

    const agencyTypes = Object.entries({ broker, syndic, management })
        .filter(([key, value]) => {
            if (value) return key;
        })
        .map(([key]) => {
            return gettext(t`agency.information.details.${key}`);
        });

    return (
        <div className={style.agencyInformation} id="agency-information">
            <div className={style.title}>{t`agency.information.find-us`}</div>
            <Row>
                <Col lg="6">
                    <div className={style.mapDesktop}>
                        <Map>
                            <Pins>
                                <Pin
                                    latitude={latitude}
                                    longitude={longitude}
                                />
                            </Pins>
                        </Map>
                    </div>
                    <div className={style.mapMobile}>
                        <Map>
                            <Pins>
                                <Pin
                                    latitude={latitude}
                                    longitude={longitude}
                                    isOpen
                                >
                                    <AgencyCard
                                        agency={agency}
                                        image={cardImage}
                                        phoneNumber={agency.phoneNumber}
                                    />
                                </Pin>
                            </Pins>
                        </Map>
                    </div>
                </Col>

                <Col lg="6" className={style.informationText}>
                    <div className={style.name}>{name}</div>
                    <Address address={address} type="agencyLocation" />
                    <div className={style.contact}>
                        <div className={style.mail}>
                            <Icon icon={EmailReceive} className={style.icon} />
                            <a href={`mailto: ${email}`}>{email}</a>
                        </div>
                        {phoneNumber && (
                            <div className={style.phoneNumber}>
                                <Icon
                                    icon={Phone}
                                    className={style.icon}
                                ></Icon>
                                <a
                                    href={`tel:${phoneNumber.replace(
                                        /\/|\s/g,
                                        "",
                                    )}`}
                                >
                                    {phoneNumber}
                                </a>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <Row className={style.informationDetailsWrapper}>
                <Col lg={"6"}>
                    <div className={style.informationDetailsTitle}>
                        {agencyTypes.length > 0 &&
                            t`agency.information.details ${
                                agencyTypes.length === 1
                                    ? agencyTypes[0]
                                    : agencyTypes.join(", -")
                            }`}
                    </div>
                    <div>
                        {bankAccount && (
                            <div className={style.informationDetailsItem}>
                                <span
                                    className={
                                        style.informationDetailsItemLabel
                                    }
                                >{t`agency.information.details.bank-account`}</span>
                                <span>{bankAccount}</span>
                            </div>
                        )}
                        {address.countryCode === "BE" && (
                            <div className={style.informationDetailsItem}>
                                <span
                                    className={
                                        style.informationDetailsItemLabel
                                    }
                                >{t`agency.information.details.biv-number`}</span>
                                <span>{`${bivNumber} (${t`agency.information.details.biv-origin`})`}</span>
                            </div>
                        )}

                        <div className={style.informationDetailsItem}>
                            <span
                                className={style.informationDetailsItemLabel}
                            >{t`agency.information.details.company-number`}</span>
                            <span>{companyNumber}</span>
                        </div>
                        <div className={style.informationDetailsItem}>
                            <span
                                className={style.informationDetailsItemLabel}
                            >{t`agency.information.details.ba-number`}</span>
                            <span>{baNumber}</span>
                        </div>
                        {companyName && (
                            <div className={style.informationDetailsItem}>
                                <span
                                    className={
                                        style.informationDetailsItemLabel
                                    }
                                >{t`agency.information.details.company-name`}</span>
                                <span>{companyName}</span>
                            </div>
                        )}
                        {country.toUpperCase() === "LU" && (
                            <>
                                <div className={style.informationDetailsItem}>
                                    <span
                                        className={
                                            style.informationDetailsItemLabel
                                        }
                                    >{t`agency.information.details.authorization-number`}</span>
                                    <span>10131847/0</span>
                                </div>
                                <div className={style.informationDetailsItem}>
                                    <span
                                        className={
                                            style.informationDetailsItemLabel
                                        }
                                    >{t`agency.information.details.register-number`}</span>
                                    <span>B257240</span>
                                </div>
                            </>
                        )}
                    </div>
                </Col>
                {address.countryCode === "BE" && (
                    <Col
                        lg={"6"}
                        className={style.informationDetailsRightColumn}
                    >
                        <div
                            className={style.informationDetailsRightColumnItem}
                        >
                            <div
                                className={style.informationDetailsAddressTitle}
                            >{t`agency.information.details.ipi-address.title`}</div>
                            <div
                                className={
                                    style.informationDetailsAddressDescription
                                }
                            >{t`agency.information.details.ipi-address.description`}</div>
                        </div>
                        <div
                            className={style.informationDetailsRightColumnItem}
                        >
                            <div
                                className={style.informationDetailsWebsiteTitle}
                            >{t`agency.information.details.ipi-website.title`}</div>
                            <a
                                className={
                                    style.informationDetailsWebsiteDescription
                                }
                                href={t`agency.information.details.ipi-website.url`}
                            >{t`agency.information.details.ipi-website.description`}</a>
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default AgencyInformation;
