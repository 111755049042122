// @flow

import React, { useState } from "react";
import style from "../style.module.scss";
import classnames from "classnames";
import { Formik } from "formik";
import {
    Button,
    Form as ReactstrapForm,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import { t } from "ttag";
import {
    getValidationSchema,
    getInitialValues,
    renderInput,
    renderRadioButton,
    renderErrorMessage,
} from "../utils";
import {
    Buildings,
    OpenBuilding,
    SemiOpenBuilding,
    ClosedBuilding,
    Typography,
} from "@2po-c21/components";

type StepProps = {
    nextStep: () => void,
    updateFormCollection: (values: *, isComplete: boolean) => void,
};

const First = ({ nextStep, updateFormCollection }: StepProps) => {
    const [sliderValue, setSliderValue] = useState(0);
    const choices = {
        radioButtons: [
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.property-type.house`,
                value: "HOUSE",
                name: "propertyType",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: OpenBuilding,
            },
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.property-type.apartment`,
                value: "APARTMENT",
                name: "propertyType",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: Buildings,
            },
        ],
        secondRadioButtons: [
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.building-type.open`,
                value: "OPEN",
                name: "buildingType",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: OpenBuilding,
            },
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.building-type.semi-open`,
                value: "SEMI_OPEN",
                name: "buildingType",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: SemiOpenBuilding,
            },
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.building-type.closed`,
                value: "CLOSED",
                name: "buildingType",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: ClosedBuilding,
            },
        ],
        sliders: [
            {
                type: "range",
                displayValue: t`form.estimation-tool.fields.number-of-bedrooms`,
                minValue: 0,
                maxValue: 5,
                name: "numberOfRooms",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
            },
        ],
        inputFields: [
            {
                type: "number",
                displayValue: t`form.estimation-tool.fields.livable-surface-area`,
                value: null,
                name: "livableSurfaceArea",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
            },
            {
                type: "number",
                displayValue: t`form.estimation-tool.fields.total-surface-area`,
                value: null,
                name: "totalSurfaceArea",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
            },
        ],
        booleans: [
            {
                type: "checkbox",
                displayValue: t`form.estimation-tool.fields.garden`,
                value: false,
                name: "garden",
                required: false,
                errorMessage: t`form.estimation-tool.fields.error-message`,
            },
            {
                type: "checkbox",
                displayValue: t`form.estimation-tool.fields.terrace`,
                value: false,
                name: "terrace",
                required: false,
                errorMessage: t`form.estimation-tool.fields.error-message`,
            },
        ],
    };

    const submitForm = (values) => {
        updateFormCollection(values, false);
        nextStep && nextStep();
    };

    const handleDropdownChange = (e, name, setFieldValue) => {
        setSliderValue(e.target.value);
        setFieldValue(name, e.target.value);
    };

    return (
        <div className={style.stepContainer}>
            <Typography
                type="section-title"
                className={style.estimationToolTitle}
            >{t`form.estimation-tool.title`}</Typography>
            <Formik
                initialValues={getInitialValues(choices, {
                    garden: false,
                    terrace: false,
                })}
                validationSchema={getValidationSchema(choices)}
                onSubmit={(values) => submitForm(values)}
            >
                {({
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                }) => (
                    <ReactstrapForm onSubmit={handleSubmit}>
                        <FormGroup className={style.formGroup}>
                            <div>{t`form.estimation-tool.fields.property-type.label`}</div>
                            <div className={style.radioButtonGroup}>
                                {choices.radioButtons.map((radioButton, key) =>
                                    renderRadioButton(
                                        radioButton,
                                        handleChange,
                                        key,
                                    ),
                                )}
                            </div>
                            {errors?.propertyType &&
                                renderErrorMessage(errors?.propertyType)}
                        </FormGroup>
                        <FormGroup className={style.formGroup}>
                            <div>{t`form.estimation-tool.fields.building-type.label`}</div>
                            <div className={style.radioButtonGroup}>
                                {choices.secondRadioButtons.map(
                                    (radioButton, key) =>
                                        renderRadioButton(
                                            radioButton,
                                            handleChange,
                                            key,
                                        ),
                                )}
                            </div>
                            {errors?.buildingType &&
                                renderErrorMessage(errors?.buildingType)}
                        </FormGroup>
                        <FormGroup>
                            {choices.sliders.map((slider, key) => (
                                <Label key={key} className={style.slider}>
                                    <span>{slider.displayValue}</span>
                                    <Input
                                        type={slider.type}
                                        name={slider.name}
                                        defaultValue={0}
                                        min={slider.minValue}
                                        max={slider.maxValue}
                                        onChange={(e) =>
                                            handleDropdownChange(
                                                e,
                                                slider.name,
                                                setFieldValue,
                                            )
                                        }
                                    />
                                    {sliderValue}
                                </Label>
                            ))}
                        </FormGroup>
                        <FormGroup>
                            {choices.inputFields.map((inputField, key) => (
                                <div key={key} className={style.inputField}>
                                    {renderInput(
                                        inputField,
                                        handleChange,
                                        handleBlur,
                                        errors,
                                        touched,
                                    )}
                                </div>
                            ))}
                        </FormGroup>
                        <FormGroup
                            className={classnames(
                                style.checkboxContainer,
                                style.formGroup,
                            )}
                        >
                            {choices.booleans.map((boolean, key) => (
                                <Label key={key} className={style.checkbox}>
                                    <div className={style.checkboxText}>
                                        {boolean.displayValue}
                                    </div>
                                    <Input
                                        type="checkbox"
                                        name={boolean.name}
                                        onChange={handleChange}
                                        value={boolean.value}
                                    />
                                </Label>
                            ))}
                        </FormGroup>
                        <div
                            className={classnames(
                                style.buttonContainer,
                                style.noBackButton,
                            )}
                        >
                            <Button color="primary" type="submit">
                                {t`form.estimation-tool.fields.next`}
                            </Button>
                        </div>
                    </ReactstrapForm>
                )}
            </Formik>
        </div>
    );
};

export default First;
