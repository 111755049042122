// @flow

import React from "react";
import { Icon, Printer as PrinterIcon, Typography } from "@2po-c21/components";
import { t } from "ttag";
import style from "./style.module.scss";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { type PrintEvent, EVENT_MAP } from "../../models/events";
import { PushEvent } from "../Tracker/Tracker";

type Props = {
    propertyId: string,
};
const Print = ({ propertyId }: Props) => {
    const gtmDispatcher = useGTMDispatch();

    const sendPrintEvent = () => {
        const gtmEvent: PrintEvent = {
            propertyid: propertyId,
            propertyview: "detail",
            type: EVENT_MAP.PrintEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    return (
        <div className={style.print}>
            <div
                onClick={() => {
                    sendPrintEvent();
                    window.print();
                }}
            >
                <Icon icon={PrinterIcon} size="md" />
                <Typography
                    type="caption"
                    className={style.printDescription}
                >{t`common.print`}</Typography>
            </div>
        </div>
    );
};

export default Print;
