// @flow

import React from "react";

import { Filter } from "@2po-c21/components";
import { t, gettext } from "ttag";
import { type PropertyFilterProps } from "../types";

const values = ["FOR_SALE", "FOR_RENT"];

const ListingType = ({ onChange, filter, className }: PropertyFilterProps) => (
    <Filter.Dropdown
        label={t`common.filters.listing-type.label`}
        values={values.map((value) => ({
            value,
            displayValue: gettext(`api.property.listing-type.${value}`),
        }))}
        value={filter?.listingType}
        onChange={(value) => onChange && onChange({ listingType: value })}
        className={className}
    />
);

export default ListingType;
