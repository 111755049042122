// @flow

import currency from "currency.js";

export const numberToCurrency = (number: number) =>
    currency(number, {
        formatWithSymbol: true,
        symbol: "€",
        pattern: `! #`,
        separator: ".",
        decimal: ",",
        precision: 0,
    }).format();

export const currencyToNumber = (value: *): number => +value.replace(/\D/g, "");
