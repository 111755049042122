// @flow

import React from "react";
import { UspTiles } from "@2po-c21/components";
import {
    createContentfulRenderer,
    CONTENTFUL_TYPES,
    USP_BLOCK_TYPES,
} from "../types";

type Props = {
    uspItems: *,
};

const render = ({ uspItems }: Props) => {
    return (
        <UspTiles
            tiles={uspItems.map((item) => {
                return {
                    data: item.linkTitle,
                    label: item.linkSubtitle?.linkSubtitle,
                    icon: item.icon,
                };
            })}
        />
    );
};

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.UspBlock &&
    content.type === USP_BLOCK_TYPES.Basic;

export default createContentfulRenderer(condition, render);
