// @flow

import esb from "elastic-builder";
import queryString from "query-string";
import { endpoints } from "../../c21-api";
import { Interactions } from "../interactions";
import {
    type PropertyOverviewFilter,
    type Search,
    type Sort,
    type Strategy,
} from "../types";
import { ensureArray } from "../../array";
import memo from "../../memo";
import {
    SearchBase,
    buildFromGenerator,
    buildSingleValueFilterQuery,
    buildMultiValueFilterQuery,
    buildRangeFilterQuery,
    SEARCH_DEFAULTS,
} from "../shared";

const RANGE_FILTER_KEYS = [
    "bedrooms",
    "habitableSurfaceArea",
    "price",
    "surfaceAreaGarden",
    "totalSurfaceArea",
];

const createRangeFilters = memo(() =>
    RANGE_FILTER_KEYS.map((field) => ({
        field,
        labelLower: `${field}Min`,
        labelUpper: `${field}Max`,
    })),
);

const RANGE_FILTERS = createRangeFilters();

const MULTI_VALUE_FILTERS = [
    "type",
    "location",
    "condition",
    "subType",
    "countryCode",
];

/**
 * Returns a list of allowed countries when the value is undefined or
 * filters the value to only include allowed countries.
 */
const getAllowedCountries = (countryCodes?: string[]) => {
    const allowedCountries =
        process.env.GATSBY_ALLOWED_COUNTRIES?.split(",") || [];

    if (!allowedCountries.length) {
        return countryCodes;
    }

    if (!countryCodes?.length) {
        return allowedCountries;
    }

    return countryCodes.filter((code) => allowedCountries.includes(code));
};

class PropertyOverviewSearchStrategy
    implements Strategy<PropertyOverviewFilter>
{
    endpoint = endpoints.properties;

    buildElasticQuery = (filter: PropertyOverviewFilter): * => {
        const {
            condition,
            elevator,
            floorNumber,
            garden,
            bedrooms,
            habitableSurfaceArea,
            listingType,
            location,
            parking,
            price,
            subType,
            surfaceAreaGarden,
            swimmingPool,
            terrace,
            totalSurfaceArea,
            type,
            agency,
            countryCode,
        } = filter;

        const matchQueries = [
            buildMultiValueFilterQuery("address.postalCode", location),
            buildMultiValueFilterQuery("condition", condition),
            buildMultiValueFilterQuery(
                "address.countryCode",
                getAllowedCountries(countryCode),
            ),
            buildSingleValueFilterQuery("amenities.garden", garden),
            buildSingleValueFilterQuery("amenities.elevator", elevator),
            buildSingleValueFilterQuery("amenities.parking", parking),
            buildSingleValueFilterQuery("amenities.swimmingPool", swimmingPool),
            buildSingleValueFilterQuery("agencyId.keyword", agency),
            buildSingleValueFilterQuery("amenities.terrace", terrace),
            buildSingleValueFilterQuery("floorNumber", floorNumber),
            buildSingleValueFilterQuery("listingType", listingType),
            buildRangeFilterQuery("price.amount", price),
            buildRangeFilterQuery("rooms.numberOfBedrooms", bedrooms),
            buildMultiValueFilterQuery("subType", subType),
            buildRangeFilterQuery(
                "surface.habitableSurfaceArea.value",
                habitableSurfaceArea,
            ),
            buildRangeFilterQuery(
                "surface.surfaceAreaGarden.value",
                surfaceAreaGarden,
            ),
            buildRangeFilterQuery(
                "surface.totalSurfaceArea.value",
                totalSurfaceArea,
            ),
            buildMultiValueFilterQuery("type", type),
            buildRangeFilterQuery("creationDate", {
                upper: window.__BUILD_TIMESTAMP__,
            }),
        ].filter((query) => !!query);

        const query = esb
            .boolQuery()
            .filter([esb.boolQuery().must(matchQueries)]);

        return query;
    };

    getFacets = () =>
        [
            "elevator",
            "condition",
            "floorNumber",
            "garden",
            "habitableSurfaceArea",
            "listingType",
            "numberOfBedrooms",
            "parking",
            "price",
            "subType",
            "surfaceAreaGarden",
            "swimmingPool",
            "terrace",
            "totalSurfaceArea",
            "type",
        ].join(",");

    buildBrowserQueryString = (
        interactions: Interactions<PropertyOverviewFilter>,
    ) => {
        return buildFromGenerator(() =>
            this.buildBrowserQueryStringParts(interactions),
        );
    };

    buildBrowserQueryStringParts = function* (
        interactions: Interactions<PropertyOverviewFilter>,
    ) {
        const { filter, sort, page } = interactions;

        if (filter) {
            for (let key: string of Object.keys(filter)) {
                if (RANGE_FILTER_KEYS.includes(key)) continue;

                yield { [key]: filter[key] };
            }

            yield* this.buildBrowserQueryStringPartsForRange(filter);
        }

        if (
            sort &&
            !(
                sort.field === SEARCH_DEFAULTS.sorting.field &&
                sort.direction === SEARCH_DEFAULTS.sorting.direction
            )
        )
            yield { sortOn: sort.field, sortDirection: sort.direction };
        if (page && page > 1) yield { page };
    };

    buildBrowserQueryStringPartsForRange = function* (
        filter: PropertyOverviewFilter,
    ) {
        for (let { field, labelLower, labelUpper } of RANGE_FILTERS) {
            const rangeFilter = filter[field];

            if (typeof rangeFilter?.lower !== "undefined")
                yield { [labelLower]: rangeFilter.lower };
            if (typeof rangeFilter?.upper !== "undefined")
                yield { [labelUpper]: rangeFilter.upper };
        }
    };
}

const PropertyOverviewSearchFactory = {
    fromQs: (qs: string): Search<PropertyOverviewFilter> => {
        const searchBase = new SearchBase(
            new PropertyOverviewSearchStrategy(),
            initializeInteractions(queryString.parse(qs) || {}),
        );

        return searchBase;
    },
    fromInitialValues: ({
        filter,
        sort,
        page,
        pageSize,
    }: {
        filter?: *,
        sort?: Sort,
        page?: number,
        pageSize?: number,
    }): Search<PropertyOverviewFilter> => {
        const initialInteractions = Object.assign({}, { ...filter });

        if (sort) {
            initialInteractions.sortOn = sort.field;
            initialInteractions.sortDirection = sort.direction;
        }
        if (page) initialInteractions.page = page;

        const result = new SearchBase(
            new PropertyOverviewSearchStrategy(),
            initializeInteractions(initialInteractions),
            pageSize,
        );

        return result;
    },
};

export default PropertyOverviewSearchFactory;

const initializeInteractions = (init) => {
    const defaults = {
        sortOn: SEARCH_DEFAULTS.sorting.field,
        sortDirection: SEARCH_DEFAULTS.sorting.direction,
        page: 1,
    };

    const { sortOn, sortDirection, page, ...filterData } = Object.assign(
        {},
        defaults,
        init,
    );

    const sort = {
        field: sortOn,
        direction: sortDirection,
    };

    const filter = initializeFilter(filterData);

    return new Interactions<PropertyOverviewFilter>(filter, sort, page);
};

const initializeFilter = (
    filter: PropertyOverviewFilter,
): PropertyOverviewFilter => {
    const initializedFilter = { ...filter };

    MULTI_VALUE_FILTERS.forEach((field) => {
        if (!initializedFilter[field]) return;

        initializedFilter[field] = ensureArray(initializedFilter[field]);
    });

    RANGE_FILTERS.forEach(({ field, labelLower, labelUpper }) => {
        if (
            !initializedFilter[field] &&
            (initializedFilter[labelLower] || initializedFilter[labelUpper])
        ) {
            initializedFilter[field] = {
                lower: toMaybeNumber(initializedFilter[labelLower]),
                upper: toMaybeNumber(initializedFilter[labelUpper]),
            };
        }

        delete initializedFilter[labelLower];
        delete initializedFilter[labelUpper];
    });

    return initializedFilter;
};

const toMaybeNumber = (value: ?string) =>
    typeof value === "undefined" ? undefined : +value;
