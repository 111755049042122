// @flow

const memo = (func: Function) => {
    const cache = {};

    return (...args: *) => {
        const key = JSON.stringify(args);

        if (!cache[key]) cache[key] = func.apply(null, args);

        return cache[key];
    };
};

export default memo;
