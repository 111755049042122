// @flow

import React from "react";

import { QuickInfoImage as InfoImage } from "@2po-c21/components";
import Image from "gatsby-image";
import Link from "../../Link";
import {
    createContentfulRenderer,
    CONTENTFUL_TYPES,
    IMAGE_INFORMATION_BLOCK_TYPES,
} from "../types";

type Props = {
    link?: {
        linkTitle: string,
        externalLink?: string,
        contentfulInternalLink?: {
            slug?: string,
            query?: string,
        },
        fileLink?: {
            file?: {
                url?: string,
            },
        },
    },
    content: {
        contentTitle?: string,
        landscapeImage: *,
    },
};

const render = (
    { link, content: { contentTitle, landscapeImage } }: Props,
    key,
) => {
    const { linkTitle, contentfulInternalLink, externalLink } = link || {};
    const LinkComponent = ({ children }: *) =>
        link ? (
            <Link
                to={
                    (contentfulInternalLink
                        ? contentfulInternalLink.slug ||
                          contentfulInternalLink.query
                        : externalLink) || ""
                }
                fileUri={link?.fileLink?.file?.url}
            >
                {children}
            </Link>
        ) : null;

    return (
        <InfoImage
            key={key}
            info={contentTitle}
            buttonTxt={linkTitle}
            LinkComponent={LinkComponent}
            image={{
                // eslint-disable-next-line react/display-name
                imageSrc: () => (
                    <Image
                        fluid={landscapeImage.fluid}
                        alt={landscapeImage?.description}
                        backgroundColor
                    />
                ),
                description: landscapeImage?.description,
            }}
        />
    );
};

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.ImageInformationBlock &&
    content.type === IMAGE_INFORMATION_BLOCK_TYPES.Basic;

export default createContentfulRenderer(condition, render);
