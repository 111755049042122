// @flow

import React, { useState, useEffect } from "react";
import style from "../style.module.scss";
import { Field, Formik } from "formik";
import { FormInput, Typography } from "@2po-c21/components";
import * as yup from "yup";
import { findCoordinatesForPostalCode } from "@utils/mapbox";
import queryString from "query-string";
import axios from "axios";
import { endpoints } from "../../../utils/c21-api";

import {
    FormGroup,
    Label,
    Input,
    Button,
    Form as ReactstrapForm,
} from "reactstrap";
import { t } from "ttag";
import {
    getValidationSchema,
    getInitialValues,
    renderDropdown,
    renderErrorMessage,
} from "../utils";

type StepProps = {
    previousStep: () => void,
    nextStep: () => void,
    updateFormCollection: (values: *, isComplete: boolean) => void,
    postalCode: string,
    presetAgencyId?: string,
};

const PAGE_SIZE = 10000;

const Last = ({
    previousStep,
    nextStep,
    updateFormCollection,
    postalCode,
    presetAgencyId,
}: StepProps) => {
    const formMessages = {
        checkBoxMessage: t`form.estimation-tool.fields.privacy-message`,
        checkBoxLink: t`form.estimation-tool.fields.privacy-policy`,
        reCaptcha: {
            warning: t`form.estimation-tool.fields.recaptcha-warning`,
            policy: t`form.estimation-tool.fields.recaptcha-policy`,
            tos: t`form.estimation-tool.fields.recaptcha-tos`,
            and: t`common.text.and`,
        },
    };

    const {
        checkBoxMessage,
        checkBoxLink,
        reCaptcha: { warning, policy, tos, and },
    } = formMessages;

    const [agencyCoordinates, setAgencyCoordinates] = useState({});
    const [agenciesFromState, setAgenciesFromState] = useState([]);

    useEffect(() => {
        findCoordinatesForPostalCode(postalCode).then((result) => {
            setAgencyCoordinates(result);
        });
    }, []);

    useEffect(() => {
        const { longitude, latitude } = agencyCoordinates;
        if (longitude && latitude) {
            const request = queryString.stringifyUrl({
                url: endpoints.agencies,
                query: {
                    pageSize: `${PAGE_SIZE}`,
                    sortByPoint: `${latitude},${longitude}`,
                    // Append current country code to the query
                    filter: btoa(
                        JSON.stringify({
                            bool: {
                                filter: {
                                    bool: {
                                        must: [
                                            {
                                                bool: {
                                                    should: [
                                                        {
                                                            match: {
                                                                "address.countryCode":
                                                                    process.env
                                                                        .GATSBY_COUNTRY_CODE,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                        }),
                    ),
                },
            });

            axios.get(request).then((result) => {
                setAgenciesFromState(result.data.data);
            });
        }
    }, [agencyCoordinates]);

    const fetchAgencyData = (agenciesFromState) => {
        const mappedData = agenciesFromState.map((agency) => {
            return {
                label: `${agency.name}, (${agency.address.city})`,
                value: agency.id,
            };
        });
        return mappedData;
    };
    const getFields = () => {
        return {
            inputFields: [
                {
                    id: "firstName",
                    name: "firstName",
                    label: t`form.estimation-tool.fields.firstname`,
                    required: true,
                    placeholder: t`form.estimation-tool.fields.firstname.placeholder`,
                    type: "text",
                    errorMessage: t`form.estimation-tool.fields.error-message`,
                },
                {
                    id: "lastName",
                    name: "lastName",
                    label: t`form.estimation-tool.fields.lastname`,
                    required: true,
                    placeholder: t`form.estimation-tool.fields.lastname.placeholder`,
                    type: "text",
                    errorMessage: t`form.estimation-tool.fields.error-message`,
                },
                {
                    id: "phoneNumber",
                    name: "phoneNumber",
                    label: t`form.estimation-tool.fields.phone`,
                    required: true,
                    placeholder: t`form.estimation-tool.fields.phone.placeholder`,
                    type: "tel",
                    errorMessage: t`form.estimation-tool.fields.error-message`,
                    phoneErrorMessage: t`form.estimation-tool.fields.error-invalid`,
                },
                {
                    id: "email",
                    name: "email",
                    label: t`form.estimation-tool.fields.email`,
                    required: true,
                    placeholder: t`form.estimation-tool.fields.email.placeholder`,
                    type: "email",
                    errorMessage: t`form.estimation-tool.fields.error-message`,
                    emailErrorMessage: t`form.estimation-tool.fields.error-invalid`,
                },
            ],
            dropdowns: [
                {
                    type: "dropdown",
                    id: "agencyId",
                    name: "agencyId",
                    label: t`form.estimation-tool.fields.agency`,
                    displayValue: t`form.estimation-tool.fields.agency`,
                    placeholder: t`form.estimation-tool.fields.agency-placeholder`,
                    errorMessage: t`form.estimation-tool.fields.error-message`,
                    required: true,
                    options:
                        agenciesFromState.length >= 0
                            ? fetchAgencyData(agenciesFromState)
                            : [],
                },
            ],
        };
    };

    const submitForm = (values) => {
        updateFormCollection(values, true);
        nextStep && nextStep();
    };

    const handleDropdownChange = (e: any, name, setFieldValue) => {
        setFieldValue(name, e.value);
    };

    const fields = getFields();

    return (
        <div className={style.stepContainer}>
            <Typography
                type="section-title"
                className={style.estimationToolTitle}
            >{t`form.estimation-tool.title`}</Typography>
            <Formik
                initialValues={getInitialValues(fields, { GDPR: false })}
                validationSchema={getValidationSchema(fields, {
                    GDPR: yup.boolean().oneOf([true]),
                })}
                onSubmit={(values) => submitForm(values)}
            >
                {({
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                }) => (
                    <ReactstrapForm
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                    >
                        <FormGroup>
                            {fields.inputFields.map((field, index) => {
                                return (
                                    <div
                                        className={style.inputField}
                                        key={index}
                                    >
                                        <FormInput
                                            key={index}
                                            type={field.type}
                                            name={field.id}
                                            placeholder={field.placeholder}
                                            label={field.label}
                                            invalid={
                                                errors[field.id] &&
                                                touched[field.id]
                                            }
                                            tag={Field}
                                            errorMessage={errors[field.id]}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                );
                            })}
                        </FormGroup>
                        <FormGroup>
                            {fields.dropdowns.map((dropdown, key) => {
                                let value = presetAgencyId;
                                if (values[dropdown.name]) {
                                    value = values[dropdown.name];
                                }
                                return renderDropdown({
                                    dropdown,
                                    handleDropdownChange: (e) =>
                                        handleDropdownChange(
                                            e,
                                            dropdown.name,
                                            setFieldValue,
                                        ),
                                    setFieldValue,
                                    key,
                                    handleBlur,
                                    presetAgencyId,
                                    value: dropdown.options.filter(
                                        (item) => item.value === value,
                                    ),
                                });
                            })}
                            {errors?.agencyId &&
                                renderErrorMessage(errors?.agencyId)}
                        </FormGroup>
                        <FormGroup check>
                            <Label className={style.gdprContainer} check>
                                <Input
                                    type="checkbox"
                                    name="GDPR"
                                    tag={Field}
                                />
                                {checkBoxMessage}
                                <a href="#">{checkBoxLink}</a>. {warning}{" "}
                                <a href="https://policies.google.com/privacy">
                                    {policy}
                                </a>{" "}
                                {and}{" "}
                                <a href="https://policies.google.com/terms">
                                    {tos}
                                </a>{" "}
                                .
                            </Label>
                        </FormGroup>
                        <div className={style.buttonContainer}>
                            <div
                                className={style.prevButton}
                                onClick={() => previousStep()}
                            >
                                {t`form.estimation-tool.fields.previous`}
                            </div>
                            <Button color="primary" type="submit">
                                {t`form.estimation-tool.fields.results`}
                            </Button>
                        </div>
                    </ReactstrapForm>
                )}
            </Formik>
        </div>
    );
};

export default Last;
