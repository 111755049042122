//@flow

import { AgencyFilter } from "@components";
import React from "react";

import { type FilterConfig } from "../AgencyFilter";
import { Button } from "reactstrap";
import { navigate } from "gatsby-link";
import { t } from "ttag";

import style from "./style.module.scss";

type Props = {} & FilterConfig;

const AgencyFilterWrapper = ({ language, ...filterConfig }: Props) => (
    <div className={style.wrapper}>
        <AgencyFilter language={language} {...filterConfig} />
        <Button
            onClick={() => navigate(`/${language}/${t`slug.open-agency`}`)}
            color={"minimalist"}
        >
            {t`form.hq-contact.subject.open-office`}
        </Button>
    </div>
);

export default AgencyFilterWrapper;
