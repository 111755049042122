// @flow

import React, { useState, useEffect } from "react";

import { applyMinMaxFacet } from "@utils/search/facets";
import { Filter } from "@2po-c21/components";
import { t, gettext } from "ttag";
import { numberToCurrency } from "@utils";
import { type PropertyFilterProps } from "../types";

const values = [
    50000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000,
    500000, 600000, 700000, 800000, 1000000,
];

const Price = ({
    onChange,
    filter,
    facets,
    className,
}: PropertyFilterProps) => {
    const dropdownValues = applyMinMaxFacet(values, facets?.price).map(
        (value) => ({
            value,
            lookupValue: `${value}`,
            displayValue: numberToCurrency(value),
        }),
    );
    const stringToNumberConverter = (value) => value && +value;
    const [selectedValues, setSelectedValues] = useState();

    const childrenValues = () => {
        if (selectedValues) {
            const getSelectedValue = (selectedValue) =>
                dropdownValues.filter(
                    (value) => value.value === selectedValue,
                )[0];
            const getLowerValue = getSelectedValue(selectedValues.lower);
            const getUpperValue = getSelectedValue(selectedValues.upper);
            const lowerValue = selectedValues.lower
                ? gettext("common.filters.price.lower") +
                  (getLowerValue?.displayValue
                      ? getLowerValue.displayValue
                      : selectedValues?.lower
                      ? numberToCurrency(selectedValues.lower)
                      : "")
                : "";

            const upperValue = selectedValues.upper
                ? gettext("common.filters.price.upper") +
                  (getUpperValue?.displayValue
                      ? getUpperValue.displayValue
                      : selectedValues?.upper
                      ? numberToCurrency(selectedValues.upper)
                      : "")
                : "";

            return `${lowerValue} ${upperValue}`;
        }
    };
    useEffect(() => {
        if (filter?.price) {
            setSelectedValues(() => filter.price);
        }
    }, [filter]);

    return (
        <Filter.Range
            label={t`common.filters.price.label`}
            value={filter?.price}
            onChange={(value) => {
                onChange && onChange({ price: value });
                setSelectedValues(value);
            }}
            applyBtnText={t`common.filters.apply`}
            childrenValues={childrenValues}
            className={className}
        >
            {(lower, applyLower, upper, applyUpper) => (
                <>
                    <Filter.InputDropdown
                        label={t`common.filters.price.lower`}
                        value={lower}
                        displayValue={numberToCurrency}
                        onChange={applyLower}
                        values={dropdownValues}
                        inputPlaceholder={t`common.filters.price.placeholder`}
                        toTypeConverter={stringToNumberConverter}
                    />
                    <Filter.InputDropdown
                        label={t`common.filters.price.upper`}
                        value={upper}
                        displayValue={numberToCurrency}
                        onChange={applyUpper}
                        values={dropdownValues}
                        inputPlaceholder={t`common.filters.price.placeholder`}
                        negativeMargin
                        toTypeConverter={stringToNumberConverter}
                    />
                </>
            )}
        </Filter.Range>
    );
};

export default Price;
