// @flow
import style from "./style.module.scss";
import React from "react";
import { SectionTitle, Collapse } from "@2po-c21/components";
import { Section } from "@containers";

import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";

type Props = {
    title: string,
    faq: {
        __typename: string,
        question: string,
        answer: {
            answer: string,
        },
    }[],
};

const render = ({ title, faq }: Props) => (
    <Section>
        <SectionTitle>{title}</SectionTitle>
        <div className={style.wrapper}>
            {faq.map((item, index) => (
                <Collapse key={index} title={item.question} collapsed={false}>
                    {item.answer.answer}
                </Collapse>
            ))}
        </div>
    </Section>
);

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.Container && content.faq;

export default createContentfulRenderer(condition, render);
