const customStyles = (className) => {
    return {
        dropdownIndicator: () => ({
            display: "none",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        container: (provided) => ({
            ...provided,
            width: "100%",
            height: 50,
        }),
        control: (provided) => ({
            ...provided,
            color: "black",
            height: 50,
            paddingLeft: 10,
            border: "none",
            background: className ? "transparent" : "#e6e7e8",
            borderRadius: "0",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            boxShadow: "none",
        }),
        valueContainer: () => ({
            width: "80%",
            display: "flex",
            overflowX: "scroll",
            color: "black",
            "::-webkit-scrollbar": {
                width: "100%",
                height: 5,
            },
            "::-webkit-scrollbar-thumb": {
                background: "#A09C9E",
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            minWidth: "inherit",
            margin: "2px 4px 2px 0px",
            borderRadius: 0,
            display: "flex",
            alignItems: "center",
            backgroundColor: "#343534",
            color: "black",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: "white",
            display: "inline-block",
            paddingTop: 0,
        }),

        multiValueRemove: (provided) => ({
            ...provided,
            color: "white",
            ":hover": {
                backgroundColor: "#343534",
                color: "white",
                cursor: "pointer",
            },
        }),
        input: (provided) => ({
            ...provided,
            width: "100px",
            display: "inline-block",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            right: "8px",
            background: "transparent",
        }),
        menu: (provided) => ({
            ...provided,
            top: 45,
            "z-index": 9999,
            color: "black",
        }),
    };
};

export default customStyles;
