// @flow

import { type Sort } from "../types";

export class Interactions<T> {
    filter: ?T;
    sort: Sort;
    page: number;

    constructor(filter: ?T, sort: Sort, page: number) {
        this.filter = filter;
        this.sort = sort;
        this.page = page;
    }

    setFilter(filter: ?T): Interactions<T> {
        return new Interactions(filter, this.sort, 0);
    }

    setSort(sort: Sort): Interactions<T> {
        return new Interactions(this.filter, sort, 0);
    }

    setPage(page: number): Interactions<T> {
        return new Interactions(this.filter, this.sort, page);
    }
    getFilter(): ?T {
        return this.filter;
    }
}
