// @flow
import React from "react";
import { t } from "ttag";

import { Container } from "reactstrap";
import { Logo, Navigation, LanguageSwitcher } from "@2po-c21/components";
import style from "./style.module.scss";
import Link from "../Link";
import Tracker from "../Tracker/Tracker";

import { EVENT_MAP, type NavigationClickEvent } from "../../models/events";

export type NavigationItem = {
    title: string,
    navigationItems?: Array<NavigationItem>,
    id: string,
    parentTitle: string,
    mobileSubmenu: boolean,
    link?: *,
};

type Props = {
    navigationItems: Array<NavigationItem>,
    locale: string,
    translationSlugs: { [key: string]: string },
    isAgencyPage?: any,
    agencyId?: string,
    estimationTool?: boolean,
};

const Header = ({
    navigationItems,
    locale,
    translationSlugs,
    isAgencyPage,
    agencyId,
    estimationTool,
}: Props) => {
    const getInternalOrExternal = (item) => {
        const link = item?.link;

        return (
            link &&
            (link.contentfulInternalLink && link.contentfulInternalLink.slug
                ? link.contentfulInternalLink.slug
                : link.query
                ? link.query
                : link.externalLink && link.externalLink)
        );
    };
    const navigationTree = navigationItems.map((item) => ({
        title: item.title,
        id: item.id,
        slug: getInternalOrExternal(item),
        navigationItems: item.navigationItems?.map((subItem) => ({
            title: subItem.title,
            id: subItem.id,
            slug: getInternalOrExternal(subItem),
            parentTitle: item.title,
            mobileSubmenu: subItem.mobileSubmenu,
            navigationItems: subItem.navigationItems?.map((subSubItem) => ({
                title: subSubItem.title,
                slug: getInternalOrExternal(subSubItem),
                parentTitle: subItem.title,
            })),
        })),
        mobileSubmenu: item.mobileSubmenu,
    }));

    const LinkComponent = ({ children, slug }: *) => {
        if (slug && agencyId !== "" && slug.includes("agency=[agencyID]")) {
            slug = slug.replace("[agencyID]", agencyId);
        }

        const pageurl =
            typeof window !== "undefined" ? window.location.href : "";
        const trackerEvent: NavigationClickEvent = {
            type: EVENT_MAP.NavigationClickEvent,
            linkname: "",
            name: "main",
            pageurl: pageurl,
            targeturl: slug,
        };
        return slug ? (
            <Tracker trackerEvent={trackerEvent}>
                <Link className="gatsbyLink" to={slug}>
                    {children}
                </Link>
            </Tracker>
        ) : (
            <>{children}</>
        );
    };
    return (
        <>
            <div className={style.header}>
                <div className={style.navigationItemsHolder}>
                    <Container className={style.container}>
                        {isAgencyPage ? (
                            <Navigation
                                navigationItems={navigationTree}
                                LinkComponent={LinkComponent}
                            >
                                <LanguageSwitcher
                                    locale={locale}
                                    title={t`common.text.language`}
                                    options={translationSlugs}
                                    LinkComponent={LinkComponent}
                                />
                            </Navigation>
                        ) : (
                            <>
                                <Link to={`/${locale}`}>
                                    <div className={style.logo}>
                                        <Logo
                                            logo={"benelux"}
                                            width="240"
                                            height="20"
                                        />
                                    </div>
                                </Link>
                                <Navigation
                                    navigationItems={navigationTree}
                                    LinkComponent={LinkComponent}
                                    estimationTool={!!estimationTool}
                                    estimationLink={`/${locale}/${t`slug.estimation-tool`}`}
                                    estimationLabel={t`agency.header.button.text`}
                                >
                                    <LanguageSwitcher
                                        locale={locale}
                                        title={t`common.text.language`}
                                        options={translationSlugs}
                                        LinkComponent={LinkComponent}
                                    />
                                </Navigation>
                            </>
                        )}
                    </Container>
                    <Container className={style.relativeContainer}>
                        <div className={style.languageSwitcher}>
                            <LanguageSwitcher
                                locale={locale}
                                options={translationSlugs}
                                LinkComponent={LinkComponent}
                            />
                        </div>
                        <div className={style.bigLogo}>
                            <Logo logo={"seal"} width="124" />
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Header;
