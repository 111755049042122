// @flow

import esb from "elastic-builder";
import { endpoints } from "../../c21-api";
import { Interactions } from "../interactions";
import {
    type Search,
    type AgencyPropertiesFilter,
    type Strategy,
} from "../types";
import {
    SearchBase,
    buildSingleValueFilterQuery,
    buildRangeFilterQuery,
    SEARCH_DEFAULTS,
} from "../shared";

class AgencyPropertiesSearchStrategy
    implements Strategy<AgencyPropertiesFilter>
{
    endpoint = endpoints.properties;

    buildElasticQuery = (filter: AgencyPropertiesFilter): * => {
        const { agency } = filter;

        const matchQueries = [
            buildSingleValueFilterQuery("agencyId.keyword", agency),
            buildRangeFilterQuery("creationDate", {
                upper: window.__BUILD_TIMESTAMP__,
            }),
        ].filter((query) => !!query);

        const query = esb
            .boolQuery()
            .filter([esb.boolQuery().must(matchQueries)]);

        return query;
    };

    getFacets = () => undefined;

    buildBrowserQueryString = () => ({});
}

const AgencyPropertiesSearchFactory = {
    fromAgencyAndPageSize: (
        agency: *,
        pageSize: number,
    ): Search<AgencyPropertiesFilter> => {
        const interactions = new Interactions<AgencyPropertiesFilter>(
            {
                agency: agency.api_id,
            },
            SEARCH_DEFAULTS.sorting,
            SEARCH_DEFAULTS.page,
        );

        return new SearchBase(
            new AgencyPropertiesSearchStrategy(),
            interactions,
            pageSize,
        );
    },
};

export default AgencyPropertiesSearchFactory;
