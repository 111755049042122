// @flow

import style from "./style.module.scss";

import React, { useState } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import { t } from "ttag";
import { Icon, Down } from "@2po-c21/components";

type Props = {
    links: {
        displayValue: string,
        value: string,
    }[],
};

const CategoryLinks = ({ links }: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const setActive = (value) => {
        const linkId = value.split("/").slice(3, 4).join("");
        const url = typeof window !== "undefined" ? window.location.href : "";
        const activeId = url.split("/").slice(5, 6).join("");

        return linkId === activeId ? "active" : "";
    };

    return (
        <>
            <div className={style.categoryLinksWrapper}>
                <div
                    className={style.categoryLinksTitleMobile}
                    onClick={() => setDropdownOpen((prevState) => !prevState)}
                >
                    {t`blog.filter.title`}
                    <Icon icon={Down} />
                </div>
                <div
                    className={
                        dropdownOpen
                            ? style.categoryLinksDropdownMobile
                            : style.categoryLinksDropdown
                    }
                >
                    {links.map(({ displayValue, value }, index) => {
                        return (
                            <Link
                                key={index}
                                to={value}
                                className={classnames(
                                    style.categoryLink,
                                    style[setActive(value)],
                                )}
                            >
                                {displayValue}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default CategoryLinks;
