// @flow

import axios from "axios";
import { type Value } from "@2po-c21/components";
import { type Coordinates } from "@utils/search";

import { LOCALITY_POSTALCODES } from "./helpers";
import settings from "../settings";

const urlEndpoint = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
const mapboxToken = process.env.GATSBY_MAPBOX_TOKEN;

export const getDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
};

export const getMapBoxUrl = (
    dataType: string,
    types: string,
    limit: string,
    autocomplete: boolean = false,
    latitude?: string,
    longitude?: string,
    language?: string,
) => {
    if (mapboxToken) {
        return `${urlEndpoint}${dataType}.json?access_token=${mapboxToken}${getProximity(
            longitude,
            latitude,
        )}${types ? "&types=" + types : ""}&autocomplete=${
            autocomplete ? "true" : "false"
        }&limit=${limit ? limit : "3"}&fuzzyMatch=false&country=${
            settings.LISTED_COUNTRIES
        }${language ? `&language=${language}` : ""}`;
    }

    throw new Error("Cannot get mapbox token from env");
};

const defaultCoordinates = "4.3547,50.8467";
const getProximity = (longitude, latitude) => {
    if (longitude && latitude) {
        return `&proximity=${longitude},${latitude}`;
    }

    return `&proximity=${defaultCoordinates}`;
};

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
};

export const findCitiesForAutocomplete = async (
    searchTerm: string,
    language?: string,
): Promise<Array<Value<string>>> => {
    if (!searchTerm) return [];

    const {
        data: { features },
    } = await axios.get(
        getMapBoxUrl(
            searchTerm,
            "postcode%2Clocality%2Cregion%2Cdistrict%2Cplace",
            "10",
            true,
            "",
            "",
            language,
        ),
    );

    return features.map((location) => {
        const countryText = location.context.find((details) =>
            details.id.startsWith("country."),
        );

        const regionText = location.context.find((details) =>
            details.id.startsWith("region."),
        );

        const placeText = location.context.find((details) =>
            details.id.startsWith("place."),
        );

        const localityText =
            LOCALITY_POSTALCODES.includes(searchTerm) &&
            countryText.short_code === "be"
                ? undefined
                : location.context.find((details) =>
                      details.id.startsWith("locality."),
                  );

        const localityValue = localityText ? localityText.text + ", " : "";
        const placeValue = placeText ? placeText.text + ", " : "";
        const regionValue = regionText ? regionText.text + ", " : "";
        const countryValue = countryText ? countryText.text : "";

        const labelText = `${localityValue}${placeValue}${regionValue}${countryValue}`;

        return {
            value: location.center,
            label:
                location.place_type[0] === "postcode"
                    ? labelText
                    : location.place_name,
            text:
                location.place_type[0] === "postcode"
                    ? labelText
                    : location.text,
            coordinates: {
                latitude: location.center[0],
                longitude: location.center[1],
            },
        };
    });
};

export const findPostalCodeForCoordinates = async (
    coordinates: Array<number>,
): Promise<?string> => {
    if (!coordinates) return undefined;
    const {
        data: { features },
    } = await axios.get(getMapBoxUrl(coordinates.join(), "postcode", "1"));
    return features[0].text;
};

export const findPlaceForCoordinates = async (
    coordinates: ?Coordinates,
): Promise<*> => {
    if (!coordinates) return undefined;

    if (coordinates.latitude && coordinates.longitude) {
        const { latitude, longitude } = coordinates;
        const {
            data: { features },
        } = await axios.get(
            getMapBoxUrl(`${latitude},${longitude}`, "place%2Clocality", "1"),
        );

        return features;
    }
};
export const findCoordinatesForPostalCode = async (
    postalCode: string,
): Promise<{ latitude: number, longitude: number }> => {
    const {
        data: { features },
    } = await axios.get(getMapBoxUrl(postalCode, "postcode", "1"));
    const coordinates = features[0].geometry.coordinates;

    return {
        longitude: +coordinates[0],
        latitude: +coordinates[1],
    };
};

export const findCoordinatesForPlace = async (
    place: string,
): Promise<{ latitude: number, longitude: number }> => {
    const {
        data: { features },
    } = await axios.get(getMapBoxUrl(place, "place%2Clocality", "1"));
    const coordinates = features[0].geometry.coordinates;

    return {
        latitude: +coordinates[0],
        longitude: +coordinates[1],
    };
};
