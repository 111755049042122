// @flow

import React, { type Node } from "react";

import { Link as GatsbyLink } from "gatsby";

type Props = {
    to: string,
    className?: string,
    children: Node | Node[],
    fileUri?: string,
};

const internalAnchorLinks = ["#agency-information", "#agency-contact-form"];

// If urls contains century21, don't open in new tab
// If it's a file, open in new tab
// Otherwise, open in new tab
const target = (to: string, fileUri: string) => {
    if (fileUri) {
        return "_blank";
    }

    return /^https?:\/\/(www\.)?(century21\.[a-z]{1,3})/.test(to)
        ? "_self"
        : "_blank";
};

const Link = ({ children, to, fileUri, className }: Props) => {
    const internal = /^\/(?!\/)/.test(to) || internalAnchorLinks.includes(to);

    return internal ? (
        <GatsbyLink to={to} className={className}>
            {children}
        </GatsbyLink>
    ) : (
        <a
            href={fileUri ? `https:${fileUri}` : to}
            target={target(to, fileUri)}
            rel="noreferrer"
            className={className}
        >
            {children}
        </a>
    );
};

export default Link;
