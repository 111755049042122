/* eslint-disable smells/no-switch */
//@flow
import React from "react";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import type {
    BannerClickEvent,
    NavigationClickEvent,
    MapViewEvent,
    SortEvent,
    PageImpression,
    PageImpressionParams,
    BannerImpression,
    BannerImpressionParams,
} from "../../models/events";
import { EVENT_MAP } from "../../models/events";
import { type ToolStepEvent } from "../../models/events";

type Props = {
    children: any,
    trackerEvent:
        | NavigationClickEvent
        | MapViewEvent
        | SortEvent
        | BannerClickEvent,
};

/** custom event chagners */
const modifyEventFnc = (eventToDispatch: any) => {
    const ev: any = { ...eventToDispatch };

    if (eventToDispatch.type === EVENT_MAP.NavigationClickEvent) {
        const slug = ev.targeturl;
        const params: string = slug
            ? slug.indexOf("?") > 0
                ? slug.substr(slug.indexOf("?"), slug.length)
                : slug
            : "";
        let searchParams = new URLSearchParams(params);
        let linkname = searchParams.get("type") || "";
        for (const [key, value] of searchParams.entries()) {
            if (key != "type") {
                if (value === "true" || value === "false") {
                    linkname = linkname.concat(`:${key}`);
                } else {
                    linkname = linkname.concat(`:${value}`);
                }
            }
        }
        ev.linkname = linkname.toLowerCase();
    }
    if (eventToDispatch.type === EVENT_MAP.SortEvent) {
        if (ev.field === "price.amount" && ev.order === "asc") {
            ev.order = "increasing price";
        } else if (ev.field === "price.amount" && ev.order === "desc") {
            ev.order = "decreasing price";
        } else {
            ev.order = "most recent";
        }
    }

    if (eventToDispatch.type === EVENT_MAP.BannerClickEvent) {
        ev.targeturl = `${window.location.origin}${ev.targeturl}`;
    }

    return ev;
};

export const PushEvent = (trackerEvent: *, GTMDispatcher: function) => {
    let eventToDispatch: any = { ...trackerEvent };
    eventToDispatch = modifyEventFnc(eventToDispatch);
    const { type } = eventToDispatch;
    delete eventToDispatch.type;
    GTMDispatcher({
        event: type ? type : "undefined",
        value: eventToDispatch,
    });
};

const Tracker = ({ children, trackerEvent }: Props) => {
    const eventHook = useGTMDispatch();
    let eventToDispatch: any = { ...trackerEvent };
    const handleClick = () => PushEvent(eventToDispatch, eventHook);

    return <div onClick={handleClick}>{children}</div>;
};
export default Tracker;

export const SendToolEvent = (
    gtmDispatcher: any,
    name: string,
    stepnumber: number,
    messagetype: string,
    step: number,
    stepname: string,
    isLastStep: boolean = false,
) => {
    const gtmEvent: ToolStepEvent = {
        name,
        stepnumber,
        stepname,
        element: "form",
        messagetype,
        type: !isLastStep
            ? EVENT_MAP.ToolStepEvent
            : EVENT_MAP.ToolLastStepEvent,
    };
    PushEvent(gtmEvent, gtmDispatcher);
};

export const TrackPageImpression = (
    gtmDispatcher: any,
    eventType: string,
    params: PageImpressionParams,
) => {
    var currentdate = new Date();
    var datetime = `${currentdate.getDate()}/${
        currentdate.getMonth() + 1
    }/${currentdate.getFullYear()} ${currentdate.getHours()}:${currentdate.getMinutes()}:${currentdate.getSeconds()}`;
    const { pagename, agencypage, language, agency, typetemplate } = params;
    const gtmEvent: PageImpression = {
        name: eventType,
        pagename,
        type: eventType,
        typetemplate,
        language,
        url: window.location.href,
        useragent: navigator.userAgent,
        domain: window.location.origin,
        path: window.location.pathname,
        title: document.title,
        referrer: document.referrer,
        timestamp: datetime,
        agency,
        agencypage,
    };
    PushEvent(gtmEvent, gtmDispatcher);
};

export const TrackBannerImpression = (
    gtmDispatcher: any,
    eventType: string,
    params: BannerImpressionParams,
) => {
    const { name, targeturl, targetname } = params;
    const gtmEvent: BannerImpression = {
        type: eventType,
        name: name || eventType,
        targeturl: `${window.location.origin}${targeturl}`,
        targetname: targetname,
    };
    PushEvent(gtmEvent, gtmDispatcher);
};
