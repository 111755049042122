// @flow

import React, { useEffect, useRef } from "react";
import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";
import style from "./style.module.scss";
import { Section } from "@containers";

type Props = {
    hasWidth: boolean,
    iframeLink: {
        iframeLink: string,
    },
};

const render = ({ hasWidth, iframeLink }: Props) => {
    const { iframeLink: urlLink } = iframeLink;
    const containerRef = useRef(null);

    useEffect(() => {
        if (
            containerRef.current &&
            containerRef.current.firstChild instanceof HTMLIFrameElement
        ) {
            const iframe: HTMLIFrameElement = containerRef.current.firstChild;
            const container = containerRef.current;
            const iframeHeight = iframe.getAttribute("height");
            if (iframeHeight) {
                iframe.style.height = `${iframeHeight}px`;
                container.style.height = `${iframeHeight}px`;
            }
        }
    }, [containerRef.current]);

    return (
        <Section fullBleed={hasWidth}>
            <div
                ref={containerRef}
                dangerouslySetInnerHTML={{ __html: urlLink }}
                className={style.IframeContainer}
            />
        </Section>
    );
};

const condition = (content) => content.__typename === CONTENTFUL_TYPES.Iframe;

export default createContentfulRenderer(condition, render);
