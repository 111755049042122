// @flow

import React from "react";
import Link from "gatsby-link";
import { t } from "ttag";

import { Breadcrumb } from "@2po-c21/components";

type BreadcrumbProps = {
    locale: string,
};

const createBreadcrumbItem = (text: string, to: ?string = null) => (
    <Breadcrumb.Item
        text={text}
        LinkComponent={({ children }: *) => <Link to={to}>{children}</Link>}
    />
);

const homeBreadcrumbItem = (locale: string) =>
    createBreadcrumbItem("Home", `/${locale}`);

export const PropertyOverviewBreadcrumb = ({ locale }: BreadcrumbProps) => (
    <Breadcrumb>
        {homeBreadcrumbItem(locale)}
        {createBreadcrumbItem(t`breadcrumbs.property-overview.title`)}
    </Breadcrumb>
);

type PropertyDetailsBreadcrumbProps = BreadcrumbProps & {
    propertyTitle: string,
};

export const PropertyDetailsBreadcrumb = ({
    locale,
    propertyTitle,
}: PropertyDetailsBreadcrumbProps) => (
    <Breadcrumb>
        {homeBreadcrumbItem(locale)}
        {createBreadcrumbItem(
            t`breadcrumbs.property-overview.title`,
            `/${locale}/${t`slug.property-overview`}`,
        )}
        {createBreadcrumbItem(
            propertyTitle || t`breadcrumbs.property-details.title`,
        )}
    </Breadcrumb>
);

export const AgencyOverviewBreadcrumb = ({ locale }: BreadcrumbProps) => (
    <Breadcrumb>
        {homeBreadcrumbItem(locale)}
        {createBreadcrumbItem(t`breadcrumbs.agency-overview.title`)}
    </Breadcrumb>
);

type AgencyDetailsBreadcrumbProps = BreadcrumbProps & {
    agencyName: string,
};

export const AgencyDetailsBreadcrumb = ({
    locale,
    agencyName,
}: AgencyDetailsBreadcrumbProps) => (
    <Breadcrumb>
        {homeBreadcrumbItem(locale)}
        {createBreadcrumbItem(
            t`breadcrumbs.agency-overview.title`,
            `/${locale}/${t`slug.agency-overview`}`,
        )}
        {createBreadcrumbItem(agencyName)}
    </Breadcrumb>
);

export const FavouritesBreadcrumb = ({ locale }: BreadcrumbProps) => (
    <Breadcrumb>
        {homeBreadcrumbItem(locale)}
        {createBreadcrumbItem(t`breadcrumbs.favourites.title`)}
    </Breadcrumb>
);

export const BlogOverviewBreadcrumb = ({ locale }: BreadcrumbProps) => (
    <Breadcrumb>
        {homeBreadcrumbItem(locale)}
        {createBreadcrumbItem(t`breadcrumbs.blog-overview.title`)}
    </Breadcrumb>
);

type BlogBreadcrumbProps = BreadcrumbProps & {
    blogTitle: string,
};

export const BlogBreadcrumb = ({ locale, blogTitle }: BlogBreadcrumbProps) => (
    <Breadcrumb>
        {homeBreadcrumbItem(locale)}
        {createBreadcrumbItem(blogTitle)}
    </Breadcrumb>
);

type LandingBreadcrumbProps = BreadcrumbProps & {
    pageTitle: string,
};

export const LandingBreadcrumb = ({
    locale,
    pageTitle,
}: LandingBreadcrumbProps) => (
    <Breadcrumb>
        {homeBreadcrumbItem(locale)}
        {createBreadcrumbItem(pageTitle)}
    </Breadcrumb>
);

export default Breadcrumb;
