// @flow

import React, { type Node } from "react";
import { Image } from "@2po-c21/components";
import { ReviewCollector } from "../TrustPilot";

import style from "./style.module.scss";

type Props = {
    image: {
        imageSrc: string | (() => Node),
        description: string,
    },
    accountNumber?: string,
    locale?: string,
};
const AgencyHeroImage = ({ image, accountNumber, locale }: Props) => (
    <>
        <div className={style.reviewCollectorContainer}>
            <ReviewCollector
                locale={locale}
                templateId={
                    process.env.GATSBY_PUBLIC_TRUSTPILOT_REVIEW_COLLECTOR_ID
                }
                businessUnitId={
                    process.env.GATSBY_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID
                }
                dataHeight={"52px"}
                dataWidth={"100%"}
                dataTrustPilotId={accountNumber}
                isAgency
            />
        </div>
        <div className={style.heroImageWrapper}>
            <Image
                imageSrc={image?.imageSrc}
                description={image?.description}
            />
        </div>
    </>
);
export default AgencyHeroImage;
