// @flow

import React, { useState } from "react";

import { t } from "ttag";
import { Filter } from "@2po-c21/components";
import { ReviewCollector } from "../../../TrustPilot";

import { navigate } from "gatsby";
import PropertySearch from "../../../../utils/search/property-overview";
import style from "../style.module.scss";

import { FILTERS } from "./Filters";

import {
    addFilterFactory,
    Type,
    Price,
    ListingType,
    Location,
    Country,
} from "@components/Filters";

const QuickSearchFilter = ({ locale, filters }: *) => {
    const [filter, setFilter] = useState(undefined);

    const onChange = (newFilter) => {
        if (newFilter === filter) return;

        setFilter(newFilter);
    };

    const onClick = () => {
        if (!filter) return;
        navigate(
            `/${locale}/${t`slug.property-overview`}${PropertySearch.fromInitialValues(
                {
                    filter,
                },
            ).toBrowserQueryString()}`,
        );
    };

    return (
        <Filter onChange={onChange}>
            {(applyFilter) => {
                const addFilter = addFilterFactory(
                    filter,
                    undefined,
                    applyFilter,
                );

                return (
                    <>
                        <div className={style.quickFilterWrapper}>
                            {filters.includes(FILTERS.LOCATION)
                                ? addFilter(
                                      <Location
                                          language={locale}
                                          className={style.quickFilter}
                                          additionalClassName={
                                              style.quickFilterLocation
                                          }
                                      />,
                                  )
                                : null}
                            <div className={style.quickFilterOptions}>
                                {filters.includes(FILTERS.LISTINGTYPE)
                                    ? addFilter(
                                          <ListingType
                                              className={style.quickFilter}
                                          />,
                                      )
                                    : null}
                                {filters.includes(FILTERS.TYPE)
                                    ? addFilter(
                                          <Type
                                              className={style.quickFilter}
                                          />,
                                      )
                                    : null}
                                {filters.includes(FILTERS.BUDGET)
                                    ? addFilter(
                                          <Price
                                              className={style.quickFilter}
                                          />,
                                      )
                                    : null}
                                {filters.includes(FILTERS.COUNTRY)
                                    ? addFilter(
                                          <Country
                                              className={style.quickFilter}
                                          />,
                                      )
                                    : null}
                            </div>
                            <Filter.Button
                                label={t`common.text.search`}
                                onClick={onClick}
                            />
                        </div>
                        <div className={style.ReviewCollector}>
                            <ReviewCollector
                                locale={locale}
                                templateId={
                                    process.env
                                        .GATSBY_PUBLIC_TRUSTPILOT_REVIEW_COLLECTOR_ID
                                }
                                businessUnitId={
                                    process.env
                                        .GATSBY_PUBLIC_TRUSTPILOT_BUSINESS_UNIT_ID
                                }
                                dataHeight={"52px"}
                                dataWidth={"100%"}
                            />
                        </div>
                    </>
                );
            }}
        </Filter>
    );
};

export default QuickSearchFilter;
