// @flow

import style from "./style.module.scss";

import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { t } from "ttag";
import { Link, navigate } from "gatsby";
import {
    SimilarPropertiesCarousel,
    SimilarPropertiesList,
    SectionTitle,
} from "@2po-c21/components";
import { mapPropertiesForSearch } from "@utils/c21-api/mappers/property";
import { type Search } from "@utils/search";
import SimilarPropertiesSearchFactory from "@utils/search/similar-properties";
import AgencyPropertiesSearchFactory from "@utils/search/agency-properties";
import { PropertyCardMapper } from "../PropertyCard";
import { PropertyFallbackImage } from "@containers";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
    type FavoritesEvent,
    type ImagesSliderEvent,
    type SliderImageClickEvent,
    EVENT_MAP,
} from "../../models/events";
import { PushEvent } from "../Tracker/Tracker";

const NUMBER_OF_ITEMS_PER_PAGE = 3;

const getOtherPropertiesInProject = (property: *) =>
    property.project?.properties.filter(({ id }) => id !== property.id) || [];

type ProjectPropertiesDisplayProps = {
    property: { id: string, project: { properties: Array<*>, slug: string } },
};

const ProjectDetailsProjectPropertiesDisplay = ({
    property,
}: ProjectPropertiesDisplayProps) => {
    const otherPropertiesInProject = getOtherPropertiesInProject(property);

    if (!otherPropertiesInProject.length) return null;

    const pageCount = Math.ceil(
        otherPropertiesInProject.length / NUMBER_OF_ITEMS_PER_PAGE,
    );

    return (
        <PropertiesDisplay
            title={t`project-details.project-properties.title`}
            properties={otherPropertiesInProject}
            pageCount={pageCount}
        />
    );
};
const PropertyDetailsProjectPropertiesDisplay = ({
    property,
}: ProjectPropertiesDisplayProps) => {
    const otherPropertiesInProject = getOtherPropertiesInProject(property);

    if (!otherPropertiesInProject.length) return null;

    const pageCount = Math.ceil(
        otherPropertiesInProject.length / NUMBER_OF_ITEMS_PER_PAGE,
    );

    const projectDetailsLink = property.project.slug;

    const projectDetailsButtonText = t`property-details.project-properties.button`;

    const button = {
        clickHandler: () => navigate(projectDetailsLink),
        text: projectDetailsButtonText,
    };

    return (
        <div>
            <PropertiesDisplay
                title={t`property-details.project-properties.title`}
                properties={otherPropertiesInProject}
                pageCount={pageCount}
                buttonOverride={button}
            />
            <div className={style.callToAction}>
                <Link to={projectDetailsLink}>
                    <Button color="primary">{projectDetailsButtonText}</Button>
                </Link>
            </div>
        </div>
    );
};

type SimilarPropertiesDisplayProps = {
    property: *,
};

const SimilarPropertiesDisplay = ({
    property,
}: SimilarPropertiesDisplayProps) => {
    const search = SimilarPropertiesSearchFactory.fromPropertyAndPageSize(
        property,
        NUMBER_OF_ITEMS_PER_PAGE,
    );

    return (
        <SearchPropertiesDisplay
            title={t`property-details.similar-properties.title`}
            search={search}
            locale={property.locale}
        />
    );
};

type AgencyPropertiesDisplayProps = {
    agency: *,
};

const AgencyPropertiesDisplay = ({ agency }: AgencyPropertiesDisplayProps) => {
    const search = AgencyPropertiesSearchFactory.fromAgencyAndPageSize(
        agency,
        NUMBER_OF_ITEMS_PER_PAGE,
    );

    const propertyOverviewLink = `/${
        agency.locale
    }/${t`slug.property-overview`}?agency=${agency.api_id}`;

    const propertyOverviewButtonText = t`agency.agency-properties.button`;

    const button = {
        clickHandler: () => navigate(propertyOverviewLink),
        text: propertyOverviewButtonText,
    };

    return (
        <div>
            <SearchPropertiesDisplay
                title={t`agency.agency-properties.title`}
                search={search}
                locale={agency.locale}
                buttonOverride={button}
            />
            <div className={style.callToAction}>
                <Link to={propertyOverviewLink}>
                    <Button color="primary">
                        {propertyOverviewButtonText}
                    </Button>
                </Link>
            </div>
        </div>
    );
};

type SearchPropertiesDisplayProps<T> = {
    search: Search<T>,
    locale: string,
    title: string,
    buttonOverride?: PropertiesDisplayMoreButtonOverride,
};

const SearchPropertiesDisplay = <T>({
    search,
    locale,
    title,
    buttonOverride,
}: SearchPropertiesDisplayProps<T>) => {
    const [properties, setProperties] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    const fetch = () => {
        search
            .search()
            .then((result) => {
                setPageCount(result.data._meta.pageCount);
                return mapPropertiesForSearch(result.data.data, locale);
            })
            .then((properties) => {
                setProperties((previous) => [...previous, ...properties]);
            });
    };

    useEffect(() => {
        fetch();
    }, []);

    const onFetchNext = () => {
        search.setPage(search.current.page + 1);
        fetch();
    };

    return !properties.length ? null : (
        <PropertiesDisplay
            title={title}
            properties={properties}
            pageCount={pageCount}
            onFetchNext={onFetchNext}
            buttonOverride={buttonOverride}
        />
    );
};

type PropertiesDisplayMoreButtonOverride = {
    clickHandler: () => void,
    text: string,
};

type PropertiesDisplayProps = {
    title: string,
    properties: Array<*>,
    pageCount: number,
    onFetchNext?: () => void,
    buttonOverride?: PropertiesDisplayMoreButtonOverride,
};

const PropertiesDisplay = ({
    title,
    properties: _properties,
    pageCount,
    onFetchNext,
    buttonOverride,
}: PropertiesDisplayProps) => {
    const [page, setPage] = useState(1);
    const [properties, setProperties] = useState([]);
    const [carouselProperties, setCarouselProperties] = useState([]);
    const [listProperties, setListProperties] = useState([]);
    const gtmDispatcher = useGTMDispatch();
    useEffect(() => {
        if (!_properties || !_properties.length) return;

        setProperties(PropertyCardMapper.mapAll(_properties));
    }, [_properties]);

    const changePage = (page: number) => {
        setPage(page);

        // do not ask for next results if we have enough properties to display
        if (properties.length >= page * NUMBER_OF_ITEMS_PER_PAGE) return;

        onFetchNext && onFetchNext();
    };

    useEffect(() => {
        const start = (page - 1) * NUMBER_OF_ITEMS_PER_PAGE;
        const newCarouselProperties = properties.slice(
            start,
            start + NUMBER_OF_ITEMS_PER_PAGE,
        );

        // ensure no flickering when loading new properties
        if (newCarouselProperties.length === 0) return;

        setCarouselProperties(newCarouselProperties);
    }, [properties, page]);

    useEffect(() => {
        setListProperties(properties.slice(0, page * NUMBER_OF_ITEMS_PER_PAGE));
    }, [properties, page]);

    // TODO [TKN-20200928] allow for show more button to be hidden in the similar properties list and don't re-use it, instead of the hack below
    pageCount = properties.length
        ? buttonOverride
            ? 2 // WHY? if show more button is overridden, it should always be visible unless no results => pagecount >= 2
            : pageCount
        : pageCount;

    // TODO [TKN-20200814] (edge case) if desktop mode is on page 2, and browser resizes to mobile, and user clicks next, the mobile view will still report page 2 (causing no extra properties to show)

    const pushFavorite = (isFavorite, id) => {
        const gtmEvent: FavoritesEvent = {
            favorite: isFavorite,
            propertyid: id,
            propertyview: "detail",
            listname: "similar houses",
            type: EVENT_MAP.FavoritesEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    const handleAddFavourite = (property: *) => {
        pushFavorite(true, property.id);
    };
    const handleRemoveFavourite = (property: *) => {
        pushFavorite(false, property.id);
    };

    const pushImagesSlider = (direction: string, projectid: string) => {
        const gtmEvent: ImagesSliderEvent = {
            direction,
            listname: "similar houses",
            propertyid: projectid,
            propertyview: "detail",
            type: EVENT_MAP.ImagesSliderEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    const handleOnNext = (property: any) => {
        pushImagesSlider("right", property.id);
    };
    const handleOnPrevious = (property: any) => {
        pushImagesSlider("left", property.id);
    };

    const handleOnClick = (property: any, imageIndex: number) => {
        const image =
            property.images[imageIndex]?.imageSrc()?.props?.fixed?.src ||
            undefined;
        const amountImages = property.images.length;
        const gtmEvent: SliderImageClickEvent = {
            name: image,
            propertyid: property.id,
            propertyview: "card",
            listname: "similar houses",
            position: imageIndex + 1,
            listtotal: amountImages,
            type: EVENT_MAP.SliderImageClickEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    const carousselCallbacks = {
        handleAddFavourite,
        handleRemoveFavourite,
        handleOnNext,
        handleOnPrevious,
        handleOnClick,
    };

    return (
        <PropertyFallbackImage>
            {(fallbackImage) => (
                <>
                    <SectionTitle>{title}</SectionTitle>
                    <div className={style.carousel}>
                        <SimilarPropertiesCarousel
                            totalPageCount={pageCount}
                            onNextClick={changePage}
                            onPreviousClick={changePage}
                            properties={carouselProperties}
                            fallbackImage={fallbackImage}
                            callbacks={carousselCallbacks}
                        />
                    </div>
                    <div className={style.list}>
                        <SimilarPropertiesList
                            totalPageCount={pageCount}
                            showMoreTxt={
                                (buttonOverride && buttonOverride.text) ||
                                t`common.text.show-more`
                            }
                            onNextClick={(page) => {
                                if (buttonOverride) {
                                    buttonOverride.clickHandler();
                                    return;
                                }
                                changePage(page);
                            }}
                            properties={listProperties}
                            fallbackImage={fallbackImage}
                            callbacks={carousselCallbacks}
                        />
                    </div>
                </>
            )}
        </PropertyFallbackImage>
    );
};

export {
    AgencyPropertiesDisplay,
    ProjectDetailsProjectPropertiesDisplay,
    PropertyDetailsProjectPropertiesDisplay,
    SimilarPropertiesDisplay,
};
