// @flow
import React from "react";

import { Icon, Plus } from "@2po-c21/components";
import style from "./style.module.scss";
import { gettext, t } from "ttag";
import { toKebabCase, numberToCurrency } from "@utils";

type Props = {
    activeFilters: *,
    removeFilter: *,
    onFilterCleared: *,
    countryFilterWhitelist?: string[],
};

type buttonProps = {
    objectKey: string,
    value: *,
    type: string,
    displayValue: any,
    onClick: (activeKey: *, activeValue: *, type: string) => void,
};

type resetProps = {
    activeFilters: *,
    onClick: () => void,
};

const AppliedFilterButton = ({
    objectKey,
    value,
    type,
    displayValue,
    onClick,
}: buttonProps) => {
    return (
        <div
            className={style.appliedFiltersButton}
            onClick={() => onClick(objectKey, value, type)}
        >
            {displayValue}
            <Icon
                className={style.closeIcon}
                icon={Plus}
                size="lg"
                variant={"black"}
            />
        </div>
    );
};

const ResetAllFilters = ({ activeFilters, onClick }: resetProps) => {
    for (let i = 0; i < Object.values(activeFilters).length; i++) {
        if (typeof Object.values(activeFilters)[i] !== "undefined") {
            return (
                <div
                    className={style.resetFiltersButton}
                    onClick={() => onClick()}
                >{t`common.filters.clear`}</div>
            );
        }
    }
    return null;
};

const AppliedFiltersBlock = ({
    activeFilters,
    removeFilter,
    onFilterCleared,
    countryFilterWhitelist,
}: Props) => {
    return (
        <div className={style.appliedFiltersBlock}>
            {Object.keys(activeFilters).map((objectKey, k) => {
                if (activeFilters[objectKey]) {
                    if (Array.isArray(activeFilters[objectKey])) {
                        return activeFilters[objectKey].map((arrayValue, k) => {
                            if (
                                !["location", "countryCode"].includes(objectKey)
                            ) {
                                return (
                                    <AppliedFilterButton
                                        key={k}
                                        objectKey={objectKey}
                                        value={arrayValue}
                                        type={"array"}
                                        displayValue={gettext(
                                            `api.property.${toKebabCase(
                                                objectKey,
                                            )}.${arrayValue}`,
                                        )}
                                        onClick={removeFilter}
                                    />
                                );
                            } else if (
                                objectKey === "countryCode" &&
                                !(
                                    countryFilterWhitelist?.length === 1 &&
                                    countryFilterWhitelist[0] === arrayValue
                                )
                            ) {
                                return (
                                    <AppliedFilterButton
                                        key={k}
                                        objectKey={objectKey}
                                        value={arrayValue}
                                        type={"array"}
                                        displayValue={gettext(
                                            `api.property.${toKebabCase(
                                                objectKey,
                                            )}.${arrayValue}`,
                                        )}
                                        onClick={removeFilter}
                                    />
                                );
                            }
                        });
                    }

                    if (
                        Object?.getPrototypeOf(activeFilters[objectKey]) ===
                        Object?.prototype
                    ) {
                        return Object.keys(activeFilters[objectKey]).map(
                            (innerObjectKey, k) => {
                                if (
                                    activeFilters[objectKey][innerObjectKey] !==
                                        "" &&
                                    typeof activeFilters[objectKey][
                                        innerObjectKey
                                    ] !== "undefined"
                                ) {
                                    return (
                                        <AppliedFilterButton
                                            key={k}
                                            objectKey={objectKey}
                                            value={
                                                activeFilters[objectKey][
                                                    innerObjectKey
                                                ]
                                            }
                                            type={"object"}
                                            displayValue={
                                                gettext(
                                                    `common.filters.${objectKey}.${innerObjectKey}`,
                                                ) +
                                                `: ${
                                                    objectKey === "price"
                                                        ? numberToCurrency(
                                                              activeFilters[
                                                                  objectKey
                                                              ][innerObjectKey],
                                                          )
                                                        : objectKey ===
                                                          "bedrooms"
                                                        ? `${activeFilters[objectKey][innerObjectKey]}`
                                                        : `${activeFilters[objectKey][innerObjectKey]}m\u00b2`
                                                }`
                                            }
                                            onClick={removeFilter}
                                        />
                                    );
                                }
                            },
                        );
                    }

                    return (
                        <AppliedFilterButton
                            key={k}
                            objectKey={objectKey}
                            value={activeFilters[objectKey]}
                            type={"string"}
                            displayValue={
                                objectKey === "agency"
                                    ? `${gettext("api.property.agency")}`
                                    : objectKey === "floorNumber"
                                    ? `${gettext(
                                          "common.filters.floor-number.label",
                                      )}: ${activeFilters[objectKey]}`
                                    : gettext(
                                          `api.property.${toKebabCase(
                                              objectKey,
                                          )}.${activeFilters[objectKey]}`,
                                      )
                            }
                            onClick={removeFilter}
                        />
                    );
                }
            })}
            {activeFilters &&
                !(
                    countryFilterWhitelist?.length === 1 &&
                    Object.keys(activeFilters).length === 1 &&
                    activeFilters.countryCode
                ) && (
                    <ResetAllFilters
                        onClick={onFilterCleared}
                        activeFilters={activeFilters}
                    />
                )}
        </div>
    );
};

export default AppliedFiltersBlock;
