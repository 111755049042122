// @flow

import axios from "axios";
import esb from "elastic-builder";
import { Base64 } from "js-base64";
import queryString from "query-string";

import { type Range, type Search, type Sort, type Strategy } from "../types";
import { Interactions } from "../interactions";

export class SearchBase implements Search<*> {
    _strategy: Strategy<*>;
    _interactions: Interactions<*>;
    _pageSize: number;

    get current(): Interactions<*> {
        return this._interactions;
    }

    setFilter(filter: *) {
        this._interactions = this._interactions.setFilter(filter);
    }

    setSort(sort: Sort) {
        this._interactions = this._interactions.setSort(sort);
    }

    setPage(page: number) {
        this._interactions = this._interactions.setPage(page);
    }

    constructor(
        strategy: Strategy<*>,
        interactions: Interactions<*>,
        pageSize: ?number,
    ) {
        this._strategy = strategy;
        this._interactions = interactions;
        this._pageSize = pageSize || SEARCH_DEFAULTS.resultsPerPage;
    }

    search(): Promise<*> {
        const request = queryString.stringifyUrl({
            url: this._strategy.endpoint,
            query: { ...this._buildSearchQueryString() },
        });

        return axios.get(request);
    }

    _buildSearchQueryString(): * {
        return buildFromGenerator(() => this._buildSearchQueryStringParts());
    }

    _buildSearchQueryStringParts = function* (): Generator<*, *, *> {
        yield { filter: this._buildElasticFilter() };
        yield {
            pageSize: `${this._pageSize}`,
        };

        const { page, sort } = this._interactions;

        if (sort) yield { sort: this._buildSort(sort) };
        if (page && page - 1) yield { page: `${page - 1}` };

        const facets = this._strategy.getFacets();
        if (facets) yield { facets };
    };

    _buildElasticFilter(): string {
        const { filter } = this._interactions;

        if (!filter) return "";

        const query = this._strategy.buildElasticQuery(filter);

        const json = JSON.stringify(query);

        const base64 = Base64.encode(json);

        return base64;
    }

    _buildSort(sort: Sort): * {
        const direction = sort.direction === "desc" ? "-" : "";

        return `${direction}${sort.field}`;
    }

    toBrowserQueryString(): string {
        const qs = queryString.stringify(
            this._strategy.buildBrowserQueryString(this._interactions),
        );
        return qs && `?${qs}`;
    }
}

export const SEARCH_DEFAULTS = {
    get sorting() {
        return {
            field: "creationDate",
            direction: "desc",
        };
    },

    get resultsPerPage() {
        return RESULTS_PER_PAGE;
    },

    get page() {
        return 1;
    },
};

export const buildSingleValueFilterQuery = (fieldName: string, value: *) =>
    value && esb.matchQuery(fieldName, value);

export const buildMultiValueFilterQuery = (fieldName: string, values: ?(*[])) =>
    values?.length &&
    esb
        .boolQuery()
        .should(values.map((value) => esb.matchQuery(fieldName, value)));

export const buildRangeFilterQuery = (fieldName: string, values: ?Range) =>
    values &&
    (values.lower || values.upper) &&
    esb.rangeQuery(fieldName).gte(values.lower).lte(values.upper);

export const buildFromGenerator = (generator: function): { [string]: string } =>
    [...generator()].reduce((qs, part) => Object.assign({}, qs, part), {});

export const RESULTS_PER_PAGE = 24;
