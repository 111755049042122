// @flow

import React from "react";
import style from "./style.module.scss";

import { Link } from "gatsby";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";
import { Typography } from "@2po-c21/components";
import Render from "../.";
import { Container, Button } from "reactstrap";

const Bold = ({ children }: *) => (
    <span className={style.bold}>{children}</span>
);

const Heading = ({ children }: *) => (
    <div className={style.heading}>
        <Typography type="heading-4-bold">{children}</Typography>
    </div>
);

const richTextBuilder = (locale) => ({
    /* eslint-disable */
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_, children) => (
            <p className={style.paragraph}>{children}</p>
        ),
        [BLOCKS.UL_LIST]: (_, children) => (
            <ul className={style.list}>{children}</ul>
        ),
        [BLOCKS.OL_LIST]: (_, children) => (
            <ol className={style.list}>{children}</ol>
        ),
        [BLOCKS.HEADING_1]: (_, children) => <Heading>{children}</Heading>,
        [BLOCKS.HEADING_2]: (_, children) => <Heading>{children}</Heading>,
        [BLOCKS.HEADING_3]: (_, children) => (
            <div className={style.heading}>
                <Typography type="heading-5-bold">{children}</Typography>
            </div>
        ),
        [BLOCKS.HEADING_4]: (_, children) => (
            <Typography type="heading-6-bold">{children}</Typography>
        ),
        [INLINES.ENTRY_HYPERLINK]: (
            {
                data: {
                    target: { fields },
                },
            },
            children,
        ) => {
            return fields ? (
                fields?.fileLink ? (
                    <a
                        href={`https:${fields?.fileLink[locale]?.fields?.file[locale]?.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {children}
                    </a>
                ) : (
                    <Link to={`${fields.slug[locale]}`}>{children}</Link>
                )
            ) : (
                children
            );
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            if (
                node.nodeType == "embedded-entry-block" &&
                node.data?.target?.fields?.fileLink
            ) {
                return (
                    <a
                        href={`https:${node.data.target.fields?.fileLink[locale]?.fields?.file[locale]?.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button color="primary">
                            {node.data.target.fields?.linkTitle[locale]}
                        </Button>
                    </a>
                );
            }
            return Render(node.data.target, locale);
        },
        [INLINES.EMBEDDED_ENTRY]: (node) => {
            if (
                node.nodeType == "embedded-entry-inline" &&
                node.data?.target?.fields?.fileLink
            ) {
                return (
                    <a
                        href={`https:${node.data.target.fields?.fileLink[locale]?.fields?.file[locale]?.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {node.data.target.fields?.linkTitle[locale]}
                    </a>
                );
            }
            return Render(node.data.target, locale);
        },
    },
    /* eslint-enable */
});
const render = (content, key, locale) => {
    const options = richTextBuilder(locale);

    return (
        <Container className={style.container} key={key}>
            {(content?.childContentfulRichTextTextRichTextNode?.json &&
                documentToReactComponents(
                    content.childContentfulRichTextTextRichTextNode.json,
                    options,
                )) ||
                ""}
        </Container>
    );
};

const condition = (content) =>
    content?.internal?.type === CONTENTFUL_TYPES.RichText;

export default createContentfulRenderer(condition, render);
