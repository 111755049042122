// @flow

import React, { useState, useEffect } from "react";
import { t } from "ttag";
import {
    Typography,
    Map,
    Pin,
    Pins,
    School,
    SchoolPin,
    Shop,
    ShopPin,
    Transport,
    TransportPin,
    Icon,
} from "@2po-c21/components";

import style from "./style.module.scss";
import { Row, Col } from "reactstrap";
import axios from "axios";
import { getDistance, getMapBoxUrl } from "@utils";

type Props = {
    property: *,
};

const PropertyLocation = ({ property }: Props) => {
    const coordinates = property.location;
    const { latitude, longitude } = coordinates;

    const [pointsOfInterest, setPointsOfInterest] = useState();

    const getMostRelevantPoints = (baseLat, baseLong, collection) => {
        return collection.map((category) => {
            const title = category.title;
            const icon = category.icon;
            const pin = category.pin;
            const result = category.data.map((poi) => {
                return {
                    distance: getDistance(
                        baseLat,
                        baseLong,
                        poi.center[1],
                        poi.center[0],
                    ),
                    text: poi.text,
                    coordinates: {
                        latitude: poi.center[1],
                        longitude: poi.center[0],
                    },
                    pin: pin,
                };
            });

            const sortedResult = result.sort((a, b) => a.distance - b.distance);

            return {
                title: title,
                icon: icon,
                result: sortedResult.splice(0, 3),
            };
        });
    };

    const fetchPointsOfInterest = async () => {
        axios
            .all([
                axios.get(
                    getMapBoxUrl(
                        "school",
                        "poi",
                        "10",
                        false,
                        latitude,
                        longitude,
                    ),
                ),
                axios.get(
                    getMapBoxUrl(
                        "groceries",
                        "poi",
                        "10",
                        false,
                        latitude,
                        longitude,
                    ),
                ),
                axios.get(
                    getMapBoxUrl(
                        "bus station",
                        "poi",
                        "10",
                        false,
                        latitude,
                        longitude,
                    ),
                ),
            ])
            .then(
                axios.spread(
                    (
                        responseSchoolPoi,
                        responseStoresPoi,
                        responseTransportPoi,
                    ) => {
                        const filteredPointsOfInterest = getMostRelevantPoints(
                            latitude,
                            longitude,
                            [
                                {
                                    title: t`property-details.location-map.school`,
                                    icon: School,
                                    data: responseSchoolPoi.data.features,
                                    pin: SchoolPin,
                                },
                                {
                                    title: t`property-details.location-map.shop`,
                                    icon: Shop,
                                    data: responseStoresPoi.data.features,
                                    pin: ShopPin,
                                },
                                {
                                    title: t`property-details.location-map.transport`,
                                    icon: Transport,
                                    data: responseTransportPoi.data.features,
                                    pin: TransportPin,
                                },
                            ],
                        );
                        setPointsOfInterest(filteredPointsOfInterest);
                    },
                ),
            );
    };

    useEffect(() => {
        if (!coordinates) return;

        fetchPointsOfInterest();
    }, [coordinates]);

    const renderPins = (pointsOfInterest) => {
        const resultsArray = [];
        return resultsArray
            .concat(
                pointsOfInterest[0].result,
                pointsOfInterest[1].result,
                pointsOfInterest[2].result,
            )
            .map((pointOfInterest, key) => {
                return (
                    <Pin
                        key={key}
                        latitude={pointOfInterest.coordinates.latitude}
                        longitude={pointOfInterest.coordinates.longitude}
                        icon={pointOfInterest.pin}
                    />
                );
            });
    };

    return (
        <Row>
            <Col xl="12">
                <Typography
                    type="heading-4"
                    className={style.propertyLocationTitle}
                >{t`property-details.location-map.title`}</Typography>
            </Col>
            <Col xl="8">
                <div className={style.propertyLocationWrapper}>
                    {coordinates && pointsOfInterest && (
                        <div className={style.mapContainer}>
                            <Map>
                                <Pins>
                                    <Pin
                                        latitude={latitude}
                                        longitude={longitude}
                                        className={style.propertyLocationPin}
                                    />
                                    {renderPins(pointsOfInterest)}
                                </Pins>
                            </Map>
                        </div>
                    )}
                </div>
            </Col>
            <Col
                xl="4"
                className={style.propertyLocationPointsOfInterestWrapper}
            >
                <Row>
                    {pointsOfInterest &&
                        pointsOfInterest.map((categories, key) => (
                            <Col key={key} lg="6" xl="12">
                                <div
                                    key={key}
                                    className={
                                        style.propertyLocationCategoryWrapper
                                    }
                                >
                                    <Typography
                                        type="heading-5-bold"
                                        className={
                                            style.propertyLocationSubtitle
                                        }
                                    >
                                        <Icon
                                            icon={categories.icon}
                                            size="md"
                                            variant={"relentless-gold"}
                                        />
                                        {categories.title}
                                    </Typography>
                                    <div
                                        className={
                                            style.propertyLocationPointOfInterestWrapper
                                        }
                                    >
                                        {categories.result.map(
                                            (pointOfInterest, index) => {
                                                return (
                                                    <Typography
                                                        key={index}
                                                        type="small"
                                                        className={
                                                            style.propertyLocationPointOfInterest
                                                        }
                                                    >
                                                        {`${
                                                            pointOfInterest.text
                                                        }: ${pointOfInterest.distance.toFixed(
                                                            1,
                                                        )} km`}
                                                    </Typography>
                                                );
                                            },
                                        )}
                                    </div>
                                </div>
                            </Col>
                        ))}
                </Row>
            </Col>
        </Row>
    );
};

export default PropertyLocation;
