// @flow

import React from "react";

import { t, gettext } from "ttag";

import { toYesNoNull } from "@utils";
import { TextualInformation } from "@2po-c21/components";

type Props = {
    property: *,
};

const GeneralInformation = ({ property }: Props) => (
    <TextualInformation
        title={t`property-details.general-information.title`}
        rows={converters(property).map((convert) => convert(property))}
    />
);

export default GeneralInformation;

const converters = ({ type }) => {
    if (type === "LAND")
        return [convertAvailability, convertReference, convertFloodzone];

    if (type === "PARKING")
        return [
            convertAvailability,
            convertYearOfConstruction,
            convertNumberOfFacades,
            convertCondition,
        ];

    return [
        convertAvailability,
        convertYearOfConstruction,
        convertNumberOfFacades,
        convertFloorNumber,
        convertCondition,
        convertParking,
        convertReference,
    ];
};

const convertAvailability = ({ availability, availableFrom }) => ({
    label: t`property-details.general-information.availability`,
    value: `${gettext(`api.property.availability.${availability}`)} ${
        availableFrom || ""
    }`,
});

const convertReference = ({ reference: value }) => ({
    label: t`property-details.general-information.reference`,
    value,
});

const convertFloodzone = ({ isFloodZone }) => ({
    label: t`property-details.general-information.flood-zone`,
    value: toYesNoNull(isFloodZone),
});

const convertYearOfConstruction = ({ yearOfConstruction: value }) => ({
    label: t`property-details.general-information.construction-year`,
    value,
});

const convertNumberOfFacades = ({ numberOfFacades: value }) => ({
    label: t`property-details.general-information.number-of-walls`,
    value,
});

const convertCondition = ({ condition }) => ({
    label: t`property-details.general-information.property-state`,
    value: gettext(`api.property.condition.${condition}`),
});

const convertFloorNumber = ({ floorNumber: value }) => ({
    label: t`property-details.general-information.floor-number`,
    value,
});

const convertParking = ({ hasParking }) => ({
    label: t`property-details.general-information.parking`,
    value: toYesNoNull(hasParking),
});
