// @flow

import { t } from "ttag";

export const unitsConverter = {
    M2: (value: string) => `${value} m\u00b2`,
    KG_CO2_M2: (value: string) => `${value} kg CO2 / m\u00b2`,
    KW_M2_YEAR: (value: string) => `${value} kw / m\u00b2 / ${t`common.year`}`,
    KW_YEAR: (value: string) => `${value} kw / ${t`common.year`}`,
};

export const energySourcesDictionary = {
    GAS: () => t`common.gas`,
    ELECTRICITY: () => t`common.electricity`,
    HEAT_PUMP: () => t`common.heat-pump`,
    FUEL: () => t`common.fuel`,
};
