// @flow

import { createPropertyAssetPath } from "../../endpoints";
import { GATSBY_C21_IMAGES_BASEPATH } from "../../env";
import ImageMapper from "../image";

import slugify from "slugify";
import { numberToCurrency } from "../../../currency";
import {
    api_id,
    apply,
    localizeAddress,
    type Mapper,
    shallowCopy,
    setLocale,
} from "../shared";

import { gettext, useLocale, addLocale } from "ttag";

/** non-gatsby mappers */

const slug = ({ locale, api_id, type, listingType, address: { city } }: *) => {
    const slug = [
        "",
        locale,
        slugify(gettext("slug.property-detail")),
        slugify(gettext(`api.property.listing-type.${listingType}`)),
        slugify(gettext(`api.property.type.${type}`)),
        slugify(city),
    ]
        .join("/")
        .toLowerCase();

    return {
        slug: `${slug}/${api_id}`,
    };
};

const assets = ({ id, assets }: *) => ({
    assets: assets.map((asset: *) => ({
        ...asset,
        href: `${GATSBY_C21_IMAGES_BASEPATH}/${createPropertyAssetPath({
            id,
        })}/${asset.name}`,
    })),
});

const images = (property: *) => {
    const path = createPropertyAssetPath(property);

    return {
        images: property.images
            .map(({ name, description, lastModifiedDate }: *) => {
                try {
                    const [, filename, extension] = name.match(/(.+)(\..+)/);

                    return {
                        description,
                        path,
                        filename,
                        extension,
                        lastModifiedDate,
                    };
                } catch {
                    return null;
                }
            })
            .filter((image) => !!image)
            .map((image) => ({
                description: image.description,
                fixed: (size) => ImageMapper.toFixed(image, size),
                lastModifiedDate: image.lastModifiedDate,
            })),
    };
};

/** gatsby only */

const agent =
    ({ createC21ImageId }: *) =>
    (property: *) => {
        const agent = property._embedded.agent
            ? { ...property._embedded.agent }
            : null;

        if (!agent) return { agent: null };

        agent.images___NODE = agent.images.map((image) =>
            createC21ImageId(createPropertyAssetPath(property), image.name),
        );

        return { agent };
    };

const agency =
    ({ createC21AgencyId }: *) =>
    (property: *) => ({
        agency___NODE: createC21AgencyId({
            id: property._links.agency.id,
            locale: property.locale,
        }),
    });

const images___NODE =
    ({ createC21ImageId }: *) =>
    (property: *) => ({
        images___NODE: property.images.map((image) =>
            createC21ImageId(createPropertyAssetPath(property), image.name),
        ),
    });

/** cleanup */

const cleanupAfterSourceMapped = (property: *) => {
    delete property.images;

    delete property._embedded;
    if (property.agent) delete property.agent.images;

    return property;
};

const cleanupAfterSourceLocalized = (property: *) => {
    delete property._links;
};

const cleanupAfterSearchMapped = (property: *) => {
    delete property._embedded;
    delete property._links;

    if (property.agent) delete property.agent.images;

    return property;
};

/** localization */

const localizeTitle = ({ title }: *, locale: *) => ({
    title: title[locale],
});

const localizeDescription = ({ description }: *, locale: *) => ({
    description: description[locale],
});

const localizePrice = ({ price, status }: *) => {
    let localizedPrice;
    if (!["AVAILABLE", "OPTION"].includes(status)) {
        localizedPrice = gettext(`api.property.status.${status}`);
    } else if (price.hidden) {
        localizedPrice = gettext("common.price.on-demand");
    } else {
        localizedPrice = numberToCurrency(price.amount);
    }

    return {
        price: localizedPrice,
    };
};

const createSourcePluginMapper = (gatsbyApi: *): Mapper => ({
    map: (property: *) => {
        const steps: *[] = [
            api_id,
            shallowCopy,
            agent(gatsbyApi),
            agency(gatsbyApi),
            assets,
            images___NODE(gatsbyApi),
        ];

        const mapped = apply(steps, (step) => step(property));

        // delete everything that was mapped because of shallow copy that we do not want in the result anymore (e.g. images)
        cleanupAfterSourceMapped(mapped);

        return mapped;
    },
    localize: (property: *, locale: string) => {
        const steps: *[] = [
            shallowCopy,
            setLocale,
            localizeTitle,
            localizeDescription,
            localizeAddress,
        ];

        return apply(steps, (step) => step(property, locale));
    },
    mapLocalized: (property: *, translations: *) => {
        addLocale(property.locale, translations);
        useLocale(property.locale);

        const steps: *[] = [
            shallowCopy,
            slug,
            agency(gatsbyApi),
            localizePrice,
        ];

        const mapped = apply(steps, (step) => step(property));

        cleanupAfterSourceLocalized(mapped);

        return mapped;
    },
});

const createSearchMapper = (): Mapper => ({
    map: (property: *) => {
        const steps: *[] = [api_id, shallowCopy, images];

        const mapped = apply(steps, (step) => step(property));

        cleanupAfterSearchMapped(mapped);

        return mapped;
    },
    localize: (property: *, locale: string) => {
        const steps: *[] = [
            shallowCopy,
            setLocale,
            localizeTitle,
            localizeAddress,
            localizePrice,
        ];

        return apply(steps, (step) => step(property, locale));
    },
    mapLocalized: (property: *) => {
        const steps: *[] = [shallowCopy, slug];

        return apply(steps, (step) => step(property));
    },
});

const MapperFactory = {
    forSourcePlugin: (gatsbyApi: *): Mapper =>
        createSourcePluginMapper(gatsbyApi),
    forSearch: (): Mapper => createSearchMapper(),
};

export default MapperFactory;

export const mapPropertiesForSearch = (data: *, locale: *) => {
    const propertyMapper = MapperFactory.forSearch();

    return data
        .map(propertyMapper.map)
        .map((property) => propertyMapper.localize(property, locale))
        .map(propertyMapper.mapLocalized)
        .map((property) => ({
            ...property,
            images: property.images.map((image) => ({
                description: image.description,
                fixed: image.fixed({
                    width: 310,
                    height: 177,
                }),
                lastModifiedDate: image.lastModifiedDate,
            })),
        }));
};
