// @flow

import esb from "elastic-builder";
import { endpoints } from "../../c21-api";
import { Interactions } from "../interactions";
import {
    type Search,
    type SimilarPropertiesFilter,
    type Strategy,
} from "../types";
import { currencyToNumber } from "../../currency";
import {
    SearchBase,
    buildSingleValueFilterQuery,
    buildRangeFilterQuery,
    SEARCH_DEFAULTS,
} from "../shared";

class SimilarPropertiesSearchStrategy
    implements Strategy<SimilarPropertiesFilter>
{
    endpoint = endpoints.properties;

    buildElasticQuery = (filter: SimilarPropertiesFilter): * => {
        const { id, listingType, location, price, type } = filter;

        const matchQueries = [
            buildSingleValueFilterQuery("listingType", listingType),
            buildRangeFilterQuery("price.amount", price),
            buildSingleValueFilterQuery("type", type),
            buildSingleValueFilterQuery("address.postalCode", location),
            buildRangeFilterQuery("creationDate", {
                upper: window.__BUILD_TIMESTAMP__,
            }),
        ].filter((query) => !!query);

        const notMatchQueries = [
            buildSingleValueFilterQuery("_id", id), //WHY? ElasticSearch only gets the id property filled out after an update, the _id property is always correct for querying based on ids.
        ].filter((query) => !!query);

        const query = esb
            .boolQuery()
            .filter([
                esb.boolQuery().must(matchQueries),
                esb.boolQuery().mustNot(notMatchQueries),
            ]);

        return query;
    };

    getFacets = () => undefined;

    buildBrowserQueryString = () => ({});
}

const SimilarPropertiesSearchFactory = {
    fromPropertyAndPageSize: (
        property: *,
        pageSize: number,
    ): Search<SimilarPropertiesFilter> => {
        const price = currencyToNumber(property.price);
        const interactions = new Interactions<SimilarPropertiesFilter>(
            {
                id: property.api_id,
                type: property.type,
                listingType: property.listingType,
                location: property.address.postalCode,
                price: {
                    lower: price - price / 2,
                    upper: price + price / 2,
                },
            },
            SEARCH_DEFAULTS.sorting,
            SEARCH_DEFAULTS.page,
        );

        return new SearchBase(
            new SimilarPropertiesSearchStrategy(),
            interactions,
            pageSize,
        );
    },
};

export default SimilarPropertiesSearchFactory;
