// @flow

import React from "react";

import { applyCountFacet } from "@utils/search/facets";
import { t, gettext } from "ttag";

import { Filter } from "@2po-c21/components";
import { type PropertyFilterProps } from "../types";
import { ensureArray } from "@utils/array";

const Condition = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const values = applyCountFacet(
        [
            "READY_TO_USE",
            "TO_REFRESH",
            "TO_RENOVATE",
            "NEW",
            "MINT",
            "GOOD",
            "RENOVATED",
            "TO_ABOLISH",
            "TO_REALIZE",
        ],
        facets?.condition,
    ).map((value) => ({
        value,
        displayValue: gettext(`api.property.condition.${value}`),
    }));
    return (
        <Filter.MultiSelect
            label={t`common.filters.condition.label`}
            value={filter?.condition && ensureArray(filter.condition)}
            values={values}
            onChange={(value) => onChange && onChange({ condition: value })}
            applyBtnText={t`common.filters.apply`}
        />
    );
};

export default Condition;
