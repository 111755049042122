// @flow

import React from "react";
import { HeroVideo } from "@2po-c21/components";
import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";

type Props = {
    title?: string,
    subtitle?: {
        subtitle: string,
    },
    videoId?: string,
};

const render = ({ title, subtitle, videoId }: Props) => {
    return (
        <HeroVideo
            title={title}
            subtitle={subtitle?.subtitle}
            videoID={videoId}
        />
    );
};

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.HeroVideo;

export default createContentfulRenderer(condition, render);
