// @flow

import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { t } from "ttag";
import { Collapse, Button } from "reactstrap";
import { Filter, Icon, FilterIcon, Minus } from "@2po-c21/components";
import {
    addFilterFactory,
    Bedrooms,
    Condition,
    Elevator,
    Floors,
    Garden,
    ListingType,
    Location,
    Parking,
    Pool,
    Price,
    SubType,
    SurfaceHabitable,
    SurfaceGarden,
    SurfaceTotal,
    Terrace,
    Type,
    Country,
} from "@components/Filters";
import {
    type PropertyOverviewFilter,
    type PropertyFilterFacets,
} from "@utils/search";

import AppliedFiltersBlock from "../AppliedFiltersBlock";

type Props = {
    currentFilter: ?PropertyOverviewFilter,
    facets: ?PropertyFilterFacets,
    onFilterChanged?: (filter: *) => void,
    onFilterCleared?: () => void,
    language?: string,
    filterType?: string,
    countryFilterWhitelist?: string[],
};

const PropertyFilter = ({
    onFilterChanged,
    onFilterCleared,
    currentFilter,
    facets,
    language,
    filterType,
    countryFilterWhitelist,
}: Props) => {
    const [filter, setFilter] = useState(undefined);
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

    const toggleAdvancedFilters = () =>
        setShowAdvancedFilters(!showAdvancedFilters);

    const onChange = (newFilter) => {
        if (newFilter === filter) return;

        setFilter(newFilter);
    };

    const onClear = () => onFilterCleared && onFilterCleared();

    useEffect(() => {
        if (!filter) return;

        onFilterChanged && onFilterChanged(filter);
    }, [filter]);

    useEffect(() => {}, [currentFilter]);

    const amountOfAppliedFilters = () => {
        const defaultFiltersKeys = [
            "type",
            "listingType",
            "price",
            "location",
            "agency",
        ];
        const appliedFiltersLength =
            currentFilter &&
            Object.keys(currentFilter).filter(
                (filterKey) => !defaultFiltersKeys.includes(filterKey),
            ).length;

        return appliedFiltersLength ? `(${appliedFiltersLength})` : "";
    };
    const removeFilter = (activeKey: *, activeValue: *, type: string) => {
        let activeFilters = { ...currentFilter };
        if (activeFilters) {
            if (type === "array") {
                if (
                    activeFilters &&
                    Object.prototype.hasOwnProperty.call(
                        activeFilters,
                        activeKey,
                    )
                ) {
                    activeFilters[activeKey].splice(
                        activeFilters[activeKey].indexOf(activeValue),
                        1,
                    );
                }

                if (activeFilters[activeKey].length === 0) {
                    delete activeFilters[activeKey];
                }
            } else if (type === "object") {
                for (let objectKey in activeFilters[activeKey]) {
                    let value = activeFilters[activeKey][objectKey];
                    if (value === activeValue || value === undefined) {
                        delete activeFilters[activeKey][objectKey];
                    }
                }
                if (Object.keys(activeFilters[activeKey]).length === 0) {
                    delete activeFilters[activeKey];
                }
            } else {
                delete activeFilters[activeKey];
            }
            onChange(activeFilters);
        }
    };

    return (
        <Filter
            onChange={onChange}
            onClear={onClear}
            initialValue={currentFilter}
            filterType={filterType}
        >
            {(applyFilter, clearFilter) => {
                const addFilter = addFilterFactory(
                    currentFilter,
                    facets,
                    applyFilter,
                );

                return (
                    <>
                        <div className={style.filters}>
                            {addFilter(
                                <Location
                                    language={language}
                                    additionalClassName={
                                        style.propertyFilterLocation
                                    }
                                />,
                            )}
                            {addFilter(<ListingType />)}
                            {addFilter(<Type />)}
                            {addFilter(<Price />)}
                            <Collapse
                                isOpen={showAdvancedFilters}
                                className={style.advancedFilters}
                            >
                                {addFilter(<SubType />)}
                                {addFilter(<Bedrooms />)}
                                {addFilter(<SurfaceHabitable />)}
                                {addFilter(<SurfaceTotal />)}
                                {addFilter(<Garden />)}
                                {addFilter(<SurfaceGarden />)}
                                {addFilter(<Terrace />)}
                                {addFilter(<Pool />)}
                                {addFilter(<Parking />)}
                                {addFilter(<Floors />)}
                                {addFilter(<Condition />)}
                                {addFilter(<Elevator />)}
                                {!(
                                    currentFilter?.countryCode &&
                                    countryFilterWhitelist?.length === 1 &&
                                    currentFilter?.countryCode[0] ===
                                        countryFilterWhitelist[0]
                                ) &&
                                    !process.env.GATSBY_ALLOWED_COUNTRIES?.split(
                                        ",",
                                    )?.length &&
                                    addFilter(
                                        <Country
                                            whitelist={countryFilterWhitelist}
                                        />,
                                    )}
                            </Collapse>
                            <Button
                                className="filter-button"
                                onClick={() => toggleAdvancedFilters()}
                                color="primary"
                            >
                                {showAdvancedFilters ? (
                                    <>
                                        {`${t`common.filters.less-filters`} ${amountOfAppliedFilters()}`}
                                        <Icon icon={Minus} />
                                    </>
                                ) : (
                                    <>
                                        {`${t`common.filters.more-filters`} ${amountOfAppliedFilters()}`}
                                        <Icon icon={FilterIcon} />
                                    </>
                                )}
                            </Button>
                        </div>
                        {currentFilter &&
                            Object.keys(currentFilter).length > 0 && (
                                <div className={style.appliedFilters}>
                                    <AppliedFiltersBlock
                                        activeFilters={currentFilter}
                                        removeFilter={removeFilter}
                                        onFilterCleared={clearFilter}
                                        countryFilterWhitelist={
                                            countryFilterWhitelist
                                        }
                                    />
                                </div>
                            )}
                    </>
                );
            }}
        </Filter>
    );
};

export default PropertyFilter;
