// @flow

const WAIT_FOR_PARSED_TIMEOUT = 100;
const WAIT_FOR_PARSED_MAX_WAITING_TIME = 10000;

const LIBRARIES = {};

const curryOnload = (script, callback) => {
    const previous = script.onload || (() => undefined);
    return () => {
        previous();
        callback();
    };
};

export const load = (playerId: string) =>
    new Promise<any, typeof Error>((resolve, reject) => {
        let totalWait = 0;
        let { script, loaded } = LIBRARIES[playerId] || {
            script: null,
            loaded: false,
        };

        const waitForParsedPlayer = () => {
            if (!window.jwplayer) {
                if (totalWait > WAIT_FOR_PARSED_MAX_WAITING_TIME) {
                    reject(new Error("Could not parse jwplayer."));
                }

                setTimeout(() => {
                    totalWait += WAIT_FOR_PARSED_TIMEOUT;
                    waitForParsedPlayer();
                }, WAIT_FOR_PARSED_TIMEOUT);

                return;
            }

            // Resolved, store defaults in library
            // ensuring no settings go lost
            LIBRARIES[window.jwplayer.defaults.pid].defaults =
                window.jwplayer.defaults;

            // Activate the player
            window.jwplayer.defaults = LIBRARIES[playerId].defaults;

            // Return it to the user
            resolve(window.jwplayer);
        };

        // Script has been marked as loaded, directly wait for parse
        if (loaded) {
            waitForParsedPlayer();
            return;
        }

        // Script not present nor loading
        if (!script) {
            script = document.createElement("script");
            script.src = `https://cdn.jwplayer.com/libraries/${playerId}.js`;
            script.async = true;
            document.body?.appendChild(script);

            // Store script into library
            LIBRARIES[playerId] = { script, loaded: false };
        }

        // Script present but not yet loaded, append to handler
        script.onload = curryOnload(script, () => {
            // Script is guaranteed to be present, update loaded state
            // to prevent re-loading
            LIBRARIES[playerId].loaded = true;
            waitForParsedPlayer();
        });
    });
