// @flow

import React from "react";

import { CampaignBanner } from "@2po-c21/components";
import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";
import { EVENT_MAP, type BannerClickEvent } from "../../../models/events";
import Tracker from "../../Tracker/Tracker";

type Props = {
    imageLink: {
        externalLink?: string,
        contentfulInternalLink?: {
            slug?: string,
            query?: string,
        },
        linkTitle?: string,
    },
    desktopImage: *,
    mobileImage: *,
};

const render = ({ imageLink, desktopImage, mobileImage }: Props) => {
    const { src: desktopImageSrc } = desktopImage.fluid;
    const { src: mobileImageSrc } = mobileImage.fluid;
    const { externalLink, contentfulInternalLink, linkTitle } = imageLink || {};

    const targetUrl =
        (contentfulInternalLink && contentfulInternalLink?.slug) || "";
    const trackerEvent: BannerClickEvent = {
        type: EVENT_MAP.BannerClickEvent,
        name: "pagebanner",
        targeturl: targetUrl,
        targetname: linkTitle || "",
    };
    return (
        <Tracker trackerEvent={trackerEvent}>
            <CampaignBanner
                imageLink={
                    (contentfulInternalLink
                        ? contentfulInternalLink.slug ||
                          contentfulInternalLink.query
                        : externalLink && externalLink) || ""
                }
                desktopImage={desktopImageSrc}
                desktopImageDescription={
                    desktopImage.description || desktopImage.title
                }
                mobileImage={mobileImageSrc}
                mobileImageDescription={
                    mobileImage.description || mobileImage.title
                }
            />
        </Tracker>
    );
};

const condition = (content) => content.__typename === CONTENTFUL_TYPES.Banner;

export default createContentfulRenderer(condition, render);
