// @flow

import React, { Fragment } from "react";

import BlogQuickLinks from "./BlogQuickLinks";
import Blog from "./Blog";
import CampaignBanner from "./CampaignBanner";
import CategoryLinksBlock from "./CategoryLinksBlock";
import CtaGroup from "./CtaGroup";
import Iframe from "./Iframe";
import ImageContainer from "./ImageContainer";
import ImageInformationBlock from "./ImageInformationBlock";
import QuickLinkBlock from "./QuickLinkBlock";
import QuickSearch from "./QuickSearch";
import RichText from "./RichText";
import SearchPageBanner from "./SearchPageBanner";
import SplitView from "./SplitView";
import StepCardBlock from "./StepCardBlock";
import UspBlock from "./UspBlock";
import UspExtendedBlock from "./UspExtendedBlock";
import Video from "./Video";
import ContactHqForm from "./ContactHqForm";
import Faq from "./FAQ";
import HeroVideo from "./HeroVideo";
import { ensureArray } from "@utils/array";

import { type ContentfulRenderer } from "./types";

const contentfulRenderers: ContentfulRenderer[] = [
    Blog,
    BlogQuickLinks,
    CampaignBanner,
    CategoryLinksBlock,
    ContactHqForm,
    CtaGroup,
    Faq,
    HeroVideo,
    Iframe,
    ImageContainer,
    ImageInformationBlock,
    QuickLinkBlock,
    QuickSearch,
    RichText,
    SearchPageBanner,
    SplitView,
    StepCardBlock,
    UspBlock,
    UspExtendedBlock,
    Video,
];

export default (pageContent: Array<*>, locale: *) =>
    ensureArray(pageContent).flatMap<*>((content, index) =>
        contentfulRenderers
            .map((renderer) => renderer.render(content, index, locale))
            .filter((rendered) => !!rendered)
            .map((rendered) => (
                <Fragment
                    key={`render-${index}-${content?.id}-${
                        locale || "undefined"
                    }`}
                >
                    {rendered}
                </Fragment>
            )),
    );
