// @flow

import style from "./style.module.scss";

import React, { type Node } from "react";

import { PropertyCard } from "@components";
import { Pagination } from "@2po-c21/components";

import { RESULTS_PER_PAGE } from "@utils/search/shared";

import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
    type FavoritesEvent,
    type ImagesSliderEvent,
    type SliderImageClickEvent,
    EVENT_MAP,
} from "../../models/events";
import { PushEvent } from "../Tracker/Tracker";

type Props = {
    properties: Array<*>,
    numberOfResults: ?number,
    currentPage: ?number,
    onPageChanged: (number) => void,
    Header: ?Node,
};

const PropertySearchResults = ({
    properties,
    numberOfResults,
    currentPage,
    onPageChanged,
    Header,
}: Props) => {
    const gtmDispatcher = useGTMDispatch();

    const handleRemoveFavourite = (property) => {
        const event: FavoritesEvent = {
            favorite: false,
            propertyid: property.id,
            propertyview: "card",
            listname: "search results",
            type: EVENT_MAP.FavoritesEvent,
        };
        PushEvent(event, gtmDispatcher);
    };

    const handleAddFavourite = (property) => {
        const event: FavoritesEvent = {
            propertyid: property.id,
            propertyview: "card",
            listname: "search results",
            favorite: true,
            type: EVENT_MAP.FavoritesEvent,
        };
        PushEvent(event, gtmDispatcher);
    };

    const pushImagesSlider = (direction: string, projectid: string) => {
        const gtmEvent: ImagesSliderEvent = {
            direction,
            listname: "search results",
            propertyid: projectid,
            propertyview: "card",
            type: EVENT_MAP.ImagesSliderEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    const handleOnNext = (property: any) => {
        pushImagesSlider("right", property.id);
    };
    const handleOnPrevious = (property: any) => {
        pushImagesSlider("left", property.id);
    };

    const handleOnPropertyCardClick = (event: SliderImageClickEvent) => {
        event.propertyview = "search results";
        event.listname = "card";
        PushEvent(event, gtmDispatcher);
    };

    const propertyCardCallbacks = {
        handleRemoveFavourite,
        handleAddFavourite,
        handleNext: handleOnNext,
        handlePrevious: handleOnPrevious,
        handleOnClick: handleOnPropertyCardClick,
    };

    return numberOfResults ? (
        <>
            {Header && <div className={style.sorting}>{Header}</div>}
            <div className={style.cards}>
                {properties.map((property, index) => (
                    <div className={style.card} key={index}>
                        <PropertyCard
                            property={property}
                            callbacks={propertyCardCallbacks}
                        />
                    </div>
                ))}
            </div>
            <div>
                <Pagination
                    onPageChange={onPageChanged}
                    nrOfPages={Math.ceil(numberOfResults / RESULTS_PER_PAGE)}
                    currentPage={currentPage}
                />
            </div>
        </>
    ) : null;
};
export default PropertySearchResults;
