// @flow

import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Section } from "@containers";
import { First, Second, Last, Results } from "./Steps";
import style from "./style.module.scss";
import { endpoints } from "../../utils/c21-api/endpoints";
import axios from "axios";
import queryString from "query-string";
import { type ToolStepEvent, EVENT_MAP } from "../../models/events";
import { PushEvent } from "../../components/Tracker/Tracker";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
type Props = {
    agencyData: [],
    contentfulData: [],
    locale: string,
};
const EstimationTool = ({ agencyData, contentfulData, locale }: Props) => {
    const [isReady, setIsReady] = useState(false);
    const [values, setValues] = useState({
        language: locale,
        postalCode: 0,
        GDPR: undefined,
        token: null,
    });
    const [result, setResult] = useState();
    const [qs, setQs] = useState();
    const gtmDispatcher = useGTMDispatch();

    useEffect(() => {
        const QS = queryString.parse(window.location.search) || {};
        setQs(QS.agency);
        onStepChange({ activeStep: 1 });
    }, []);

    useEffect(() => {
        if (isReady) {
            fetchResults();
        }
    }, [values]);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const updateFormCollection = (newValues, isComplete) => {
        isComplete ? setIsReady(true) : null;

        setValues({
            ...values,
            ...newValues,
        });
    };

    const fetchResults = async () => {
        if (!executeRecaptcha) {
            return;
        }
        const gRecaptchaResponse = await executeRecaptcha("homepage");
        values.token = gRecaptchaResponse;
        delete values.GDPR;
        _fethResults(values)
            .then((data) => {
                setResult(data.data);
            })
            .catch(() => {
                setResult(false);
            });
    };

    const _fethResults = (formValues: {}): Promise<*> => {
        const urlForm = `${endpoints.forms}/estimation`;
        return axios({
            method: "post",
            url: urlForm,
            headers: {
                "Content-Type": "application/json",
            },
            data: formValues,
        });
    };

    const steps = [
        <First
            key={"property details 1"}
            id={"property details 1"}
            updateFormCollection={(data) => updateFormCollection(data)}
        />,
        <Second
            key={"property details 2"}
            id={"property details 2"}
            updateFormCollection={(data) => updateFormCollection(data)}
        />,
        <Last
            key={"personal details"}
            id={"personal details"}
            updateFormCollection={(data, isComplete) =>
                updateFormCollection(data, isComplete)
            }
            agencyData={agencyData}
            postalCode={values.postalCode}
            presetAgencyId={qs}
        />,
        <Results
            key={"estimation laststep"}
            id={"estimation laststep"}
            results={result}
            contentfulData={contentfulData}
            locale={locale}
        />,
    ];

    const onStepChange = ({ activeStep }: *) => {
        const gtmEvent: ToolStepEvent = {
            name: "property estimation",
            stepnumber: activeStep,
            stepname: String(steps[parseInt(activeStep - 1)].key),
            element: "widget",
            messagetype: "undefined",
            type:
                activeStep === steps.length
                    ? EVENT_MAP.ToolLastStepEvent
                    : EVENT_MAP.ToolStepEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    return (
        <Section theme={"themeGray"}>
            <div className={style.estimationToolContainer}>
                <StepWizard isLazyMount={true} onStepChange={onStepChange}>
                    {steps}
                </StepWizard>
            </div>
        </Section>
    );
};

export default EstimationTool;
