// @flow

import React from "react";
import axios from "axios";
import { endpoints } from "../../utils/c21-api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { AgencyContactForm } from "@2po-c21/components";
import { t } from "ttag";
import style from "./style.module.scss";
import { SendToolEvent } from "../../components/Tracker/Tracker";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const ANCHOR = "agency-contact-form";

type Props = {
    agencyApiId: string,
    locale: string,
    type: "agency" | "property",
    property?: {
        price?: string,
        address?: {
            street?: string,
            number?: string,
            postalCode?: string,
            city?: string,
        },
        carouselImages?: {
            fixed?: {
                src?: string,
            },
        },
    },
    propertyAddressHidden?: boolean,
};

const Form = ({
    agencyApiId,
    locale,
    type,
    property,
    propertyAddressHidden,
}: Props) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const gtmDispatcher = useGTMDispatch();

    const propertyPrice = property?.price ?? t`common.price.on-demand`;
    const propertyAddress =
        !propertyAddressHidden && property?.address
            ? `${property?.address?.street ?? ""} ${
                  property?.address?.number ?? ""
              } ${property?.address?.postalCode ?? ""} ${
                  property?.address?.city ?? ""
              }`
            : t`common.address.on-demand`;
    const propertyImage =
        property?.carouselImages ?? false
            ? property?.carouselImages?.[0]?.fixed?.src ?? ""
            : "";
    return (
        <div
            id={ANCHOR}
            data-tracking-event="tool-step"
            data-tracking-info={`{"name":"contact","stepnumber":"1","stepname":"contact details","element":"form"}`}
            className={style.agencyContactFormWrapper}
        >
            <AgencyContactForm
                fields={getFormFields()}
                formMessages={getFormMessages(type, locale)}
                onSubmitForm={(values) => {
                    const dataValues = {
                        ...values,
                        propertyPrice,
                        propertyAddress,
                        propertyImage,
                    };
                    submitForm(
                        agencyApiId,
                        locale,
                        dataValues,
                        executeRecaptcha,
                    ) // TODO [ACC24062020] Resolve success when design is done
                        .then(() => {
                            SendToolEvent(
                                gtmDispatcher,
                                "contact",
                                2,
                                values.chosenSubjectEventKey,
                                2,
                                "contact laststep",
                                true,
                            );
                            alert(t`form.agency-contact.message-success`);
                        })
                        .catch(() => {
                            alert(t`form.agency-contact.message-error`);
                        });
                }}
            />
        </div>
    );
};

Form.anchor = `#${ANCHOR}`;

export default Form;

const createQueryParams = (language, emailValue, url, gRecaptchaResponse) => ({
    language,
    emailValue,
    url,
    "g-recaptcha-response": gRecaptchaResponse,
});

const createData = (values) =>
    Object.entries(values)
        .filter((keyValue) => keyValue[0] !== "GDPR")
        .map(([key, val]) =>
            typeof val === "string"
                ? `${key}=${encodeURIComponent(val)}`
                : undefined,
        )
        .join("&");

const submitForm = async (id, language, dataValues, executeRecaptcha) => {
    if (!executeRecaptcha) {
        return;
    }

    const gRecaptchaResponse = await executeRecaptcha("homepage");

    return _submitForm(
        id,
        language,
        dataValues.emailValue,
        window.location.href,
        gRecaptchaResponse,
        dataValues,
    );
};

const _submitForm = (
    id: string,
    language: string,
    emailValue: string,
    url: string,
    gRecaptchaResponse: string,
    formValues: {},
): Promise<*> => {
    const urlForm = `${endpoints.forms}/contact/agency/${id}`;

    return axios({
        method: "post",
        url: urlForm,
        params: createQueryParams(
            language,
            emailValue,
            url,
            gRecaptchaResponse,
        ),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: createData(formValues),
    });
};

export const getFormMessages = (type: string, locale: string) => ({
    title: t`form.agency-contact.title`,
    subtitle: t`form.agency-contact.subtitle`,
    subject:
        type === "property"
            ? [
                  {
                      label: t`form.agency-contact.subject.visit`,
                      eventKey: "visit a property",
                  },
                  {
                      label: t`form.agency-contact.subject.info`,
                      eventKey: "more info",
                  },
                  {
                      label: t`form.agency-contact.subject.offer`,
                      eventKey: "make an offer",
                  },
              ]
            : [
                  {
                      label: t`form.agency-contact.subject.buy`,
                      eventKey: "buy a property",
                  },
                  {
                      label: t`form.agency-contact.subject.invest`,
                      eventKey: "invest in a property",
                  },
                  {
                      label: t`form.agency-contact.subject.second-residence`,
                      eventKey: "second residence",
                  },
                  {
                      label: t`form.agency-contact.subject.find`,
                      eventKey: "find a property",
                  },
                  {
                      label: t`form.agency-contact.subject.rent`,
                      eventKey: "rent a property",
                  },
                  {
                      label: t`form.agency-contact.subject.benefits`,
                      eventKey: "benefits",
                  },
              ],
    checkBoxMessage: t`form.agency-contact.privacy-message`,
    checkBoxLink: {
        href: `/${locale}/${t`slug.privacy-policy`}`,
        label: t`form.agency-contact.privacy-policy`,
    },
    reCaptcha: {
        warning: t`form.agency-contact.recaptcha-warning`,
        policy: t`form.agency-contact.recaptcha-policy`,
        tos: t`form.agency-contact.recaptcha-tos`,
        and: t`common.text.and`,
    },
    submitMessage: t`form.agency-contact.submit`,
});

export const getFormFields = () => [
    {
        id: "nameValue",
        label: t`form.agency-contact.fields.name`,
        required: true,
        placeholder: t`form.agency-contact.fields.name.placeholder`,
        type: "text",
        errorMessage: t`form.agency-contact.fields.name.error-empty`,
    },
    {
        id: "firstnameValue",
        label: t`form.agency-contact.fields.firstname`,
        required: true,
        placeholder: t`form.agency-contact.fields.firstname.placeholder`,
        type: "text",
        errorMessage: t`form.agency-contact.fields.firstname.error-empty`,
    },
    {
        id: "phoneValue",
        label: t`form.agency-contact.fields.phone`,
        required: false,
        placeholder: t`form.agency-contact.fields.phone.placeholder`,
        type: "tel",
        errorMessage: t`form.agency-contact.fields.phone.error-empty`,
    },
    {
        id: "emailValue",
        label: t`form.agency-contact.fields.email`,
        required: true,
        placeholder: t`form.agency-contact.fields.email.placeholder`,
        type: "email",
        errorMessage: t`form.agency-contact.fields.email.error-empty`,
        emailErrorMessage: t`form.agency-contact.fields.email.error-invalid`,
    },
    {
        id: "body",
        label: t`form.agency-contact.fields.message`,
        required: true,
        placeholder: t`form.agency-contact.fields.message.placeholder`,
        type: "textarea",
        errorMessage: t`form.agency-contact.fields.message.error-empty`,
    },
];
