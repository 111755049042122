/* eslint-disable react/no-unknown-property */
// @flow

import React from "react";
import style from "./style.module.scss";

import { createContentfulRenderer, EMBEDDED_CONTENT_TYPE } from "../types";

const render = (content, key, locale) => (
    <div key={key} className={style.videoWrapper}>
        <iframe
            width="100%"
            height="100%"
            src={content.fields.youtubeUrl[locale]}
            frameBorder="0"
            modestbranding="1"
            color="white"
            disablekb="1"
            iv_load_policy="3"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    </div>
);

const condition = (content) =>
    content?.sys?.contentType?.sys?.id === EMBEDDED_CONTENT_TYPE.Video;

export default createContentfulRenderer(condition, render);
