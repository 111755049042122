// @flow

import React from "react";

import { ensureArray } from "@utils/array";
import { applyCountFacet } from "@utils/search/facets";
import { Filter } from "@2po-c21/components";
import { t, gettext } from "ttag";
import { type PropertyFilterProps } from "../types";

const subTypeValues = [
    "TOWNHOUSE",
    "VILLA",
    "FERMETTE",
    "CASTLE",
    "FARM",
    "FOREST",
    "INVESTMENT",
    "LOFT",
    "GROUND",
    "APPARTEMENT",
    "HOUSE",
    "DUPLEX",
    "TRIPLEX",
    "PENTHOUSE",
    "STUDIO",
    "MANSION",
    "BUNGALOW",
    "STUDENT_ACCOMMODATION",
    "CLOSED_GARAGE",
    "INDOOR_PARKING_SPACE",
    "OUTDOOR_PARKING_SPACE",
    "COVERED_OUTDOOR_SPACE",
    "AGRICULTURAL",
    "BUILDABLE",
    "RECREATIONAL",
    "PASTURE",
    "SHOP_AND_RETAIL",
    "HORECA",
    "LEISURE_AND_SPORTS",
    "INDUSTRIAL",
    "INDUSTRY",
    "INDUSTRY_HALL",
    "OTHER_BUILDING",
    "WAREHOUSE",
    "PARKING",
    "WHOLESALE_BUSINESS",
    "FREE_PROFESSION",
    "SHOWROOM",
    "LEISURE_AND_SPORTS",
];

const SubType = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const values = applyCountFacet(subTypeValues, facets?.subType).map(
        (value) => ({
            value,
            displayValue: gettext(`api.property.sub-type.${value}`),
        }),
    );

    return (
        <Filter.MultiSelect
            label={t`common.filters.sub-type.label`}
            values={values}
            value={filter?.subType && ensureArray(filter.subType)}
            onChange={(value) => onChange && onChange({ subType: value })}
            applyBtnText={t`common.filters.apply`}
        />
    );
};

export default SubType;
