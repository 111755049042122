// @flow

import React from "react";

import style from "./style.module.scss";

import classnames from "classnames";

type Props = {
    background?: { color: string, position: string, size: string },
    fullBleed?: boolean,
    children: *,
    theme?: string,
};

const Background = ({ children, background, theme, fullBleed }: Props) => {
    return (
        <div
            className={classnames(
                style.backgroundWrapper,
                theme && style[theme],
            )}
        >
            <div
                className={classnames(
                    style.backgroundHolder,
                    fullBleed ? style.fullBleed : "",
                )}
            >
                {background?.position !== "Bottom" && (
                    <div
                        className={classnames(
                            style.background,
                            background?.position && style[background.position],
                            background?.size && style[background.size],
                            background?.color && style[background.color],
                        )}
                    />
                )}
                <div className={style.children}>{children}</div>
                {background?.position === "Bottom" && (
                    <div
                        className={classnames(
                            style.background,
                            background?.position && style[background.position],
                            background?.size && style[background.size],
                            background?.color && style[background.color],
                        )}
                    />
                )}
            </div>
        </div>
    );
};

export default Background;
