// @flow

import style from "./style.module.scss";

import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { t } from "ttag";
import scrollTo from "gatsby-plugin-smoothscroll";

import classNames from "classnames";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { type DetailElementClickEvent, EVENT_MAP } from "../../models/events";
import { PushEvent } from "../../components/Tracker/Tracker";

import {
    AgencyContactBlock,
    ReadMore,
    PropertyDescription as Description,
    SectionTitle,
} from "@2po-c21/components";

type Agent = {
    firstName: string,
    lastName: string,
    images: Array<{
        description: string,
        fixed: *,
    }>,
};

type Agency = {
    name: string,
    slug: string,
    images: Array<{
        description: string,
        fixed: *,
    }>,
    address: {
        city: string,
        postalCode: string,
        number: string,
        countryCode: string,
        street: string,
    },
};

type Props = {
    title: string,
    subTitle: string,
    description: string,
    agent: Agent,
    agency: Agency,
    fallbackImage: string,
    contactFormAnchor: string,
    propertyId: string,
};

const SHORT_DESCRIPTION_UPPER_LIMIT = 300;
const LONG_DESCRIPTION_LOWER_LIMIT = 1000;

const PropertyDescription = ({
    title,
    subTitle,
    description,
    agent,
    agency,
    fallbackImage,
    contactFormAnchor,
    propertyId,
}: Props) => {
    const gtmDispatcher = useGTMDispatch();

    const _description = description || "";

    const isShortDescription =
        _description.length <= SHORT_DESCRIPTION_UPPER_LIMIT;
    const isLongDescription =
        _description.length >= LONG_DESCRIPTION_LOWER_LIMIT;

    const [collapseContent] = useState(isLongDescription);

    const image = agent?.images[0] || agency?.images[0] || fallbackImage;

    const getContactProps = {
        label: t`property-details.property-description.estate-agent`,
        agencyName: agency?.name,
        agentName: agent ? `${agent.firstName} ${agent.lastName}` : "",
        image: {
            // eslint-disable-next-line react/display-name
            imageSrc: () => (
                <Image
                    fixed={image?.fixed}
                    alt={image?.description}
                    backgroundColor
                />
            ),
            description: image?.description,
        },
        visitPropertyBtnText: t`property-details.property-description.visit-property`,
        visitWebsiteBtnText: t`property-details.property-description.visit-website`,
        address: {
            street: agency?.address?.street,
            number: agency?.address?.number,
            postalCode: agency?.address?.postalCode,
            city: agency?.address?.city,
            country: agency?.address?.countryCode,
        },
    };

    const sendEvent = (name: string, element: string, targetUrl: string) => {
        const gtmEvent: DetailElementClickEvent = {
            name,
            element,
            targeturl: targetUrl,
            type: EVENT_MAP.DetailElementClickEvent,
            propertyid: propertyId,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    const handleOnReadMore = () => {
        sendEvent("read more", "text", "#");
    };

    const handleOnLinkAgency = () => {
        sendEvent("visit website", "button", agency.slug);
    };

    const handleClickVisitProperty = () => {
        sendEvent("visit property", "button", contactFormAnchor);
    };

    return (
        <Row className={style.propertyDescriptionContainer}>
            {description && (
                <Col lg={isShortDescription ? "12" : "7"}>
                    <SectionTitle>
                        {title}
                        <div className={style.subTitle}>{subTitle}</div>
                    </SectionTitle>

                    <div
                        className={classNames(style.propertyDescription, {
                            [style.propertyDescriptionShort]:
                                isShortDescription,
                        })}
                    >
                        <ReadMore
                            collapseContent={collapseContent}
                            label={t`common.text.read-more`}
                            onReadMore={handleOnReadMore}
                        >
                            <Description description={description} />
                        </ReadMore>
                    </div>
                </Col>
            )}
            <Col
                md={{
                    size: isShortDescription ? 6 : 4,
                    offset: isShortDescription ? 0 : 1,
                }}
            >
                <div
                    className={classNames(style.agencyCardWrapper, {
                        [style.wideCard]: isShortDescription,
                    })}
                >
                    {agency && (
                        <AgencyContactBlock
                            wideCard={isShortDescription}
                            {...getContactProps}
                            onClickVisitProperty={() => {
                                scrollTo(contactFormAnchor);
                                handleClickVisitProperty();
                            }}
                            LinkToAgency={(children) => (
                                <Link
                                    to={agency.slug}
                                    onClick={handleOnLinkAgency}
                                >
                                    {children}
                                </Link>
                            )}
                        />
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default PropertyDescription;
