// @flow

import React, { useState } from "react";

import style from "./style.module.scss";

import { StepCard, SectionTitle, Icon, Left, Right } from "@2po-c21/components";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { Section } from "@containers";

import { t } from "ttag";

import {
    createContentfulRenderer,
    CONTENTFUL_TYPES,
    QUICK_LINK_BLOCK_TYPES,
} from "../types";

type Props = {
    linkItems: {
        linkTitle: string,
        linkSubtitle: {
            linkSubtitle: string,
        },
        contentfulInternalLink?: {
            slug: string,
        },
        image: {
            fixed: *,
            description: string,
        },
    }[],
    title: string,
    hideStepsTitle?: boolean,
};

const renderCard = (
    index,
    linkTitle,
    linkSubtitle,
    image,
    hideStepsTitle = false,
) => (
    <StepCard
        stepNr={`${t`landing.step-block.step`} ${index + 1}`}
        title={linkTitle}
        subtitle={linkSubtitle}
        image={{
            // eslint-disable-next-line react/display-name
            imageSrc: () => (
                <Image
                    fixed={image?.fixed}
                    alt={image?.description}
                    backgroundColor
                />
            ),
            description: image?.description,
        }}
        isStepHidden={hideStepsTitle}
    />
);

const render = ({ linkItems, title, hideStepsTitle }: Props) => {
    const [arrowState, setArrowState] = useState({ left: false, right: true });

    const getScrollPosition = (event) => {
        const element = event.target;

        if (
            Math.floor(element.scrollWidth - element.scrollLeft) ===
            element.clientWidth
        ) {
            setArrowState({ left: true, right: false });
        }

        if (element.scrollLeft === 0) {
            setArrowState({ left: false, right: true });
        }

        if (
            element.scrollLeft > 0 &&
            Math.floor(element.scrollWidth - element.scrollLeft) >
                element.clientWidth
        ) {
            setArrowState({ left: true, right: true });
        }
    };

    return (
        <Section>
            <SectionTitle>{title}</SectionTitle>
            <div className={style.stepsCardBlockWrapper}>
                {arrowState.left && <Icon icon={Left} size="lg" />}
                <div
                    className={style.stepsCardBlock}
                    onScroll={getScrollPosition}
                >
                    {linkItems.map(
                        (
                            {
                                image,
                                linkTitle,
                                linkSubtitle: { linkSubtitle },
                                contentfulInternalLink,
                            },
                            index,
                        ) =>
                            contentfulInternalLink ? (
                                <Link
                                    key={index}
                                    to={contentfulInternalLink.slug}
                                >
                                    {renderCard(
                                        index,
                                        linkTitle,
                                        linkSubtitle,
                                        image,
                                    )}
                                </Link>
                            ) : (
                                renderCard(
                                    index,
                                    linkTitle,
                                    linkSubtitle,
                                    image,
                                    hideStepsTitle,
                                )
                            ),
                    )}
                </div>
                {arrowState.right && <Icon icon={Right} size="lg" />}
            </div>
        </Section>
    );
};
const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.QuickLinkBlock &&
    content.type === QUICK_LINK_BLOCK_TYPES.Steps;

export default createContentfulRenderer(condition, render);
