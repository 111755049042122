// @flow

/**
 * A contentful image asset
 */
type ContentfulAssetFile = {
    url: string,
    details: {
        image: {
            width: number,
            height: number,
        },
    },
};

/**
 * Cheaply create on the fly, gatsby-image capable renditions here
 * TODO: Find a way to fetch base64 image strings here, currently not possible
 *       could be possible by node extension to an asset
 * fluid(maxWidth: 1050)
 */
export default (
    {
        url,
        details: {
            image: { width, height },
        },
    }: ContentfulAssetFile,
    quality: number,
) => ({
    aspectRatio: width / height,
    src: `${url}?w=1050&q=${quality}`,
    srcSet: `${url}?w=263&h=175&q=${quality} 263w,\n${url}?w=525&h=349&q=${quality} 525w,\n${url}?w=1050&h=697&q=${quality} 1050w,\n${url}?w=1575&h=1046&q=${quality} 1575w,\n${url}?w=2100&h=1395&q=${quality} 2100w,\n${url}?w=3150&h=2092&q=${quality} 3150w`,
    srcWebp: `${url}?w=1050&q=${quality}&fm=webp`,
    srcSetWebp: `${url}?w=263&h=175&q=${quality}&fm=webp 263w,\n${url}?w=525&h=349&q=${quality}&fm=webp 525w,\n${url}?w=1050&h=697&q=${quality}&fm=webp 1050w,\n${url}?w=1575&h=1046&q=${quality}&fm=webp 1575w,\n${url}?w=2100&h=1395&q=${quality}&fm=webp 2100w,\n${url}?w=3150&h=2092&q=${quality}&fm=webp 3150w`,
    sizes: "(max-width: 1050px) 100vw, 1050px",
});
