// @flow

import React from "react";

import { MapWithPin, List, LinkButton } from "@2po-c21/components";

import { t } from "ttag";

import style from "./style.module.scss";

type Props = {
    mapVisible: boolean,
    onShowClick: () => void,
    onHideClick: () => void,
};

const ShowHideMap = ({ onShowClick, onHideClick, mapVisible }: Props) => (
    <span className={style.container}>
        <LinkButton
            className={style.linkButton}
            onClick={onShowClick}
            toggled={mapVisible}
            icon={MapWithPin}
            iconSize={"lg"}
        >
            {t`map.show`}
        </LinkButton>
        <LinkButton
            className={style.linkButton}
            onClick={onHideClick}
            toggled={!mapVisible}
            icon={List}
            iconSize={"md"}
        >
            {t`map.hide`}
        </LinkButton>
    </span>
);

export default ShowHideMap;
