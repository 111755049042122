// @flow

import React from "react";
import { t } from "ttag";

import { Filter } from "@2po-c21/components";
import { applyCountFacet } from "@utils/search/facets";
import { type PropertyFilterProps } from "../types";

const Elevator = ({ filter, onChange, facets }: PropertyFilterProps) => {
    const values = applyCountFacet([true], facets?.elevator);

    return (
        <>
            {values.length > 0 && (
                <Filter.Boolean
                    label={t`common.filters.elevator.label`}
                    value={filter?.elevator}
                    onChange={(value) =>
                        onChange && onChange({ elevator: value })
                    }
                />
            )}
        </>
    );
};

export default Elevator;
