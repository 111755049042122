// @flow

import React from "react";

import { Row, Col, Container } from "reactstrap";
import { Logo } from "@2po-c21/components";

import { type NavigationItem } from "../Header";

import Link from "../Link";

import style from "./style.module.scss";

import { groupItems } from "@utils/array";
import Tracker from "../Tracker/Tracker";
import { EVENT_MAP, type NavigationClickEvent } from "../../models/events";

type Props = {
    footerItems: { navigationItems: Array<NavigationItem> },
};

const NavItem = ({ navigationItem }: *) => {
    const groupedItems =
        navigationItem?.navigationItems &&
        groupItems(navigationItem.navigationItems, 8);
    return (
        <div>
            <div className={style.footerTitle}>{navigationItem.title}</div>
            <div className={style.columnHolder}>
                {groupedItems?.map((items, index) => (
                    <div key={index}>
                        {items.map((item, index) => {
                            const pageurl =
                                typeof window !== "undefined"
                                    ? window.location.href
                                    : "";

                            const to = item.link.contentfulInternalLink
                                ? item.link.contentfulInternalLink.slug
                                : item.link.query
                                ? item.link.query
                                : item.link.externalLink &&
                                  item.link.externalLink;
                            const trackerEvent: NavigationClickEvent = {
                                type: EVENT_MAP.NavigationClickEvent,
                                linkname: "",
                                name: "footer",
                                pageurl: pageurl,
                                targeturl: to,
                            };
                            return (
                                <Tracker
                                    trackerEvent={trackerEvent}
                                    key={index}
                                >
                                    {item.link ? (
                                        <Link to={to}>
                                            <div className={style.footerItem}>
                                                {item.title}
                                            </div>
                                        </Link>
                                    ) : (
                                        <div
                                            key={index}
                                            className={style.footerItem}
                                        >
                                            {item.title}
                                        </div>
                                    )}
                                </Tracker>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

const Footer = ({ footerItems: { navigationItems } }: Props) => {
    return (
        navigationItems && (
            <>
                <div className={style.footerSection}>
                    <Container>
                        <Row className={style.footerWrapper}>
                            <Col md="3" sm="12">
                                <Logo
                                    logo={"square"}
                                    width="138"
                                    height="125"
                                />
                            </Col>

                            {navigationItems[0] && (
                                <Col lg="3" md="4" sm="6">
                                    <NavItem
                                        navigationItem={navigationItems[0]}
                                    />
                                </Col>
                            )}
                            {navigationItems[1] && (
                                <Col lg="3" md="4" sm="6">
                                    <NavItem
                                        navigationItem={navigationItems[1]}
                                    />
                                </Col>
                            )}
                            <Col lg="3" md="4" sm="12">
                                {navigationItems[2] && (
                                    <NavItem
                                        navigationItem={navigationItems[2]}
                                    />
                                )}
                                {navigationItems[3] && (
                                    <div className={style.footerItemHolder}>
                                        <NavItem
                                            navigationItem={navigationItems[3]}
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {navigationItems[4] && (
                    <div className={style.legalSectionWrapper}>
                        <Container className={style.legalSection}>
                            <span className={style.copyright}>
                                © CENTURY 21 Benelux
                            </span>
                            <div className={style.legalItemsWrapper}>
                                {navigationItems[4].navigationItems?.map(
                                    (item, index) =>
                                        item.link ? (
                                            <Link
                                                to={
                                                    item.link
                                                        .contentfulInternalLink
                                                        ? item.link
                                                              .contentfulInternalLink
                                                              .slug
                                                        : item.link.query
                                                        ? item.link.query
                                                        : item.link
                                                              .externalLink &&
                                                          item.link.externalLink
                                                }
                                                key={index}
                                            >
                                                <div
                                                    className={style.footerItem}
                                                >
                                                    {item.title}
                                                </div>
                                            </Link>
                                        ) : (
                                            <div
                                                key={index}
                                                className={style.footerItem}
                                            >
                                                {item.title}
                                            </div>
                                        ),
                                )}
                            </div>
                        </Container>
                    </div>
                )}
            </>
        )
    );
};

export default Footer;
