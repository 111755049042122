// @flow

import style from "./style.module.scss";

import React from "react";

import { Container } from "reactstrap";

import { QuickSearch } from "@2po-c21/components";
import QuickSearchFilter from "./QuickSearchFilter";
import Image from "gatsby-image";

import { QuickLinksBlock } from "../QuickLinkBlock";

import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";

type Props = {
    content: {
        contentTitle: string,
        contentSubtitle: string,
        squareImage: {
            description: string,
            fluid: *,
        },
    },
    node_locale: string,
    quickLinks?: *,
    background?: {
        size: string,
        color: string,
        position: string,
    },
    filters?: *,
    overlaySelectedBrand?: {
        description?: string,
        fluid: *,
    },
};

const render = (
    {
        content: { contentTitle, contentSubtitle, squareImage },
        node_locale,
        quickLinks,
        filters,
        overlaySelectedBrand,
    }: Props,
    key,
) => {
    return (
        <>
            <Container fluid key={key} className="p-0">
                <QuickSearch
                    info={contentTitle}
                    infoSubText={contentSubtitle}
                    image={{
                        // eslint-disable-next-line react/display-name
                        imageSrc: () => (
                            <Image
                                fluid={squareImage.fluid}
                                alt={squareImage?.description}
                                backgroundColor
                                style={{ position: "absolute" }}
                            />
                        ),
                        description: squareImage?.description,
                    }}
                    overlay={{
                        // eslint-disable-next-line react/display-name
                        imageSrc: () => (
                            <Image
                                fluid={overlaySelectedBrand?.fluid}
                                alt={overlaySelectedBrand?.description}
                                backgroundColor
                            />
                        ),
                        description: overlaySelectedBrand?.description,
                    }}
                >
                    <div className={style.filters}>
                        {filters?.length && (
                            <QuickSearchFilter
                                locale={node_locale}
                                filters={filters}
                            />
                        )}
                    </div>
                    <div className={style.quickLinks}>
                        {quickLinks && quickLinks.linkItems && (
                            <QuickLinksBlock linkItems={quickLinks.linkItems} />
                        )}
                    </div>
                </QuickSearch>
                <div className={style.quickLinksMobile}>
                    {quickLinks && quickLinks.linkItems && (
                        <QuickLinksBlock linkItems={quickLinks.linkItems} />
                    )}
                </div>
            </Container>
        </>
    );
};

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.QuickSearch;

export default createContentfulRenderer(condition, render);
