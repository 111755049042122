// @flow

import React from "react";

export { default as Bedrooms } from "./Bedrooms";
export { default as Condition } from "./Condition";
export { default as Elevator } from "./Elevator";
export { default as ListingType } from "./ListingType";
export { default as Location } from "./Location";
export { default as Floors } from "./Floors";
export { default as Garden } from "./Garden";
export { default as Parking } from "./Parking";
export { default as Pool } from "./Pool";
export { default as Price } from "./Price";
export { default as SubType } from "./SubType";
export {
    Habitable as SurfaceHabitable,
    Garden as SurfaceGarden,
    Total as SurfaceTotal,
} from "./Surface";
export { default as Terrace } from "./Terrace";
export { default as Type } from "./Type";
export { default as Country } from "./Country";

export const addFilterFactory =
    <T, F>(filter: T, facets: F, onChange: ($Values<T>) => void) =>
    (filterElement: *) =>
        React.cloneElement(filterElement, {
            filter,
            facets,
            onChange,
        });
