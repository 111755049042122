// @flow
import React from "react";
import style from "./style.module.scss";

type Props = {
    locale: string,
    templateId: string,
    businessUnitId: string,
    reviewLanguages: string,
    dataHeight: string,
    dataWidth: string,
    dataStars: string,
    dataTheme: string,
    dataTrustPilotId?: string,
    dataSchemaType?: string,
    dataAllowRobots?: boolean,
};

const Slider = ({
    locale,
    templateId,
    businessUnitId,
    reviewLanguages,
    dataHeight,
    dataWidth,
    dataStars,
    dataTheme,
    dataTrustPilotId,
    dataSchemaType,
    dataAllowRobots,
}: Props) => {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div
            ref={ref}
            className={`trustpilot-widget ${style.trustPilotCarousel}`}
            data-locale={locale}
            data-template-id={templateId}
            data-businessunit-id={businessUnitId}
            data-style-height={dataHeight}
            data-style-width={dataWidth}
            data-theme={dataTheme}
            data-stars={dataStars}
            data-review-languages={reviewLanguages}
            data-location={dataTrustPilotId}
            data-schema-type={dataSchemaType}
            data-allow-robots={dataAllowRobots}
        >
            <a
                href="https://nl-be.trustpilot.com/review/century21.be"
                target="_blank"
                rel="noreferrer"
            >
                Trustpilot
            </a>
        </div>
    );
};
export default Slider;
