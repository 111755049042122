// @flow

import React from "react";
import { t } from "ttag";
import { applyCountFacet } from "@utils/search/facets";

import { Filter } from "@2po-c21/components";
import { type PropertyFilterProps } from "../types";

const Parking = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const values = applyCountFacet([true], facets?.parking);
    return (
        <>
            {values.length > 0 && (
                <Filter.Boolean
                    label={t`common.filters.parking.label`}
                    value={filter?.parking}
                    onChange={(value) =>
                        onChange && onChange({ parking: value })
                    }
                />
            )}
        </>
    );
};

export default Parking;
