// @flow
import React from "react";
import style from "./style.module.scss";

type Props = {
    locale: string,
    templateId: string,
    businessUnitId: string,
    dataHeight: string,
    dataWidth: string,
    dataTrustPilotId?: string,
    isAgency?: boolean,
};

const ReviewCollector = ({
    locale,
    templateId,
    businessUnitId,
    dataHeight,
    dataWidth,
    dataTrustPilotId,
    isAgency,
}: Props) => {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div
            ref={ref}
            className={
                isAgency
                    ? `trustpilot-widget  ${style.trustPilot} ${style.trustPilotAgency}`
                    : `trustpilot-widget  ${style.trustPilot}`
            }
            data-locale={locale}
            data-template-id={templateId}
            data-businessunit-id={businessUnitId}
            data-style-height={dataHeight}
            data-style-width={dataWidth}
            data-location={dataTrustPilotId}
        >
            <a
                href="https://nl-be.trustpilot.com/review/century21.be"
                target="_blank"
                rel="noreferrer"
            >
                Trustpilot
            </a>
        </div>
    );
};
export default ReviewCollector;
