// @flow

import React from "react";

import style from "./style.module.scss";

import { Section } from "@containers";
import Image from "gatsby-image";
import { Typography } from "@2po-c21/components";
import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";
import { Container } from "reactstrap";
import Render from "../.";

type Props = {
    heroImage: *,
    id: string,
    title: string,
    author: *,
    content: *,
};

const render = ({ heroImage, author, title, content }: Props, key, locale) => {
    return (
        <div key={key}>
            <div className={style.heroImageWrapper}>
                <Image fluid={heroImage.fluid} alt={heroImage?.description} />
            </div>
            <Section>
                <Container className={style.blogWrapper}>
                    <div className={style.heading1}>
                        <Typography type="heading-4-bold">{title}</Typography>
                    </div>
                    <a className={style.authorWrapper} href={author.link}>
                        <div className={style.authorImage}>
                            <Image
                                fixed={author.image.fixed}
                                alt={author.image.description}
                            />
                        </div>
                        {author.name}
                    </a>
                    {Render(content, locale)}
                </Container>
            </Section>
        </div>
    );
};

const condition = (content) => content.__typename === CONTENTFUL_TYPES.Blog;

export default createContentfulRenderer(condition, render);
