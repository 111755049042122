// @flow

import { t } from "ttag";

export const toYesNoNull = (value: ?boolean) =>
    value ? t`common.text.yes` : value === false ? t`common.text.no` : null;

export const toYesNo = (value: ?boolean) => toYesNoNull(!!value);

export const toYesNoMaybeNull = (value: ?string) => {
    if (!value) return null;

    if (value.toLowerCase() === "maybe") {
        return t`common.text.maybe`;
    }

    return toYesNoNull(value.toLowerCase() === "true" ? true : false);
};
