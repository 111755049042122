// @flow

export const ensureArray = (values: *): *[] => {
    if (!values) return [];

    return Array.isArray(values) ? values : [values];
};

export const groupItems = (items: *[], amount: number) =>
    items?.reduce((acc, item, index) => {
        const idx = Math.floor(index / amount);
        acc[idx] = [...(acc[idx] || []), item];
        return acc;
    }, []);
