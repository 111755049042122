// @flow

import React from "react";
import { Helmet } from "react-helmet";
import { gettext, t } from "ttag";
import { useSiteUrl } from "@utils";

type PropertyDetailsMetaProps = {
    property: {
        type: string,
        listingType: string,
        address: {
            city: string,
        },
        price: string,
        description: string,
    },
    translationSlugs: *,
    locale: string,
    metaImage?: *,
};

const PropertyDetailsMeta = ({
    property,
    translationSlugs,
    locale,
    metaImage,
}: PropertyDetailsMetaProps) => {
    const type = gettext(`api.property.type.${property.type}`);
    const listingType = gettext(
        `api.property.listing-type.${property.listingType}`,
    ).toLowerCase();
    const city = property.address.city;
    const price = property.price;
    const title = t`title.property-detail ${type} ${listingType} ${city} ${price}`;

    return (
        <Meta
            title={title}
            description={property.description}
            translationSlugs={translationSlugs}
            locale={locale}
            metaImage={metaImage}
        />
    );
};

type ProjectDetailsMetaProps = {
    project: {
        title: string,
        address: {
            city: string,
        },
        description: string,
    },
    translationSlugs: *,
    locale: string,
};

const ProjectDetailsMeta = ({
    project: {
        title,
        address: { city },
        description,
    },
    translationSlugs,
    locale,
}: ProjectDetailsMetaProps) => {
    const metaTitle = t`title.project-detail ${title} ${city}`;

    return (
        <Meta
            title={metaTitle}
            description={description}
            translationSlugs={translationSlugs}
            locale={locale}
        />
    );
};

type AgencyMetaProps = {
    agency: {
        name: string,
        address: {
            city: string,
        },
    },
    translationSlugs: *,
    locale: string,
};

const AgencyMeta = ({
    agency: {
        name,
        address: { city },
    },
    translationSlugs,
    locale,
}: AgencyMetaProps) => {
    const title = t`title.agency ${name} ${city}`;
    const description = t`meta.description.agency ${name} ${city}`;

    return (
        <Meta
            title={title}
            description={description}
            translationSlugs={translationSlugs}
            locale={locale}
        />
    );
};

type MetaProps = {
    title: string,
    description: string,
    translationSlugs: *,
    locale: string,
    metaImage?: *,
};

const Meta = ({
    title,
    description,
    translationSlugs,
    locale,
    metaImage,
}: MetaProps) => {
    const siteUrl = useSiteUrl();
    const toAbsolutePath = (relativePath) => `${siteUrl}${relativePath}`;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link
                rel="canonical"
                href={toAbsolutePath(translationSlugs[locale])}
            />
            {locale === "nl" ? null : (
                <link
                    rel="alternate"
                    href={toAbsolutePath(translationSlugs["nl"])}
                    hrefLang="nl-be"
                />
            )}
            {locale === "fr" ? null : (
                <link
                    rel="alternate"
                    href={toAbsolutePath(translationSlugs["fr"])}
                    hrefLang="fr-be"
                />
            )}
            <meta
                property="og:url"
                content={toAbsolutePath(translationSlugs[locale])}
            />
            <meta property="og:type" content="website" />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={description} />
            <meta name="twitter:card" content="summary_large_image" />
            {metaImage && (
                <meta name="og:image" property="og:image" content={metaImage} />
            )}
            {metaImage && <meta name="twitter:image" content={metaImage} />}
        </Helmet>
    );
};

export { AgencyMeta, PropertyDetailsMeta, ProjectDetailsMeta };

export default Meta;
