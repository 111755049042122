// @flow

import React from "react";
import { t } from "ttag";
import { applyCountFacet } from "@utils/search/facets";
import { Filter } from "@2po-c21/components";
import { type PropertyFilterProps } from "../types";

const Terrace = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const values = applyCountFacet([true], facets?.terrace);
    return (
        <>
            {values.length > 0 && (
                <Filter.Boolean
                    label={t`common.filters.terrace.label`}
                    value={filter?.terrace}
                    onChange={(value) =>
                        onChange && onChange({ terrace: value })
                    }
                />
            )}
        </>
    );
};

export default Terrace;
