// @flow

import React from "react";

import style from "./style.module.scss";

import { Section } from "@containers";
import { Link } from "gatsby";

import { CategoryLinkCard, SectionTitle } from "@2po-c21/components";
import Image from "gatsby-image";

import {
    createContentfulRenderer,
    CONTENTFUL_TYPES,
    QUICK_LINK_BLOCK_TYPES,
} from "../types";
import { Container } from "reactstrap";

type Props = {
    linkItems: {
        linkTitle?: string,
        image?: *,
        content?: *,
        query?: string,
        externalLink?: string,
        contentfulInternalLink?: *,
    }[],
    title: string,
};

const render = ({ linkItems, title }: Props, key) => {
    return (
        <Section key={key} theme="themeGray">
            <SectionTitle>{title}</SectionTitle>
            <Container className={style.categoryLinksWrapper}>
                {linkItems.map(
                    (
                        {
                            linkTitle,
                            image,
                            content,
                            query,
                            contentfulInternalLink,
                            externalLink,
                        },
                        index,
                    ) => (
                        <Link
                            key={index}
                            to={
                                externalLink
                                    ? externalLink
                                    : contentfulInternalLink?.slug || query
                            }
                            className="gatsbyLink"
                        >
                            <CategoryLinkCard
                                label={linkTitle || content?.contentTitle}
                                image={{
                                    // eslint-disable-next-line react/display-name
                                    imageSrc: () => (
                                        <Image
                                            fixed={
                                                image?.fixed ||
                                                content?.squareImage.fixed
                                            }
                                            alt={
                                                image?.description ||
                                                content?.squareImage
                                                    ?.description
                                            }
                                        />
                                    ),
                                    description:
                                        image?.description ||
                                        content?.squareImage?.description,
                                }}
                            />
                        </Link>
                    ),
                )}
            </Container>
        </Section>
    );
};

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.QuickLinkBlock &&
    content.type === QUICK_LINK_BLOCK_TYPES.CategoryLink;

export default createContentfulRenderer(condition, render);
