// @flow

import React from "react";
import style from "./style.module.scss";
import { Icon, HeartWithFill, Typography } from "@2po-c21/components";
import { t } from "ttag";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { type FavoritesEvent, EVENT_MAP } from "../../models/events";
import { PushEvent } from "../Tracker/Tracker";

type Props = {
    id: string,
};

const Favourite = ({ id }: Props) => {
    const gtmDispatcher = useGTMDispatch();

    const toggleFavourite = (event, propertyId) => {
        event.stopPropagation();

        if (typeof localStorage !== "undefined") {
            const storage = JSON.parse(
                localStorage.getItem("favourites") || "[]",
            );

            if (event.target.checked) {
                storage.push(propertyId);
                window.localStorage.setItem(
                    "favourites",
                    JSON.stringify(storage),
                );
            } else {
                window.localStorage.setItem(
                    "favourites",
                    JSON.stringify(storage.filter((id) => id !== propertyId)),
                );
            }
        }
        const gtmEvent: FavoritesEvent = {
            favorite: event.target.checked ? true : false,
            propertyid: id,
            propertyview: "detail",
            listname: "property detail",
            type: EVENT_MAP.FavoritesEvent,
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    const checkLocalStorage = () => {
        if (typeof localStorage !== "undefined") {
            return JSON.parse(
                localStorage.getItem("favourites") || "[]",
            )?.includes(id);
        }
        return false;
    };

    return (
        <div className={style.favourite}>
            <input
                type="checkbox"
                onClick={(e) => toggleFavourite(e, id)}
                defaultChecked={checkLocalStorage()}
            />
            <Icon icon={HeartWithFill} size="md" />
            <Typography
                type="caption"
                className={style.favouriteDescription}
            >{t`favourites.add-to-favourite`}</Typography>
        </div>
    );
};

export default Favourite;
