// @flow

import React from "react";
import { createContentfulRenderer, CONTENTFUL_TYPES } from "../types";
import { ContactHqForm } from "@components";

const render = (content, key, locale) => <ContactHqForm locale={locale} />;

const condition = (content) => {
    return content?.internal?.type === CONTENTFUL_TYPES.ContactHqBlock;
};

export default createContentfulRenderer(condition, render);
