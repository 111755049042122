// @flow

import React from "react";

import Link from "../../Link";
import style from "./style.module.scss";

import { Col, Container, Row } from "reactstrap";
import { QuickLinkCard } from "@2po-c21/components";
import {
    createContentfulRenderer,
    CONTENTFUL_TYPES,
    QUICK_LINK_BLOCK_TYPES,
} from "../types";

type Props = {
    linkItems: {
        icon: string,
        linkTitle: string,
        externalLink: string,
        contentfulInternalLink?: {
            slug: string,
        },
    }[],
};

export const QuickLinksBlock = ({ linkItems }: Props) => (
    <Row className={style.quickLinksWrapper}>
        {linkItems.map(
            ({ linkTitle, contentfulInternalLink, externalLink }, index) => {
                const LinkComponent = ({ children }: *) => (
                    <Link
                        className="gatsbyLink"
                        to={
                            contentfulInternalLink
                                ? contentfulInternalLink.slug
                                : externalLink && externalLink
                        }
                    >
                        {children}
                    </Link>
                );
                return (
                    <Col
                        className={style.quickLinkCard}
                        key={index}
                        xs="6"
                        lg="3"
                        xl="2"
                    >
                        <QuickLinkCard
                            title={linkTitle}
                            LinkComponent={LinkComponent}
                        />
                    </Col>
                );
            },
        )}
    </Row>
);

const render = ({ linkItems }: Props, key) => (
    <Container fluid key={key}>
        <QuickLinksBlock linkItems={linkItems} />
    </Container>
);

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.QuickLinkBlock &&
    content.type === QUICK_LINK_BLOCK_TYPES.Basic;

export default createContentfulRenderer(condition, render);
