// @flow

import React from "react";

import axios from "axios";
import { endpoints } from "../../utils/c21-api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { AgencyContactForm } from "@2po-c21/components";
import { t } from "ttag";
import { getFormFields } from "../AgencyContactForm";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { SendToolEvent } from "../../components/Tracker/Tracker";

type Props = {
    locale: *,
};

const ContactHqForm = ({ locale }: Props) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const gtmDispatcher = useGTMDispatch();

    const getHqFormMessages = () => ({
        title: t`form.hq-contact.title`,
        subtitle: t`form.hq-contact.subtitle`,
        subject: [
            {
                label: t`form.hq-contact.subject.open-office`,
                eventKey: "Open an office",
            },
            {
                label: t`form.hq-contact.subject.job-application`,
                eventKey: "Apply for a job",
            },
            {
                label: t`form.hq-contact.subject.dissatisfaction`,
                eventKey: "Dissatisfaction",
            },
            {
                label: t`form.hq-contact.subject.real-estate-development`,
                eventKey: "Real-estate development",
            },
            {
                label: t`form.hq-contact.subject.others`,
                eventKey: "Others",
            },
        ],
        checkBoxMessage: t`form.hq-contact.privacy-message`,
        checkBoxLink: {
            label: t`form.hq-contact.privacy-policy`,
            href: `/${locale}/${t`slug.privacy-policy`}`,
        },
        reCaptcha: {
            warning: t`form.hq-contact.recaptcha-warning`,
            policy: t`form.hq-contact.recaptcha-policy`,
            tos: t`form.hq-contact.recaptcha-tos`,
            and: t`common.text.and`,
        },
        submitMessage: t`form.hq-contact.submit`,
    });

    return (
        <div
            data-tracking-event="tool-step"
            data-tracking-info={`{"name":"contact","stepnumber":"1","stepname":"contact details","element":"form"}`}
        >
            <AgencyContactForm
                fields={getFormFields()}
                formMessages={getHqFormMessages()}
                onSubmitForm={(values) => {
                    submitForm(locale, values, executeRecaptcha).then(() => {
                        SendToolEvent(
                            gtmDispatcher,
                            "contact",
                            2,
                            values.chosenSubjectEventKey,
                            2,
                            "contact laststep",
                            true,
                        );
                    });
                }}
            />
        </div>
    );
};

export default ContactHqForm;

const submitForm = async (language, values, executeRecaptcha) => {
    if (!executeRecaptcha) {
        return;
    }
    const gRecaptchaResponse = await executeRecaptcha("homepage");
    _submitForm(
        language,
        values.emailValue,
        window.location.href,
        gRecaptchaResponse,
        values,
    )
        // TODO [ACC24062020] Resolve success when design is done
        .then(() => {
            alert("SUCCESS. You've submitted your message");
        })
        .catch(() => {
            alert("There was an error on the server");
        });
};

const _submitForm = (
    language: string,
    emailValue: string,
    url: string,
    gRecaptchaResponse: string,
    formValues: {
        language: string,
        url: string,
        token: string,
        GDPR: *,
    },
): Promise<*> => {
    const urlForm = `${endpoints.forms}/contact/hq`;
    formValues.language = language;
    formValues.url = url;
    formValues.token = gRecaptchaResponse;
    delete formValues?.GDPR;

    return axios({
        method: "post",
        url: urlForm,
        headers: {
            "Content-Type": "application/json",
        },
        data: formValues,
    });
};
