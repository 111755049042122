// @flow

import style from "./style.module.scss";

import React, { useEffect, useState } from "react";

import { Map, Pin, Pins } from "@2po-c21/components";

import AgencyCard, { type Agency } from "../AgencyCard";
import AgencySearchResults from "../AgencySearchResults";
import PropertyCard from "../PropertyCard";

type PropertySearchMapProps = {
    properties: Array<*>,
};

/**
 * Detect the default property location in belgium
 */
const isDefaultLocation = (location) =>
    location?.latitude === 50.85 && location?.longitude === 4.35;

const PropertySearchMap = ({ properties }: PropertySearchMapProps) => {
    const [pins, setPins] = useState();

    // TODO: Antipattern. Using useEffect as memo
    useEffect(() => {
        setPins(
            properties
                .filter((property) => !property?.address?.hidden)
                .map((property, index) =>
                    !property.location ||
                    isDefaultLocation(property.location) ? undefined : (
                        <Pin
                            key={index}
                            latitude={property.location.latitude}
                            longitude={property.location.longitude}
                        >
                            <PropertyCard property={property} />
                        </Pin>
                    ),
                )
                .filter((pin) => !!pin),
        );
    }, [properties]);

    return <SearchMap pins={pins} />;
};

type AgencyWithLocation = Agency & {
    location?: {
        latitude: number,
        longitude: number,
    },
};

type AgencySearchMapProps = {
    agencies: Array<AgencyWithLocation>,
    currentPage: number,
    isSortedByLocation: boolean,
};

const AgencySearchMap = ({
    agencies,
    currentPage,
    isSortedByLocation,
}: AgencySearchMapProps) => {
    const [pins, setPins] = useState();

    // TODO: Antipattern. Using useEffect as memo
    useEffect(() => {
        const lastIndexForFocused =
            (isSortedByLocation &&
                currentPage * AgencySearchResults.RESULTS_PER_PAGE - 1) ||
            agencies.length - 1;

        const pins: Array<Pin> = agencies
            .map((agency, index) =>
                !agency.location ||
                isDefaultLocation(agency.location) ? undefined : (
                    <Pin
                        key={index}
                        latitude={agency.location.latitude}
                        longitude={agency.location.longitude}
                        canBeOutOfFocus={index > lastIndexForFocused}
                    >
                        <AgencyCard agency={agency} />
                    </Pin>
                ),
            )
            .filter((pin) => !!pin);

        setPins(pins);
    }, [agencies, isSortedByLocation, currentPage]);

    return <SearchMap pins={pins} />;
};

export { AgencySearchMap, PropertySearchMap };

type SearchMapProps = {
    pins: ?Array<Pin>,
};

const SearchMap = ({ pins }: SearchMapProps) => {
    if (!pins || !pins.length) {
        return (
            <>
                <div className={style.desktop}>
                    <Map>
                        <Pins></Pins>
                    </Map>
                </div>
                <div className={style.mobile}>
                    <Map>
                        <Pins></Pins>
                    </Map>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={style.desktop}>
                <Map>
                    <Pins>{pins}</Pins>
                </Map>
            </div>
            <div className={style.mobile}>
                <Map>
                    <Pins>
                        {pins.map(
                            (pin) => React.cloneElement(pin, {}, undefined), // remove popup
                        )}
                    </Pins>
                </Map>
            </div>
        </>
    );
};
