// @flow

import React from "react";

import { applyMinMaxSliderFacet } from "@utils/search/facets";
import { Filter } from "@2po-c21/components";
import { t } from "ttag";
import { type PropertyFilterProps } from "../types";

const Bedrooms = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const { min, max } = applyMinMaxSliderFacet(0, 5, facets?.numberOfBedrooms);

    return (
        <Filter.RangeSlider
            label={t`common.filters.bedrooms.label`}
            value={filter?.bedrooms}
            onChange={(value) => onChange && onChange({ bedrooms: value })}
            applyBtnText={t`common.filters.apply`}
            steps={1}
            min={min}
            max={max}
            infiniteMax
        />
    );
};

export default Bedrooms;
