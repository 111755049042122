// @flow

import React from "react";
import { t } from "ttag";
import { applyCountFacet } from "@utils/search/facets";

import { Filter } from "@2po-c21/components";
import { type PropertyFilterProps } from "../types";

const Garden = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const values = applyCountFacet([true], facets?.garden);

    return (
        <>
            {values.length > 0 && (
                <Filter.Boolean
                    label={t`common.filters.garden.label`}
                    value={filter?.garden}
                    onChange={(value) =>
                        onChange && onChange({ garden: value })
                    }
                />
            )}
        </>
    );
};

export default Garden;
