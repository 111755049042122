// @flow

import style from "./style.module.scss";

import React, { useState, useEffect } from "react";

import Section from "../Section";
import { Row, Col } from "reactstrap";

import { t } from "ttag";
import { PropertyCard } from "@components";
import { Typography } from "@2po-c21/components";
import { mapPropertiesForSearch } from "@utils/c21-api/mappers/property";
import { endpoints } from "../../utils/c21-api/endpoints";
import axios from "axios";

import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
    type FavoritesEvent,
    type ImagesSliderEvent,
    type SliderImageClickEvent,
    EVENT_MAP,
} from "../../models/events";
import { PushEvent } from "../../components/Tracker/Tracker";

type Props = {
    locale: string,
};

const Favourites = ({ locale }: Props) => {
    const [results, setResults] = useState();
    const gtmDispatcher = useGTMDispatch();

    useEffect(() => {
        if (localStorage) {
            const fetchStorage = JSON.parse(
                localStorage.getItem("favourites") || "[]",
            );
            fetchProperties(fetchStorage);
        }
    }, []);

    const handleRemove = (property) => {
        if (localStorage) {
            const fetchStorage = JSON.parse(
                localStorage.getItem("favourites") || "[]",
            );
            fetchProperties(fetchStorage);
            const event: FavoritesEvent = {
                favorite: false,
                propertyid: property.id,
                propertyview: "card",
                listname: "favourites",
                type: EVENT_MAP.FavoritesEvent,
            };
            PushEvent(event, gtmDispatcher);
        }
    };
    const handleAddFavourite = (property) => {
        const event: FavoritesEvent = {
            propertyid: property.id,
            propertyview: "card",
            listname: "favourites",
            favorite: true,
            type: EVENT_MAP.FavoritesEvent,
        };
        PushEvent(event, gtmDispatcher);
    };

    const fetchProperties = async (storage) => {
        _fethResults(storage).then((results) => {
            setResults(mapPropertiesForSearch(results.data.data, locale));
        });
    };

    const _fethResults = (storage): Promise<*> => {
        const urlForm = `${endpoints.properties}`;
        return axios({
            method: "get",
            url: urlForm,
            params: {
                page: 0,
                pageSize: 100,
                filter: btoa(JSON.stringify({ ids: { values: storage } })),
            },
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    const pushImagesSlider = (direction: string, projectid: string) => {
        const gtmEvent: ImagesSliderEvent = {
            direction,
            type: EVENT_MAP.ImagesSliderEvent,
            listname: "favourites",
            propertyid: projectid,
            propertyview: "card",
        };
        PushEvent(gtmEvent, gtmDispatcher);
    };

    const handleOnNext = (property: any) => {
        pushImagesSlider("right", property.id);
    };
    const handleOnPrevious = (property: any) => {
        pushImagesSlider("left", property.id);
    };
    const handleOnPropertyCardClick = (event: SliderImageClickEvent) => {
        event.propertyview = "favourites";
        event.listname = "card";
        PushEvent(event, gtmDispatcher);
    };
    const favoritesPropCardCallbacks = {
        handleAddFavourite,
        handleRemoveFavourite: handleRemove,
        handleNext: handleOnNext,
        handlePrevious: handleOnPrevious,
        handleOnClick: handleOnPropertyCardClick,
    };

    return (
        <Section>
            <div className={style.favouritesTitle}>
                <Typography type="section-title">{t`favourites.section-title`}</Typography>
            </div>
            {results && results.length ? (
                <Row>
                    {results.map((favourite, key) => (
                        <Col
                            className={style.favouritesCard}
                            sm="12"
                            md="6"
                            xl="4"
                            key={key}
                        >
                            <PropertyCard
                                property={favourite}
                                callbacks={favoritesPropCardCallbacks}
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <div>{t`favourites.no-results-found`}</div>
            )}
        </Section>
    );
};

export default Favourites;
