// @flow

import {
    type CountFacet,
    type MinMaxFacet,
    type PropertyFilterFacets,
} from "./types";

export type { PropertyFilterFacets, MinMaxFacet };

export const applyCountFacet = <T>(
    values: ?Array<T>,
    facet: ?CountFacet,
): Array<T> =>
    _checkInputAndApply(values, facet, (values, facet) =>
        applyCountFacetDictionary(values, toDictionary(facet)),
    );

export const applyMinMaxFacet = (
    values: ?Array<number>,
    facet: ?MinMaxFacet,
): Array<number> =>
    _checkInputAndApply(values, facet, (values, facet) =>
        values.filter((value) => value >= facet.min && value <= facet.max),
    );

export const applyMinMaxSliderFacet = (
    min: number,
    max: number,
    facet: ?MinMaxFacet,
): { min: number, max: number } => {
    if (!facet) return { min, max };

    // min and max outside of slider boundaries => create slider based on facet numbers
    if (facet.max < min || facet.min > max)
        return { min: facet.min, max: facet.max };

    return {
        min: Math.max(min, facet.min),
        max: Math.min(max, facet.max),
    };
};

const _checkInputAndApply = <T, F>(
    values: ?Array<T>,
    facet: ?F,
    apply: (Array<T>, F) => Array<T>,
): Array<T> => {
    if (!values) return [];
    if (!facet) return values;
    return apply(values, facet);
};

const toDictionary = (countFacet: CountFacet): * =>
    countFacet.reduce(
        (dictionary, { value, count }) => ({
            ...dictionary,
            [value]: count,
        }),
        {},
    );

const applyCountFacetDictionary = <T>(values: Array<T>, facets: *): Array<T> =>
    values.filter((value) => facets[value] && facets[value] > 0);
