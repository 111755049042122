// @flow

import React from "react";
import style from "./style.module.scss";
import { Favourite, SocialShare, Print } from "@components";
import { Icon, ArrowLeft } from "@2po-c21/components";
import { t } from "ttag";

type Props = {
    property: *,
};

const PropertyDetailsHeader = ({ property }: Props) => {
    return (
        <div className={style.propertyDetailsHeader}>
            <div
                className={style.backButtonContainer}
                onClick={() => window.history.back()}
            >
                <Icon icon={ArrowLeft} />
                <span
                    className={style.backButton}
                >{t`property-details.back-button`}</span>
            </div>
            <div className={style.options}>
                <Favourite id={property.api_id} />
                <SocialShare
                    propertyTitle={
                        property.title || property.subType || property.type
                    }
                    propertyId={property.id}
                />
                <Print propertyId={property.id} />
            </div>
        </div>
    );
};
export default PropertyDetailsHeader;
