// @flow

import React from "react";
import style from "./style.module.scss";

import { ExtendedUspTiles, Logo } from "@2po-c21/components";
import {
    createContentfulRenderer,
    CONTENTFUL_TYPES,
    USP_BLOCK_TYPES,
} from "../types";

type Props = {
    uspItems: *,
    title: string,
};

const render = ({ uspItems, title }: Props) => (
    <div className={style.wrapper}>
        <div className={style.logo}>
            <Logo logo={"seal"} width="124" />
        </div>
        <ExtendedUspTiles
            tiles={uspItems.map((item) => {
                return {
                    data: item.linkTitle,
                    label: item.linkSubtitle?.linkSubtitle,
                    icon: item.icon,
                };
            })}
            title={title}
        />
    </div>
);

const condition = (content) =>
    content.__typename === CONTENTFUL_TYPES.UspBlock &&
    content.type === USP_BLOCK_TYPES.Extended;

export default createContentfulRenderer(condition, render);
