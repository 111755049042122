// @flow
import React from "react";
import classnames from "classnames";
import style from "./style.module.scss";
import { Container } from "reactstrap";
import { Logo } from "@2po-c21/components";

type Props = {
    children: *,
    theme?: string,
    enableLogo?: boolean,
    fullBleed?: boolean,
};
const Section = ({ children, theme, fullBleed, enableLogo }: Props) => (
    <div
        className={classnames(
            style.sectionContainer,
            theme ? style[theme] : "",
        )}
    >
        {enableLogo && (
            <div className={style.logo}>
                <Logo logo={"seal"} width="124" />
            </div>
        )}
        <Container fluid={fullBleed}>{children}</Container>
    </div>
);
export default Section;
