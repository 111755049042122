// @flow

import React from "react";
import style from "../style.module.scss";
import { Formik } from "formik";
import { Form as ReactstrapForm, Button, FormGroup } from "reactstrap";
import { t } from "ttag";
import {
    getValidationSchema,
    getInitialValues,
    renderInput,
    renderRadioButton,
    renderDropdown,
    renderErrorMessage,
} from "../utils";
import {
    Diamond,
    Star,
    Standard,
    Construction,
    Delivery,
    TagLabel,
    Typography,
} from "@2po-c21/components";

type StepProps = {
    previousStep: () => void,
    nextStep: () => void,
    updateFormCollection: (values: *, isComplete: boolean) => void,
};

const Second = ({
    previousStep,
    nextStep,
    updateFormCollection,
}: StepProps) => {
    const choices = {
        inputFields: [
            {
                type: "number",
                displayValue: t`form.estimation-tool.fields.postal-code`,
                name: "postalCode",
                required: "true",
                errorMessage: t`form.estimation-tool.fields.error-message`,
                value: null,
            },
        ],
        radioButtons: [
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.renovate`,
                value: "TO_RENOVATE",
                name: "condition",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: Construction,
            },
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.habitable`,
                value: "READY_TO_USE",
                name: "condition",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: Delivery,
            },
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.new`,
                value: "NEW",
                name: "condition",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: TagLabel,
            },
        ],
        secondRadioButtons: [
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.standing-standard`,
                value: "STANDARD",
                name: "standing",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: Standard,
            },
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.standing-superior`,
                value: "SUPERIOR",
                name: "standing",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: Star,
            },
            {
                type: "radio",
                displayValue: t`form.estimation-tool.fields.standing-luxury`,
                value: "LUXURY",
                name: "standing",
                required: true,
                errorMessage: t`form.estimation-tool.fields.error-message`,
                icon: Diamond,
            },
        ],
        dropdowns: [
            {
                type: "dropdown",
                label: t`form.estimation-tool.fields.sell-planning.label`,
                name: "sellPlanning",
                errorMessage: t`form.estimation-tool.fields.error-message`,
                options: [
                    {
                        label: t`form.estimation-tool.fields.sellPlanning-3`,
                        value: "3",
                    },
                    {
                        label: t`form.estimation-tool.fields.sellPlanning-6`,
                        value: "6",
                    },
                    {
                        label: t`form.estimation-tool.fields.sellPlanning-12`,
                        value: "12",
                    },
                    {
                        label: t`form.estimation-tool.fields.sellPlanning-0`,
                        value: "0",
                    },
                ],
            },
        ],
    };

    const submitForm = (values) => {
        updateFormCollection(values, false);
        nextStep && nextStep();
    };

    const handleDropdownChange = (e: any, name, setFieldValue) => {
        setFieldValue(name, e.value);
    };

    return (
        <div className={style.stepContainer}>
            <Typography
                type="section-title"
                className={style.estimationToolTitle}
            >{t`form.estimation-tool.title`}</Typography>
            <Formik
                initialValues={getInitialValues(choices)}
                validationSchema={getValidationSchema(choices)}
                onSubmit={(values) => submitForm(values)}
            >
                {({
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                }) => (
                    <ReactstrapForm
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    >
                        <FormGroup className={style.inputFieldsContainer}>
                            {choices.inputFields.map((inputField, key) => (
                                <div key={key} className={style.inputField}>
                                    {renderInput(
                                        inputField,
                                        handleChange,
                                        handleBlur,
                                        errors,
                                        touched,
                                    )}
                                </div>
                            ))}
                        </FormGroup>
                        <FormGroup>
                            <div>{t`form.estimation-tool.fields.property-state`}</div>
                            <div className={style.radioButtonGroup}>
                                {choices.radioButtons.map((radioButton, key) =>
                                    renderRadioButton(
                                        radioButton,
                                        handleChange,
                                        key,
                                    ),
                                )}
                            </div>
                            {errors?.condition &&
                                renderErrorMessage(errors?.condition)}
                        </FormGroup>
                        <FormGroup>
                            <div>{t`form.estimation-tool.fields.property-standing`}</div>
                            <div className={style.radioButtonGroup}>
                                {choices.secondRadioButtons.map(
                                    (radioButton, key) =>
                                        renderRadioButton(
                                            radioButton,
                                            handleChange,
                                            key,
                                        ),
                                )}
                            </div>
                            {errors?.standing &&
                                renderErrorMessage(errors?.standing)}
                        </FormGroup>
                        <FormGroup>
                            {choices.dropdowns.map((dropdown, key) =>
                                renderDropdown({
                                    dropdown,
                                    handleDropdownChange: (e) =>
                                        handleDropdownChange(
                                            e,
                                            dropdown.name,
                                            setFieldValue,
                                        ),
                                    setFieldValue,
                                    key,
                                    handleBlur,
                                }),
                            )}
                            {errors?.sellPlanning &&
                                renderErrorMessage(errors?.sellPlanning)}
                        </FormGroup>
                        <div className={style.buttonContainer}>
                            <div
                                className={style.prevButton}
                                onClick={() => previousStep()}
                            >{t`form.estimation-tool.fields.previous`}</div>
                            <Button color="primary" type="submit">
                                {t`form.estimation-tool.fields.next`}
                            </Button>
                        </div>
                    </ReactstrapForm>
                )}
            </Formik>
        </div>
    );
};

export default Second;
