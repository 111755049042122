// @flow

import React from "react";

import { applyMinMaxSliderFacet } from "@utils/search/facets";
import { Filter } from "@2po-c21/components";
import { t } from "ttag";
import { type PropertyFilterProps } from "../types";

const Habitable = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const { min, max } = applyMinMaxSliderFacet(
        0,
        500,
        facets?.habitableSurfaceArea,
    );

    return (
        <Filter.RangeSlider
            label={t`common.filters.habitableSurfaceArea.label`}
            value={filter?.habitableSurfaceArea}
            onChange={(value) =>
                onChange && onChange({ habitableSurfaceArea: value })
            }
            applyBtnText={t`common.filters.apply`}
            steps={50}
            min={min}
            max={max}
            infiniteMax
            unit={"m\u00b2"}
        />
    );
};

const Garden = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const { min, max } = applyMinMaxSliderFacet(
        0,
        5000,
        facets?.surfaceAreaGarden,
    );

    return (
        <Filter.RangeSlider
            label={t`common.filters.surfaceAreaGarden.label`}
            value={filter?.surfaceAreaGarden}
            onChange={(value) =>
                onChange && onChange({ surfaceAreaGarden: value })
            }
            applyBtnText={t`common.filters.apply`}
            steps={50}
            min={min}
            max={max}
            infiniteMax
            unit={"m\u00b2"}
        />
    );
};

const Total = ({ onChange, filter, facets }: PropertyFilterProps) => {
    const { min, max } = applyMinMaxSliderFacet(
        0,
        1000,
        facets?.totalSurfaceArea,
    );

    return (
        <Filter.RangeSlider
            label={t`common.filters.totalSurfaceArea.label`}
            value={filter?.totalSurfaceArea}
            onChange={(value) =>
                onChange && onChange({ totalSurfaceArea: value })
            }
            applyBtnText={t`common.filters.apply`}
            steps={50}
            min={min}
            max={max}
            infiniteMax
            unit={"m\u00b2"}
        />
    );
};

export { Habitable, Garden, Total };
