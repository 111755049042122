// @flow

import { useStaticQuery, graphql } from "gatsby";

export const useSiteUrl = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                    }
                }
            }
        `,
    );
    return site.siteMetadata.siteUrl;
};
